import React, { useContext, useState } from "react"
import { Spinner } from "react-bootstrap"
import { putTransaction } from "../../../../api"
import { AddFundContext } from "../../AddFundContext"
import styles from "./common.module.css"
import AlertErrorIcon from "../../../../public/images/alertError.svg"
import CheckBox from '../../../CheckBox/CheckBox'

const Transaction =()=>{
    const {setSubPage, hashID, setOnErrorRetry, setShowGenericError, SUBPAGE, onBack,setIsCheckboxSelectedForTransaction,page} = useContext(AddFundContext)
    const [tranxID, setTranxID] = useState('')
    const [error, setError] = useState(false)
    const [validate, setValidate] = useState(false)
    const [loadingContinue, setLoadingContinue] = useState(false)
    const [loadingSkip, setLoadingSkip] = useState(false)
    const [checkedTransactionDoneNoReferenceNumber, setCheckedTransactionDoneNoReferenceNumber] = useState(false)

    const submitData = async(showGenericError)=>{
        let success = true
        try{
            const res = await putTransaction(hashID, tranxID)
            if(res && res.code === 200){
                setIsCheckboxSelectedForTransaction(checkedTransactionDoneNoReferenceNumber)
                setSubPage(SUBPAGE.ACKNOWLEDGEMENT)
                // setSubPage(SUBPAGE.SUCCESS)
            }else{
                success=false
            }
        }catch(error){
            success=false
        }
        if(success && showGenericError){
            setShowGenericError(false)
            setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry(submitData)
            setShowGenericError(true)
        }
    }
    // const onContinue = async(transReq)=>{
    //     if(!validate){
    //         setValidate(true)
    //     }
       
    //     if(!loadingContinue){
    //         if(transReq && !tranxID){
    //             setError(true)
    //             return
    //         }
    //         if(transReq)
    //             setLoadingContinue(true)
    //         else
    //             setLoadingSkip(true)
    //         await submitData()
    //         if(transReq)
    //             setLoadingContinue(false)
    //         else
    //             setLoadingSkip(false)
    //     }    
        
    // }

    const onContinue = async (transReq) => {
        if (!validate) {
            setValidate(true);
        }
    
        if (!loadingContinue) {
            // If transaction is required and checkbox is not checked, then we must validate tranxID
            if (transReq && !checkedTransactionDoneNoReferenceNumber && !tranxID) {
                setError(true);
                return;
            }

            if (checkedTransactionDoneNoReferenceNumber) {
                setIsCheckboxSelectedForTransaction(true);
                setSubPage(SUBPAGE.ACKNOWLEDGEMENT);
                return;
            }
    
            if (transReq) setLoadingContinue(true);
            else setLoadingSkip(true);
    
            await submitData();
    
            if (transReq) setLoadingContinue(false);
            else setLoadingSkip(false);
        }
    };
    

    const onChange = (e)=>{
        setTranxID(e.target.value)
        if (validate && !checkedTransactionDoneNoReferenceNumber) {
            setError(!e.target.value);
        }
        // if(validate){
        //     if(!e.target.value){
        //         setError(true)
        //     }else{
        //         setError(false)
        //     }
        // }
    }

    // const onCheckBoxTick = () => {
	// 	setCheckedTransactionDoneNoReferenceNumber(!checkedTransactionDoneNoReferenceNumber);
    //     setError(false)
	// }

    const onCheckBoxTick = () => {
        setTranxID('');
        setCheckedTransactionDoneNoReferenceNumber(!checkedTransactionDoneNoReferenceNumber);
        
        // If checked, remove the error state (since reference number is not needed)
        if (!checkedTransactionDoneNoReferenceNumber) {
            setError(false);
        }
    };

    return (<>
     <div className={styles.instructionContainerGrey}>
        <h1 className={styles.heading}>Funds Added?</h1>
        <p className={styles.subHeading}>Please enter the reference or transaction number provided by your bank.</p>
        {/* <label className={styles.label}>Reference / Transaction Number</label> */}
        <div className={`${styles.input} ${error && styles.errorInput}`}>
            <input className="border-0 w-100" placeholder="Enter Reference / Transaction number" disabled={checkedTransactionDoneNoReferenceNumber} value={tranxID} onChange={onChange}/>
            {error&&<img src={AlertErrorIcon}/>}
        </div>
        {error&&<span className={styles.error}>This Field is required</span>}
        <p className={styles.subHeading} style={{textAlign:"center", marginTop:"20px"}}>OR</p>
        <div className={styles.marginCheckBox}>
            <CheckBox
                label='I have completed the transaction but I don’t have the transaction number'
                id='LRSCheckbox'
                name='isTransactionDoneNoReferenceNumber'
                onChange={onCheckBoxTick}
                isChecked={checkedTransactionDoneNoReferenceNumber}
            />
        </div>
        <hr/>
        <div>
            <p className={styles.bottomText}>Haven’t completed the transaction?  
            <span className={styles.blueTextGoBack} onClick={() => onBack()}>Go Back</span></p>
        </div>
        <div className={styles.footerContainer}>
            <button className={styles.continueBtn} onClick={()=>onContinue(true)}>
                {loadingContinue && <Spinner animation="border" className={styles.spinnerColor}/>}
                Continue
            </button>
            {/* <button className={styles.tertiaryBtn} onClick={()=>onContinue(false)}>
                {loadingSkip && <Spinner animation="border" className={styles.spinnerColorBlue}/>}
                Skip
            </button> */}
        </div>
        </div>
    </>)
}

export default Transaction