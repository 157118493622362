import React, { Component } from 'react'
import './Cookies.css'
import closeSvg from '../../public/images/close.svg'
import cookiesSvg from '../../public/images/cookies.svg'
import { LEGAL_LINKS } from '../../constants';

class Cookies extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cookiesAccepted: false
		}
		this.setCookies = this.setCookies.bind(this)
	}

	componentDidMount() {
		let getCookies = document.cookie.split(';')
		for (let i = 0; i < getCookies.length; i++) {
			const splited = getCookies[i].split('=')
			if (splited && splited[0].includes('accpeted')) {
				if (splited[1]) {
					this.setState({ cookiesAccepted: true })
				}
			}
		}
		// if (sessionStorage.getItem('cookies') === null) {
		//   this.setState({cookiesAccepted: false})
		// }
	}

  componentDidUpdate(props,state) {
    console.log('info',window.location.href)
    if(window.location.href.includes('accountsetup') && this.state.cookiesAccepted !== true){
      this.setCookies()
    }
  }

	setCookies() {
		this.setState({ cookiesAccepted: true })
		//  const d = new Date();
		// d.setTime(d.getTime() + (10 * 365 * 24 * 60 * 60));
		// let expires = "expires="+d.toUTCString();
		const expires = new Date(2147483647 * 1000).toUTCString()
		document.cookie = 'accpeted' + '=' + true + ';' + expires + ';path=/'
		// sessionStorage.setItem('cookies', 'accept')
	}

	render() {
		return (
			<>
				{this.state.cookiesAccepted === false ? (
					<div className='cookieCard'>
						<div className='cookieCardText p-0 container d-flex'>
							<div className='d-flex align-items-center'>
								<div className='pr-3'>
									<img src={cookiesSvg} alt='cookies-svg' />
								</div>
								<div>
									{`We use third-party cookies to provide support. By continuing to browse this website,
                       you accept our `}
									<a
										href={LEGAL_LINKS.PRIVACY_POLICY}
										target='_blank'
										style={{
											textDecoration: 'underline',
											color: 'inherit',
											cursor: 'pointer'
										}}>
										privacy policy
									</a>
									.
								</div>
							</div>
							<div
								className='pl-3'
								onClick={this.setCookies}
								style={{ cursor: 'pointer' }}>
								<img src={closeSvg} alt='close-svg' />
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</>
		)
	}
}

export default Cookies
