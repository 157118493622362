import React, { useState, useEffect } from 'react'
import {  Row, Col,Modal,Button,Spinner } from 'react-bootstrap'
import {updatedAccount,getExtendedHourDisclosure,extendedHoursAgreement} from '../../api'
import {setUserInfoSession} from '../../common'
import styles from './ExtendedDisclosureModal.module.css'
import CloseIcon from '../../public/images/ExtendedmodalClose.png'
// import Disclosure from './ExtendedHourDisclosure.js'


const ExtendedDisclosureModal = ({setShowDisclouser,setShowExtended,showDisclouser}) => {

  const [errorMessage,setErrorMessage] = useState(false)
  const [isAccepted,setIsAccepted] = useState('')
  const [loading,setLoading] = useState(false)
  const [disclosure,setDisclosure] = useState({})

  const asyncFetch = async () => {
    try{
    const response = await getExtendedHourDisclosure()
    if(response.code === 200){
      setDisclosure(response.data)
    }
    }catch(error){
      console.log('ext','error',error)
    }
  }

  useEffect(() => {
    asyncFetch()
  },[])


  const signDisclouser = async () => {
    setLoading(true)
    try{
      // let response = await updatedAccount({extendedHoursAgreement: true,signDisclosure:true})
      // extended hours agreement api call
      let response = await extendedHoursAgreement({extendedHoursAgreement: true,signDisclosure:true})
      if(response.code === 200){
        setUserInfoSession({extendedHoursAgreement: true})
        setShowDisclouser(false,setShowExtended(true))
       }
      else{
        setErrorMessage('Something went wrong')
      }
      setLoading(false)
    }catch(error){
      setErrorMessage('Something went wrong')
      setLoading(false)
    }
  }

  const onClose = () => {
    setErrorMessage('')
    setShowDisclouser(false,setShowExtended(false,setIsAccepted(false)))
  }

  const summary = disclosure && disclosure.summary && disclosure.summary.split('\n')

  return(
    <Modal
      show={showDisclouser}
      onHide={onClose}
      size="lg"
      dialogClassName={styles.modalDialog}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={styles.extendedModalBody}>
        <Row className='mb-3'>
          <Col className={styles.extendedModalTitle}>{disclosure.header}</Col>
          <Col style={{paddingRight:0,textAlign:'end'}}>
            <img src={CloseIcon} onClick={onClose} style={{cursor:'pointer'}}></img>
          </Col>
        </Row>
        {summary && summary.length > 0 ?
          summary.map(item => {
            const itemarr = item.split(':')
            const subvalue = itemarr[1] && itemarr.splice(1,itemarr.length).join(':')
            return(
              <Row className='mb-3'>
              <span className={styles.extendedModalSubTitle} style={itemarr[0].includes('Pre') ? {paddingRight:'15px'}: {}}>{itemarr[0]}</span>
              <span className={styles.extendedModalSubTitle}>:</span>
              <span className={styles.extendedModalSubValue}>{subvalue}</span>
            </Row>
            )
          })
        :
        ''}
        {/* <Row className='mb-3'>
          <span className={styles.extendedModalSubTitle} style={{paddingRight:'15px'}}> Pre-Market Hours</span>
          <span className={styles.extendedModalSubTitle}>:</span>
          <span className={styles.extendedModalSubValue}>4:00AM EST – 9:30AM EST</span>
        </Row>
        <Row className='mb-3'>
          <span className={styles.extendedModalSubTitle}>Post-Market Hours</span>
          <span className={styles.extendedModalSubTitle}>:</span>
          <span className={styles.extendedModalSubValue}>4:00PM EST – 6:00PM EST</span>
        </Row> */}
        <Row className={`mt-4 ${styles.extendedModalSubTitle}`}>Extended Hours Trading Disclosures</Row>
        <Row className={styles.extendedModalTnC} >
        <div dangerouslySetInnerHTML={{__html: disclosure.content}}/>
         {/* {Disclosure} */}
        </Row>
        <Row className={styles.otpSuccess} style={{ justifyContent: 'center'}}>
          <Col md={12} xs={12} style={{textAlign:'center',marginTop:"20px"}}>
          {errorMessage}
        </Col>
        </Row>
        <Row className={styles.marginRow}>
          {/* <Col className={styles.paddingSetting} xs={12} md={6}>
           <div className={`custom-control custom-checkbox ${styles.checkBox}`}>
            <input
              type='checkbox'
              className={`custom-control-input ${styles.checkBoxInput}`}
              id='extendedDisclosure'
              name='extendedDisclosure'
              value={isAccepted}
              onChange={() => {
                setIsAccepted(!isAccepted)
              }}
            />
            <label
              className={`custom-control-label ${styles.checkMark}`}
              htmlFor='extendedDisclosure'>
              I have read the disclosures and understand the risks involved with extended hours trading
            </label>
          </div>
         </Col> */}
         <Col xs={12} md={8}>
         <div className={styles.extendedModalSubTitle} style={{marginBottom:'4px',paddingRight:'0px'}}>
          I have read the disclosures and understand the risks involved with extended hours trading
          </div>
         </Col>
          <Col style={{textAlign:'end',paddingRight:'0px'}} xs={12} md={4}>
            <Button className={styles.extendedModalButton} onClick={signDisclouser}
              disabled={loading}>
                {loading ? <Spinner animation="border" className={styles.spinnerColor}/> : 'Accept'}
              </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ExtendedDisclosureModal
