import React, { useContext, useEffect } from "react"
import styles from "./direct.module.css"
import InfoIcon from "../../../../public/images/infofaded.svg"
import { OverlayTrigger,Spinner,Tooltip} from 'react-bootstrap'
import { AddFundContext } from '../../AddFundContext'
import Upload from "./Upload"
import { HDFCTransDetails, HDFCVerifyOTP, requestOTP } from "../../../../api"
import { useState } from "react"
import Success from "../Common/Success"
import GenericLoading from "../../GenericLoading"
import AlertErrorIcon from '../../../../public/images/alertError.svg'
import Error from "./Error"
import SelectRadioBtn from "../../../SelectRadioBtn/SelectRadioBtn"
import SelectCheckBox from "../../../SelectCheckBox/SelectCheckBox"
import { LEGAL_LINKS } from '../../../../constants';

const DirectTransfer = ()=>{
    const {fund, subPage, setSubPage, selectedAccount, setPage, setCancelDetails, setShowCancel,setNoHeader, PAGE, SUBPAGE} = useContext(AddFundContext)
    const [seconds, setSeconds] = useState(0)
    const [otpDetails, setOtpDetails] = useState(null)
    const [optError, setOtpError] = useState(false)
    const [otpErrorMsg, setOtpErrorMsg] = useState(null)
    const [otpFieldError, setOtpFieldError] = useState(false)
    const [otpLoading, setOtpLoading] = useState(false)
    const [fetchError, setFetchError] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [otp, setOtp] = useState('')
    const [isTaxPayer, setIsTaxPayer] = useState(false)
    const [tc, setTc] = useState(false)
    const [verifyingOtp, setVerifyingOtp] = useState(false)
    const [loading, setLoading] = useState(true)
    const [validate, setValidate] = useState(false)
    const [timer, setTimer] = useState(null)
    const [transactionDetails, setTransactionDetails] = useState({})
    const DIRECT=PAGE.DIRECT
    useEffect(()=>{
        setSubPage(SUBPAGE[DIRECT].OTP)
    },[])
    useEffect(()=>{
        setIsTaxPayer(selectedAccount.isTaxpayer)
    },[selectedAccount])
    useEffect(()=>{
        if(subPage===SUBPAGE[DIRECT].OTP){
            if(tc){
                setTc(false)
            }
            setValidate(false)
            setLoading(true)
            fetchDetails(true)
        }

    },[subPage])
    const fetchDetails = async(isOTPRequest)=>{
        if(!fetching){
            setFetching(true)
            setFetchError(false)
            try {
                if(isOTPRequest)
                    await sendOTP()
                const res = await HDFCTransDetails(fund)
                if(res.data && res.data.code===200){
                    setTransactionDetails(res.data.data)
                }
            } catch (error) {
                setFetchError(true)
            }
            setFetching(false)
            if(isOTPRequest)
                setLoading(false)
        }
    }
    const onChangeOtp=(value)=>{
        if(!otpErrorMsg || optError){
            if(!(/^[A-Za-z0-9]*$/.test(value))){
                if(otp || otp===0){
                    return
                }else{
                    value=''
                }
            }
            setOtp(value)
            if(validate){
                if(value && value.length>0){
                    setOtpError(false)
                    setOtpErrorMsg(null)
                    setOtpFieldError(false)
                }else{
                    setOtpError(true)
                    setOtpFieldError(true)
                }
            }
        }
    }
    const sendOTP = async()=>{
        onChangeOtp('')
        setOtpFieldError(false)
        setOtpError(false)
        setOtpErrorMsg(null)
        if(!otpLoading){
            setOtpLoading(true)
            try{
                const res = await requestOTP(selectedAccount.hashId)
                if(res.data && res.data.code ===200){
                    if(timer)
                        clearInterval(timer)
                    startTimer()
                    setOtpDetails(res.data.data)
                    setOtpErrorMsg(null)
                }
            }catch(error){
                if(error && error.data && error.data.code===400)
                    setOtpErrorMsg(error.data.message)
                else
                    setOtpErrorMsg('Something went wrong')
            }
            setOtpLoading(false)
        }
    }
    const startTimer = ()=>{
        let count = 120
        setSeconds(count)
        setTimer(setInterval(()=>{
            if(count > 0){
                count--
                setSeconds(count)
            }else{
                clearInterval(timer)
                setTimer(null)
            }
        },[1000]))
    }
    const verifyOTP = async()=>{
        if(!verifyingOtp &&(!otpErrorMsg || optError)){
            if(!validate)
                setValidate(true)
            let error = false
            if(!otp || !otp.length>0){
                error=true
                setOtpError(true)
                setOtpFieldError(true)
            }
            if(!tc)
                error=true
            if(!error){
                setVerifyingOtp(true)
                try {
                    const payload = {
                        otpId: otpDetails.id,
                        OTP: otp,
                        customerNo: selectedAccount.customerId,
                        isTaxResidencyOfIndia: isTaxPayer,
                        amount: fund
                    }
                    const res = await HDFCVerifyOTP(payload)
                    if(res.data && res.data.code===200){
                        setOtpErrorMsg(null)
                        if(otpDetails.bankStatement)
                            setSubPage(SUBPAGE[DIRECT].UPLOAD)
                        else
                            setSubPage(SUBPAGE.SUCCESS)
                    }
                } catch (error) {
                    if(error && error.data && error.data.code===400){
                        setOtpError(true)
                        setOtpErrorMsg(error.data.message)
                    }else{
                        setNoHeader(true)
                        setSubPage(SUBPAGE[DIRECT].ERROR)
                    }
                }
                setVerifyingOtp(false)
            }
        }
    }
    return (<>
        {subPage===SUBPAGE[DIRECT].OTP&&<>{loading ?
            <div className={styles.genericLoading}><GenericLoading /> </div>:
            <>
                <div className={styles.directHeader}>Direct Transfer Method</div>
                <div className={styles.directBody}>
                    <div className={styles.OTPContainer}>
                        <div className={styles.otpNote}>
                            Please enter the OTP sent to your registered mobile number.
                        </div>
                        <div className={`${styles.otpInput} ${optError && styles.errorInput}`}>
                            <input placeholder="Enter OTP" type='password' value={otp} onChange={(e)=>onChangeOtp(e.target.value)}/>
                            {optError && <img src={AlertErrorIcon}/>}
                        </div>
                        <div className={styles.otpCTA}>
                            {otpLoading ? 'Processing...':
                                <>{otpFieldError?
                                    <span className={styles.errorRegular}>
                                         This Field is required
                                    </span>
                                    :<>{otpErrorMsg ?
                                        <span className={styles.errorBook}>
                                            {`${otpErrorMsg} `}
                                            <span className={styles.textLink} onClick={sendOTP}>{optError?'Request New OTP':'Resend OTP'}</span>
                                        </span> :
                                        <>{seconds > 0 && `Expires in ${seconds}s `}
                                            {!(seconds > 0) &&<span className={styles.textLink} onClick={sendOTP}>Resend OTP</span>}
                                        </>
                                    }</>
                                }</>
                            }
                        </div>
                    </div>
                    <div className={styles.reviewContainer}>
                        <div className={styles.reviewHeader}>Review Transfer Details</div>
                        <div className={styles.reviewBody}>
                            <div className={styles.reviewSection}>
                                <div className={styles.row}>
                                    <span className={styles.label}>Transfer Amount</span>
                                    <span className={styles.value}>${fund}</span>
                                </div>
                                <div className={styles.row}>
                                    <span className={styles.label}>Transaction Charges</span>
                                    <span className={styles.value}>
                                        $0
                                        <OverlayTrigger
                                            placement='top'
                                            trigger={['hover', 'focus', 'click']}
                                            overlay={
                                                <Tooltip className={styles.tooltip}>
                                                    <div className={styles.tipHeader}>Transaction Charge</div>
                                                    <div className={styles.tipDesc}>A transaction charge is a charge that a business has to pay every time it processes a customer's payment.</div>
                                                </Tooltip>
                                            }>
                                            <img src={InfoIcon} className={styles.infoIcon} />
                                        </OverlayTrigger>
                                    </span>
                                </div>
                            </div>
                            <div className={styles.reviewSection}>
                                <div className={styles.row}>
                                    <span className={styles.label}>Total Amount</span>
                                    <span className={styles.value}>${fund}</span>
                                </div>
                                <div className={styles.row}>
                                    <span className={styles.label}>Exchange Value in INR</span>
                                    {!fetching && !fetchError && <span className={styles.value}>
                                        &#8377;{transactionDetails.lcy}
                                        <OverlayTrigger
                                            placement='top'
                                            trigger={['hover', 'focus', 'click']}
                                            overlay={
                                                <Tooltip className={styles.tooltip}>
                                                    <div className={styles.tipHeader}>Exchange Rate Fluctuations</div>
                                                    <div className={styles.tipDesc}>Exchange rates float freely against one another, which means they are in constant fluctuation.</div>
                                                </Tooltip>
                                            }>
                                            <img src={InfoIcon} className={styles.infoIcon} />
                                        </OverlayTrigger>
                                    </span>}
                                    {fetching && <span className={styles.label}>Fetching...</span>}
                                    {fetchError && !fetching && <span className={styles.errorBook}>
                                        {`Unable to fetch `}
                                        <span className={styles.textLink} onClick={()=>fetchDetails()}>Try Again</span>
                                    </span>}
                                </div>
                            </div>
                            <div className={`${styles.reviewSection} ${styles.row}`}>
                                <span className={styles.label}>Tax payer in India?</span>
                                <span className={styles.option}>
                                    <span onClick={()=>setIsTaxPayer(true)}>
                                        <SelectRadioBtn value={isTaxPayer}/>
                                        YES
                                    </span>
                                    <span onClick={()=>setIsTaxPayer(false)}>
                                        <SelectRadioBtn value={!isTaxPayer}/>
                                        NO
                                    </span>
                                </span>
                            </div>
                            <div className={styles.tc}>
                                <SelectCheckBox value={tc} handleChange={setTc}/>
                                <span>
                                    {`I agree to all the Terms & Conditions for `}
                                    <span className={styles.textLink} onClick={()=>window.open(LEGAL_LINKS.HDFC_TERM_CONDITION, '_blank')}>
                                        HDFC Bank Ltd.
                                    </span>
                                    {` and `}
                                    <span className={styles.textLink} onClick={()=>window.open(LEGAL_LINKS.STOCKAL_TERM_CONDITION2, '_blank')}>
                                        Stockal
                                    </span>
                                </span>
                            </div>
                            {!tc && validate && <div className={styles.errorRegular}>This Field is required</div>}
                        </div>
                    </div>
                    <div className={styles.footerContainer}>
                        <button className={styles.continueBtn} onClick={verifyOTP}>
                            {verifyingOtp && <Spinner animation="border" className={styles.spinnerColor}/>}
                            Verify OTP
                        </button>
                        <button className={styles.tertiaryBtn} onClick={()=>{
                                setCancelDetails({
                                    msg:'Are you sure you want to cancel the transaction?',
                                    onConfirm:()=>setPage(PAGE.TRANSFER),
                                    onCancel:()=>setShowCancel(false)
                                });
                                setShowCancel(true)
                            }}>
                            Try Another Method
                        </button>
                    </div>
                </div>
            </>}
        </>}
        {subPage===SUBPAGE[DIRECT].UPLOAD && <Upload otpId={otpDetails && otpDetails.id} />}
        {subPage===SUBPAGE.SUCCESS && <Success remittance={true}/>}
        {subPage===SUBPAGE[DIRECT].ERROR && <Error />}
    </>
    )
}

export default DirectTransfer
