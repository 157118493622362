const SPIN_INVALID = "Please enter valid spin";
const SPIN_LENGTH = 4;

/** Trade Contsants */
const BUY = 'BUY';
const SELL = 'SELL';
const STACK_CAT = 'stack';
const ETF_CAT = 'etf';
const STOCK_CAT = 'stock';

const MARKET_ORDER = 'MARKET';
const LIMIT_ORDER = 'LIMIT';
const STOP_ORDER = 'STOP';
const MARKET_TOUCHED_ORDER = 'MARKET_IF_TOUCHED';

// Portfolio API CALL Intervals
const PORTFOLIO_LIVE_PRICE_INTERVAL = 1000 * 5;
const PORTFOLIO_CURRENT_VALUE_INTERVAL = 1000 * 5;
const PORTFOLIO_DELTA_API_INTERVAL = 1000 * 10;

// AccountSummary API CALL INTERVALS
const ACC_SUMM_WITH_COMM_INTERVAL = 1000 * 10;

// otp resend interval in seconds
const ONE_MINUTE_INTERVAL = 180;

// Watchlist Api inetrvals
const WATCHLIST_LIVE_PRICE_INTERVAL = 1000 * 5;

const PORTFOLIO_HEADERS = {
  stack: 'Stack',
  stock: 'Stock',
  etf: 'ETF',
  logo:'Image',
  symbol:'',
  name: 'Name',
  price:'Price',
  change: 'Chng' ,
  totalInvestment: 'Total Invested',
  totalReturn: 'Total Return',
  totalReturnPer: 'Return Percent',
  currentValue: 'Current Value',
  totalUnit: 'Shares',
  averageCost: 'Avg.Cost',
  tos: 'tos'
}
const commonPortfolioHeaders = [
  PORTFOLIO_HEADERS.name,
  PORTFOLIO_HEADERS.price,
  PORTFOLIO_HEADERS.totalInvestment,
  PORTFOLIO_HEADERS.totalReturn,
  PORTFOLIO_HEADERS.currentValue
]
const stackHeader = [
  PORTFOLIO_HEADERS.stack,
  ...commonPortfolioHeaders
]

const stockHeader = [
  'Stock',
  'Name',
  'Price',
  'Shares',
  'Avg.Cost',
  'Total Invested',
  'Total Return',
  'Current Value'
]
const etfHeader = [
  'ETF',
  'Name',
  'Price',
  'Shares',
  'Avg.Cost',
  'Total Invested',
  'Total Return',
  'Current Value'
]

const paths = {
  etfDetail: '/etfDetail/',
  stockDetail: '/stockDetail/',
  stackDetail: '/stackDetail/',
  profile: '/profile',
}

const MARKET_OPEN_STATUS = 'Open';
const MARKET_CLOSE_STATUS = 'Close';

/**
 * Portfolio Summary Title
 */

const PF_SUM_TODAY_CHG = 'Today\'s change';
const PF_SUM_TOT_INV = 'Total invested';
const PF_SUM_CURR_VAL = 'Current value';
const PF_SUM_PL = 'Profit & Loss';
const PF_SUM_CB = 'Cash balance';

// Page titles

const PORTFOLIO_PAGE = 'portfolio'
const STACK_PAGE = 'stack'
const STOCK_PAGE = 'stock'
const SOMETHING_WENT_WRONG = 'errorpage'

const planSubStatus = {
  RENEW: 'renew',
  UPGRADE: 'upgrade',
  EXPIRE: 'expire',
  ACTIVE: 'active'
}

const southAsianList =[
  'CHN',
  'ESP',
  'HKG',
  'MYS',
  'PHL'
]
const menaList =[
  'ARE',
  'OMN',
  'QAT',
  'KWT',
  'SAU',
  'BHR'
]


const allowedCitizenshipList =[
  'CHN',
  'ESP',
  'HKG',
  'MYS',
  'PHL',
  'IND'
]

let menaCitizenshipList =[
  // 'IND',
  'ARE',
  'GBR',
  'FRA',
  'DEU',
  'ITA',
  'ESP',
  'PRT',
  'AUS',
  'ZAF',
  'OMN',
  'KWT',
  'SAU',
  'BHR',
  'QAT',
  'EGY',
  'SGP'
]
menaCitizenshipList = menaCitizenshipList.concat(allowedCitizenshipList)


const MARKET_TOUCHED = 'Market if Touched'

const LiquidateMessge = `Account has been marked liquidate only. You won't be able to buy anything further`;
const ANALYST_RATING_FEATURE = 'analystRatingFilter';
const MORNING_STAR_FEATURE = 'morningStar';
const TIP_RANK_FEATURE = 'tipRanks';
const ZACKS_RATING_FEATURE = 'zacksRatings';

const CORPORATE_FUNDS = 'https://www.stockal.com/funds';

export {
    SPIN_INVALID,
    SPIN_LENGTH,
    BUY,
    SELL,
    STACK_CAT,
    ETF_CAT,
    STOCK_CAT,
    MARKET_ORDER,
    LIMIT_ORDER,
    STOP_ORDER,
    MARKET_TOUCHED_ORDER,
    PORTFOLIO_CURRENT_VALUE_INTERVAL,
    PORTFOLIO_LIVE_PRICE_INTERVAL,
    PORTFOLIO_HEADERS,
    paths,
    MARKET_OPEN_STATUS,
    MARKET_CLOSE_STATUS,
    PORTFOLIO_DELTA_API_INTERVAL,
    PF_SUM_CB,
    PF_SUM_CURR_VAL,
    PF_SUM_TODAY_CHG,
    PF_SUM_TOT_INV,
    ACC_SUMM_WITH_COMM_INTERVAL,
    PORTFOLIO_PAGE,
    STACK_PAGE,
    STOCK_PAGE,
    SOMETHING_WENT_WRONG,
    ONE_MINUTE_INTERVAL,
    planSubStatus,
    PF_SUM_PL,
    WATCHLIST_LIVE_PRICE_INTERVAL,
    southAsianList,
    menaList,
    menaCitizenshipList,
    allowedCitizenshipList,
    MARKET_TOUCHED,
    LiquidateMessge,
    ANALYST_RATING_FEATURE,
    MORNING_STAR_FEATURE,
    TIP_RANK_FEATURE,
    ZACKS_RATING_FEATURE,
    CORPORATE_FUNDS
}

export const partnerList = [
  "HDFC","HDFCGLOBAL","STOCKAL","STOCKALGLOBAL"
]

export const LEGAL_LINKS = {
  BROKER_DISCLAIMER: "https://help.stockal.com/portal/en/kb/articles/broker-disclaimer",
  PRIVACY_POLICY: "https://help.stockal.com/portal/en/kb/articles/privacy-policy",
  STOCKAL_DISCLAIMER: "https://help.stockal.com/portal/en/kb/articles/stockal-disclaimer",
  QUICKO_TERMS_CONDITIONS: "https://help.stockal.com/portal/en/kb/articles/terms-conditions-redirection-to-quicko",
  TERMS_AND_CONDITIONS: "https://help.stockal.com/portal/en/kb/articles/terms-and-conditions",
  USER_AGREEMENT: "https://help.stockal.com/portal/en/kb/articles/user-agreement",
  SECURITIES_LENDING_DISCLOSURE: "https://help.stockal.com/portal/en/kb/articles/fullypaid-securities-lending-disclosure",
  GOOD_FAITH_VIOLATION: "https://help.stockal.com/portal/en/kb/articles/what-is-a-good-faith-violation",
  //new link not available redirected to home
  // HDFC_TERM_CONDITION: "https://stockal.freshdesk.com/en/support/solutions/articles/43000691978-hdfc-terms-and-conditions",
  HDFC_TERM_CONDITION: "https://help.stockal.com/portal/en/kb",
  // STOCKAL_TERM_CONDITION2: "https://stockal.freshdesk.com/en/support/solutions/articles/43000691979-terms-conditions-direct-transfer",
  STOCKAL_TERM_CONDITION2: "https://help.stockal.com/portal/en/kb",
  // ELECTRONIC_COMMUNICATION_AGREEMENT: "https://stockal.freshdesk.com/support/solutions/articles/43000690776-electronic-communications-agreement-cash-account",
  ELECTRONIC_COMMUNICATION_AGREEMENT: "https://help.stockal.com/portal/en/kb",
  // EASYFUND_TOS: "https://stockal.freshdesk.com/support/solutions/articles/43000690742-easyfund-tos",
  EASYFUND_TOS: "https://help.stockal.com/portal/en/kb",
  // LEARN_MORE: "https://stockal.freshdesk.com/support/solutions/43000372440",
  LEARN_MORE: "https://help.stockal.com/portal/en/kb",
  // CASH_ACCOUNT_TOS: "https://stockal.freshdesk.com/support/solutions/articles/43000690779-terms-conditions-cash-account",
  CASH_ACCOUNT_TOS: "https://help.stockal.com/portal/en/kb",
  HELP_HOME: "https://help.stockal.com/portal/en/home",
  CONTINGENT_LINK: "https://help.stockal.com/portal/en/kb/articles/what-is-the-difference-between-primary-and-contingency-nominee",
  W8_BEN: "https://help.stockal.com/portal/en/kb/articles/what-is-the-w8-ben-certification-fee-10-1-2025"
};

