import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import defaultTheme from './default_theme.json';
import { fetchLocation } from '../common';
export const ThemeContext = createContext()

const ThemeContextProvider = ({ children }) => {
  const [buttonColor, setButtonColor] = useState('')
  const [loaderColor, setLoaderColor] = useState('')
  const [logo, setLogo] = useState('')
  const [siteLogo, setSiteLogo] = useState('')
  const [shortLogo, setShortLogo] = useState('')
  const [isPartner, setIsPartner] = useState('')
  const [userReferralCode, setUserReferralCode] = useState('')
  const [partnerCode, setPartnerCode] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [siteDisclaimer, setsSiteDisclaimer] = useState('')
  const [siteDisclaimerLink, setsSiteDisclaimerLink] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [buttonStyle, setButtonStyle] = useState('')
  // const [bankList, setBankList] = useState('')
  // const [rzpuserid, setrzpuserid] = useState('')
  const [restrictTo, setrestrictTo] = useState('')
  const [disclosuresLink, setdisclosuresLink] = useState('')
  const [termsAndConditionsLink, settermsAndConditionsLink] = useState('')
  const [userAgreementLink, setuserAgreementLink] = useState('')
  const [privacyPolicyLink, setprivacyPolicyLink] = useState('')
  const [isFundButton, setisFundButton] = useState(true)
  const [landingUrl, setlandingUrl] = useState('')
  const [contactMail, setcontactMail] = useState('')
  const [botHelp, setbotHelp] = useState('')
  const [razorpaySetting, setRazorpaySetting] = useState('')
  const [signoutUrl,setSignoutUrl] = useState('')
  const [isSavingAccount,setIsSavingAccount] = useState(false)
  const [savingAccountDisclaimer,setsavingAccountDisclaimer] = useState('')
  const [leadSqCompID,setLeadSqCompID] = useState('')
  const [location,setLocation] = useState('')
  const [siteFeatures,setSiteFeatures] = useState('')
  const [hdfcDirect,setHdfcDirect]  = useState(false)
  const [fairexpay,setFairexpay] = useState(false)
  const [vendorName,setVendorName] = useState('');
  const [singUpValid,setSignUpValid] = useState(false);
  const [partnerConfig,setPartnerConfig] = useState([]);
  const [isThemeLoading,setIsThemeLoading] = useState(false);

  const fetchTheme = async (partnerLocation) => {
    let host = window.location.host
    if(host.includes('www.'))
        host = host.replace('www.','')
    if (host.includes('localhost') || host.includes('ec2-3-80-137-66.compute-1.amazonaws.com'))
      host = process.env.REACT_APP_PRODUCTION ? 'platform.stockal.com' : 'uat-platform.stockal.com';
      // host = 'uat-platform.stockal.com' ;
      // host = 'alpha-jmfinancialservices.stockal.com'
      //  host = 'alpha-moneycontrol.stockal.com'
      //  host = 'alpha-centrum.stockal.com'
      // host = 'motilaloswal.stockal.com'
    if (host.includes('ec2-3-80-27-33.compute-1.amazonaws.com'))
      host = 'platform.stockal.com'

    if (host.includes('beta-hdfc.stockal'))
      host = 'globalinvesting.in'

    if (host.includes('beta-platform'))
      host = 'platform.stockal.com'

    if (host.includes('beta-') && !host.includes('hdfc'))
      host = host.replace('beta-','')



    if(host === 'fe.stockal.com')
    window.location = 'https://platform.stockal.com/signin';

    if(host.includes('moneycontrol')) {
      if (window.location.href.includes('landing-page'))
        window.location = window.location.href.replace('landing-page', 'signin');
    }
    const sessionLocation = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : "";
    try {
      const location = partnerLocation ? partnerLocation : sessionLocation && sessionLocation.country !== "" ? sessionLocation.country == "IND" ? 'IN' : "OTHER" : await fetchLocation();
      let url = `${process.env.REACT_APP_BASE_API}/v2/site/theme-data/${host}`;
      if(location) url = url+'/'+location;
      const response = await axios.get(url);
      // if (response.status === 200) {
      if (response.status === 200 && response.data.data !== {}) {
        let res = response.data.data
        sessionStorage.setItem('logoutPage', res.signoutUrl ? res.signoutUrl : '')
        setSignUpValid(res.siteFeatures && res.siteFeatures.includes('signup') ? true : false)
        setSignoutUrl(res.signoutUrl ? res.signoutUrl : '')
        setLocation(res.location ? res.location : '')
        setButtonColor(res.themePrimaryColor ? res.themePrimaryColor : defaultTheme.themePrimaryColor)
        // setButtonColor('red')
        setLoaderColor(res.themeLoaderColor ? res.themeLoaderColor : defaultTheme.themeLoaderColor)
        setsSiteDisclaimer(res.siteDisclaimer ? res.siteDisclaimer : defaultTheme.siteDisclaimer)
        setsSiteDisclaimerLink(res.siteDisclaimerLink ? res.siteDisclaimerLink : defaultTheme.siteDisclaimerLink)
        setIsPartner(res.isPartner ? res.isPartner : defaultTheme.isPartner)
        setUserReferralCode(res.userReferralCode ? res.userReferralCode : '')

        setPartnerCode(res.code) //mandatory
        setPartnerName(res.partnerName ? res.partnerName : defaultTheme.partnerName)
        setSiteLogo(res.siteLogo ? res.siteLogo : defaultTheme.siteLogo)
        setShortLogo(res.shortLogo ? res.shortLogo : (res.siteLogo ? res.siteLogo : defaultTheme.siteLogo))
        setContactNumber(res.phone ? res.phone : defaultTheme.phone)
        setLogo(res.logo)
        setcontactMail(res.supportEmail ? res.supportEmail : defaultTheme.supportEmail)
        setlandingUrl(res.landingUrl ? res.landingUrl : defaultTheme.landingUrl)

        setdisclosuresLink(res.disclosuresLink ? res.disclosuresLink : '')
        settermsAndConditionsLink(res.termsAndConditionsLink ? res.termsAndConditionsLink : defaultTheme.termsAndConditionsLink)
        setuserAgreementLink(res.userAgreementLink ? res.userAgreementLink : defaultTheme.userAgreementLink)
        setprivacyPolicyLink(res.privacyPolicyLink ? res.privacyPolicyLink : defaultTheme.privacyPolicyLink)
        setIsSavingAccount(res.isSavingAccount ? res.isSavingAccount : false)
        setsavingAccountDisclaimer(res.savingAccountDisclaimer ? res.savingAccountDisclaimer : '')
        setLeadSqCompID(res.leadSqCompID ? res.leadSqCompID : '')
        setSiteFeatures(res.siteFeatures ? res.siteFeatures : "")
        setPartnerConfig(res.partnerConfig ? res.partnerConfig : [])
        setIsThemeLoading(true)

        if(res.fundingServices && res.fundingServices.length > 0){
           res.fundingServices.map((item) => {
             if(item.hasOwnProperty('vendorName')){
             const value =  item.vendorName === 'FAIREXPAY' ?  setFairexpay(true) :
                  item.vendorName === 'HDFC_DIRECT' ? setHdfcDirect(false) : ''
             }
           })
           setVendorName(res.fundingServices)
        }
        if (res.razorpaySetting) {
          setRazorpaySetting(res.razorpaySetting)
        }
        if (res.isFundButton) {
          setisFundButton(res.isFundButton)
        }
        if (res.favicon) {
          let link = document.createElement('link')
          link.rel = 'icon'
          link.href = res.favicon
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        if (res.favicon32) {
          let link =
            document.querySelector("link[sizes*='32x32']") ||
            document.createElement('link')
          link.sizes = '32x32'
          link.rel = 'icon'
          link.href = res.favicon32
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        if (res.favicon192) {
          let link =
            document.querySelector("link[sizes*='192x192']") ||
            document.createElement('link')
          link.rel = 'icon'
          link.href = res.favicon192
          link.sizes = '192x192'
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        if (res.faviconApple) {
          let link = document.createElement('link')
          link.rel = 'apple-touch-icon-precomposed'
          link.href = res.faviconApple
          // link.sizes = '180x180';
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        if (res.buttonStyle) {
          const styleButton = '.themeButton' + res.buttonStyle
          let head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style')
          head.appendChild(style)
          style.type = 'text/css'
          if (style.styleSheet) {
            style.styleSheet.cssText = styleButton
          } else {
            style.appendChild(document.createTextNode(styleButton))
          }
        }
        return res;
      } else {
        console.info('***********Theme Data is not available for current Host**********')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTheme()
  }, [])


  return (
    <ThemeContext.Provider
      value={{
        contactNumber: contactNumber,
        buttonColor: buttonColor,
        loaderColor: loaderColor,
        partnerName: partnerName,
        siteLogo: siteLogo,
        shortLogo: shortLogo,
        siteDisclaimer: siteDisclaimer,
        siteDisclaimerLink: siteDisclaimerLink,
        isPartner: isPartner,
        userReferralCode: userReferralCode,
        partnerCode: partnerCode,
        buttonStyle: buttonStyle,
        isFundButton: isFundButton,
        landingUrl: landingUrl,
        contactMail: contactMail,
        disclosuresLink: disclosuresLink,
        termsAndConditionsLink: termsAndConditionsLink,
        privacyPolicyLink: privacyPolicyLink,
        userAgreementLink: userAgreementLink,
        razorpaySetting: razorpaySetting,
        signoutUrl:signoutUrl,
        isSavingAccount:isSavingAccount,
        savingAccountDisclaimer:savingAccountDisclaimer,
        leadSqCompID:leadSqCompID,
        location:location,
        siteFeatures:siteFeatures,
        hdfcDirect:hdfcDirect,
        fairexpay:fairexpay,
        vendorName:vendorName,
        singUpValid:singUpValid,
        partnerConfig:partnerConfig,
        isThemeLoading:isThemeLoading,
        fetchTheme
      }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
