import React from "react";
import styles from "./bottomNav.module.css"; // Import module CSS
import house from "../../public/images/home.svg";
import holdings from "../../public/images/holdins.svg";
import blog from "../../public/images/blog.svg";
import search from "../../public/images/searchIcon.svg";
import profile from "../../public/images/profileLogo.svg";
import { useHistory } from "react-router-dom";
import Explore from '../../public/images/Explore.svg';

const BottomNav = () => {
  let history = useHistory();

  return (
    <nav className={styles.bottomNav}>
      <div className={styles.navItem} onClick={() => history.push("/home")}>
        <img src={house} alt="Home" className={styles.icon} />
        <span>Home</span>
      </div>
      <div className={styles.navItem} onClick={() => history.push("/portfolio")}>
        <img src={holdings} alt="Holdings" className={styles.icon} />
        <span>Holdings</span>
      </div>
      <div className={styles.navItem} onClick={() => history.push("/explore")}>
        <img src={Explore} alt="Explore" className={styles.icon} />
        <span>Explore</span>
      </div>
      {/* <div className={styles.navItem} onClick={() => history.push("/blog")}>
        <img src={blog} alt="Blog" className={styles.icon} />
        <span>Blog</span>
      </div> */}
      <div className={styles.navItem} onClick={() => history.push("/profile")}>
        <img src={profile} alt="Profile" className={styles.icon} />
        <span>Profile</span>
      </div>
    </nav>
  );
};

export default BottomNav;
