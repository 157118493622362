import React,{useContext,useEffect} from 'react'
import {Row,Col, Button,Spinner} from 'react-bootstrap'
import styles from './easyfund.module.css'
import SelectRadioBtn from '../../../SelectRadioBtn/SelectRadioBtn'
import CheckBox from './../../../CheckBox/CheckBox'
import { useState } from 'react'
import Delete from '../../../../public/images/deleteEasyFund.svg'
import CashMgmtToolTip from '../../../../containers/CashManagement/Tooltip'
import { AddFundContext } from "../../AddFundContext"
import {getQuoteEasyfund, getPaymentLinkEasyFund, previousTransactionFund} from '../../../../api'
import GenericLoading from '../../GenericLoading'
import { format } from "date-fns"
import DatePicker from "../../../DatePicker/DatePicker"
import CalendardIcon from "../../../../public/images/calendar.svg"
import PlusIcon from "../../../../public/images/plus.svg"
import AlertErrorIcon from "../../../../public/images/alertError.svg"
import {decimalAdjust} from '../../../../utils'
import { number } from '@storybook/addon-knobs'
import {isNumber} from 'lodash'
import moment from 'moment'
import { LEGAL_LINKS } from '../../../../constants';

let financial_year = "";
let today = new Date();
if ((today.getMonth() + 1) <= 3) {
    financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear()
} else {
    financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1)
}
const startDate = new Date(financial_year.split('-')[0],3,1)
const endDate = new Date()


const ReviewEasyFundDetails = () => {
  const {subPage, setSubPage, fund, hashID, selectedAccount, transferOptions, setPage, setOnErrorRetry, showGenericErrorOption,setShowGenericErrorOption, PAGE, SUBPAGE, setTransactionId} = useContext(AddFundContext)
  const[lrsCheckBox, setLrsCheckBox]= useState(false)
  const [loadingContinue, setLoadingContinue] = useState(false)
  const[lrsInputField, setLrsInputField] = useState([{transactionValue:1, Amount: "", DOT : "", BankAddress:"" }])
  const[fundQuote,setFundQuote] = useState('')
  const[loading, setLoading] = useState(false)
  const[gst, setGst]= useState()
  const[finalPaymentAmount, setFinalPaymentAmount]= useState()
  const[quoteId, setQuoteId]= useState()
  const isApiLoaded = loading && fundQuote !== "" ? true : false;
  const [transactions, setTransactions] = useState([])
  const [transactionError, setTransactionsError] = useState([])
  const [showCalendar, setShowCalendar] = useState(null)
  const [validate, setValidate] = useState(null)
  const [showTransactions, setShowTransactions] = useState(false)
  const[termsAndCondition, setTermsAndCondition] = useState(false)
  const[toAmount, setToAmount] = useState(null)
  const[tcs, setTcs] = useState(null)
  const[transactionCharges,setTransactionCharges] = useState(null)
  const[baseAmount,setBaseAmount]= useState(null)
  const[exchangeRate, setExchangeRate] = useState('')
  const[expiryTime, setExpiryTime] = useState(null)
  const[isQuoteExpired, setIsQuoteExpired] = useState(true)
  console.log('')
  const digitsAfterDecimal = (num) => {
    if (Number.isInteger(num)) {
      return `${addCommasinPrice(parseFloat(num))}.00`;
    }

    const arr = num.toString().split('.');
    if(arr[1].length === 1){
      return `${addCommasinPrice(parseFloat(num))}0`
    }else{
      return `${addCommasinPrice(parseFloat(num))}`
    }
  }

  function addCommasinPrice(price){
    if(price && isNumber(price)) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return price
  }

  const transferDetail = [
    // {type:"Transfer Amount", value:`$${fund}`},
    {type:"Tax Collected at Source (TCS)", value:isApiLoaded && tcs !== null ? `₹${addCommasinPrice(parseFloat(tcs))}` : "--", toolTip:"TCS at 5% on LRS forex transactions over INR 7 Lakhs"},
    {type:"Goods and Services Tax", value: isApiLoaded && gst !== "" && gst !== undefined ? Number.isInteger(gst) ?   `₹${addCommasinPrice(parseFloat(gst))}.00` : `₹${addCommasinPrice(parseFloat(gst))}` : "--", toolTip:"Applicable on fees & charges"},
    {type:"Total Tax and Charges", value: isApiLoaded && transactionCharges !== null ? `₹${digitsAfterDecimal(transactionCharges)}` : "--", toolTip:""}
  ]
  useEffect(() => {
    fetchData()
  },[])

  const fetchData = async() => {
    let success = true
    setLoading(false)
    setTermsAndCondition(false)
    setShowTransactions(false)
    const resp = await getQuoteEasyfund(fund).then(response => {
      if(response.status === 200){
        success = true
        setLoading(true)
        setFundQuote(response.doc)
        setGst(response.doc.gst)
        setFinalPaymentAmount(response.doc.finalPaymentAmount)
        setQuoteId(response.doc.quoteId)
        setToAmount(response.doc.toAmount)
        setTcs(response.doc.tcs)
        setTransactionCharges(response.doc.totAdditionalAmount)
        setBaseAmount(response.doc.baseAmount)
        setExchangeRate(response.doc.rate)
        setExpiryTime(response.doc.expireTime)
        setIsQuoteExpired(true)
      }
    }).catch((err)=>{
      setLoading(true)
      success=false
    })
    if(success && showGenericErrorOption){
      setShowGenericErrorOption(false)
      setOnErrorRetry(null)
      }else if(!success){
          setOnErrorRetry((showGenericErrorOption) => fetchData())
          setShowGenericErrorOption(true)
      }
  }

  const addFormFields = (e) => {
    e.preventDefault();
    if(lrsInputField.length > 0 ){
    setLrsInputField(
       [...lrsInputField,{transactionValue:lrsInputField.length+1, Amount: "", DOT : "", BankAddress:"" }]
    )} else {
      setLrsInputField([{transactionValue:1, Amount: "", DOT : "", BankAddress:"" }])
    }
  }

  const removeFormFields = (i) => {
    let formValues = lrsInputField;
    // formValues.splice(i, 1);
    const filteredArray = formValues.filter((item) => item.transactionValue !== i);
    setLrsInputField(filteredArray);
  }

  const onContinue = async()=>{
    let error = false
    if(showTransactions){
        setValidate(transactions.length)
        let transError=[]
        for(let i=0;i<transactions.length;i++){
            transError.push({prevLrsAmount:transactions[i]['prevLrsAmount']&&transactions[i]['prevLrsAmount'].length>0?false:(()=>{error=true; return true})(),
                prevLrsDate:transactions[i]['prevLrsDate']&&transactions[i]['prevLrsDate'].length>0?false:(()=>{error=true; return true})(),
                prevLrsBank:transactions[i]['prevLrsBank']&&transactions[i]['prevLrsBank'].length>0?false:(()=>{error=true; return true})()})
        }
        setTransactionsError([...transError])
        if(!error){
          const fetchData = async() => {
            const resp = await previousTransactionFund(hashID, showTransactions?transactions:[]).then(response => {
              if(response.status === 200){
                console.log('successfully send')
              }
            }).catch((err)=>{
              console.error('transactionError',err)
          })
          }
          fetchData()
          generatePaymentLink()
        }
    }
  }

  const timestamp = new Date();
  const momentTimestamp = moment(timestamp);
  const checkIfQuoteExpired = () => {
    const expiryValue = moment(expiryTime);
    const IsExpired =  momentTimestamp.isBefore(expiryValue);
    setIsQuoteExpired(IsExpired)
    if(IsExpired){
      continueTransaction()
    }
  }

  const continueTransaction = () =>{
    setLoadingContinue(true)
    if(showTransactions){
      onContinue()
    }else{
      generatePaymentLink()
    }
  }

  const generatePaymentLink = () => {
    const fetchData = async() => {
      setLoading(false)
      let success = true
      const body = {
        quoteId:quoteId,
        bankId:selectedAccount.hashId,
        redirectUrl:`${window.location.origin}/easyfund`
      }
      const resp = await getPaymentLinkEasyFund(body).then(response => {
        if(response.status === 200){
          success=true
          window.open(response.doc.payLink, "_blank")
          setSubPage(SUBPAGE[PAGE.EASYFUND].UPLOADSTATEMENT)
          setTransactionId(response.doc.transactionId)
        }
        else{
          setLoading(true,setLoadingContinue(false))
        }
      }).catch((err)=>{
        success=false
        setLoading(true,setLoadingContinue(false))
        console.error(err)
    })
    if(success && showGenericErrorOption){
      setShowGenericErrorOption(false)
      setOnErrorRetry(null)
      }else if(!success){
          setOnErrorRetry((showGenericErrorOption) => fetchData())
          setShowGenericErrorOption(true)
      }
    }
    fetchData()
  }

  const deleteTransaction = (index)=>{
    let temp = [...transactions]
    temp.splice(index, 1)
    setTransactions(temp)
  }

  const onChange= (index, field, value)=>{
    if(field==='prevLrsDate'){
        value=format(value, 'yyyy-MM-dd')
        setShowCalendar(false)
    }else if(field==='prevLrsAmount' && value > 250000){
        return false
    }else if(field==='prevLrsBank' && value.length > 60){
        return false
    }
    let temp = [...transactions]
    temp[index][field] = value
    setTransactions(temp)
    if(validate>index){
        let transError=[...transactionError]
        if(value && value.length>0){
            transError[index][field]=false
        }else{
            transError[index][field]=true
        }
        setTransactionsError([...transError])
    }
  }

  return(<>{!loading ?<div className={styles.genericLoading}><GenericLoading /> </div>:
    <>
    {isQuoteExpired === false ?
    <Row>
      <Col className={`${styles.bankName}`} style={{color:"red",fontSize:"18px",marginBottom:"10px"}}>
        Quote Expired.
        <span style={{color:'blue',cursor:"pointer"}} onClick={() => fetchData()}>Refresh Quote</span>
      </Col>
    </Row> : ""}
    <Row className={`${styles.reviewDetailsBankRow}`}>
      <Col md={1} xs={1}>
      <img src={ selectedAccount && selectedAccount.bank && selectedAccount.bank.logo} className={styles.easyFundLogo}/>
      </Col>
      <Col  className={styles.bankName}>
      {selectedAccount && selectedAccount.bankName} - {selectedAccount && selectedAccount.accountNumber.toString().slice(-4)}
      </Col>
      <Col md={4} xs={4} className={styles.textEasyFund} style={{paddingLeft:'0px'}}>
      EasyFund
      </Col>
    </Row>
    <Row>
      <Col className={`${styles.bankName} ${styles.bottomBorderReviewEasyFund}`}>
        Review transfer details
      </Col>
    </Row>
    <Row className={`${styles.reviewEasyFund}`}>
      <Col md={8} xs={7} className={styles.type}>
        Transfer Amount
      </Col>
      <Col className={styles.value}>
        ${isApiLoaded && toAmount !== null ? `${toAmount}.00` : "--" }
      </Col>
    </Row>
    <Row className={`${styles.reviewEasyFund} ${styles.bottomBorderReviewEasyFund}`} style={{paddingBottom:"0px"}}>
      <Col md={8} xs={7} className={styles.type}>
        Amount in INR <CashMgmtToolTip content={`$1 = ₹${exchangeRate !== "" ? decimalAdjust('floor',1/exchangeRate,-4) : "--"} This rate has been blocked for 30 mins.`}/>
      </Col>
      <Col className={styles.value}>
        ₹{isApiLoaded && baseAmount !== null ? addCommasinPrice(parseFloat(baseAmount)): "--"}
      </Col>
    </Row>
    {transferDetail.map((data, index) => {
      return(
      <Row className={styles.reviewEasyFund}>
      <Col md={8} xs={8} className={styles.type}>
        {data.type} {data.toolTip ? <CashMgmtToolTip content={data.toolTip}/> : ""}
      </Col>
      <Col className={styles.value}>
        {data.value}
      </Col>
    </Row>)
    })}
    <Row className={`${styles.reviewEasyFund} ${styles.topBorderReviewEasyFund}`}>
      <Col md={8} xs={7}  className={styles.type}>
        Total Amount Debited
      </Col>
      <Col className={styles.value}>
        ₹{isApiLoaded &&  finalPaymentAmount ? digitsAfterDecimal(finalPaymentAmount) : ""
        // ? `₹${finalPaymentAmount}.00` : `₹${finalPaymentAmount}`  : ""
        }
      </Col>
    </Row>
    <Row className={styles.reviewEasyFundGreyBox}>
      <Col className={styles.text}>Have you done any LRS transactions <br/> in the current financial year?</Col>
      <div className={styles.radioButtonEasyFund}>
        <div
        // onClick={() => setLrsCheckBox(true)}
        onClick={()=>{setTransactions(transactions.length>0?transactions:[{prevLrsAmount: '', prevLrsBank: '', prevLrsDate: ''}]);setShowTransactions(true)}}
        ><SelectRadioBtn value={showTransactions}/></div>
        <div className={styles.text}>Yes</div>
        <div style={{marginLeft:"16px"}} onClick={()=>setShowTransactions(false)}>
          <SelectRadioBtn value={!showTransactions}/>
        </div>
        <div className={styles.text}>No</div>
      </div>
    </Row>
    {showTransactions ?
    <>
    <Row>
      <Col className={styles.financialYearHead}>Please provide details of foreign currency transactions you’ve done in the current financial year (April - March)</Col>
    </Row>
    {transactions && transactions.length ?  transactions.map((data,index) => {
    return(<>
    <Row>
      <Col className={`${styles.easyFundLrsTransaction} ${styles.bottomBorderReviewEasyFund}`} >
        Transaction {index+1}
        {transactions.length-1 === index ?
        <img style={{float:"right", cursor:"pointer"}}
        // onClick={() => removeFormFields(data.transactionValue)}
        onClick={()=>deleteTransaction(index)}
        src={Delete}/> : ""}
      </Col>
    </Row>
    <div className={styles.inputContainer}>
      <label>Amount in USD</label>
        <div className={`${styles.input} ${validate>index&& transactionError[index].prevLrsAmount && styles.errorInput}`}>
            <div className={styles.inputLeft}>
                <input type='number' name='amount' placeholder="Enter Amount"
                 value={data.prevLrsAmount} onChange={(e)=>onChange(index, 'prevLrsAmount', e.target.value)}
                 onKeyPress={(e)=>(e.key==='.'||e.key==='-') && e.preventDefault()}/>
            </div>
            {validate>index&& transactionError[index].prevLrsAmount && <img src={AlertErrorIcon} />}
        </div>
        {(validate>index&& transactionError[index].prevLrsAmount) &&<span className={styles.errorMsg}>This Feild is required</span>}
    </div>
      {/* new date */}
      <div className={styles.inputContainer} style={{marginTop:"20px"}}>
      <label>Date of Transaction</label>
      <div className={`${styles.input} ${validate>index&& transactionError[index].prevLrsAmount && styles.errorInput}`}>
          <input
              placeholder="Select Date of Transaction"
              value={data.prevLrsDate}
              onFocus={()=>setShowCalendar(!showCalendar?index:null)}
          />
          <img src={CalendardIcon} onClick={() => {
                  setShowCalendar(!showCalendar?index:null)
              }}/>
      </div>
      {(validate>index&& transactionError[index].prevLrsDate) && <span className={styles.errorMsg}>This Feild is required</span>}
      {showCalendar === index && (
          <div
          style={{
              marginTop:"5px",
              zIndex: '1000',
              position: 'absolute',
              width: '91%'
          }}
          >
          <DatePicker
              onChange={date => onChange(index, 'prevLrsDate', date)}
              minDate={startDate}
              maxDate={endDate}
              color='#0061D3'
          />
          </div>
      )}
    </div>
  {/* end */}
    {/* <div className={styles.inputContainer} style={{marginTop:"20px"}}>
      <label>Date of Transaction</label>
        <div className={`${styles.input}`}>
            <div className={styles.inputLeft}>
                <input type='number' name='amount' placeholder="Select Date of Transaction" />
            </div>
        </div>
    </div> */}
    <div className={styles.inputContainer} style={{marginTop:"20px"}}>
      <label>Bank/AD Name & Address</label>
        <div className={`${styles.input} ${validate>index&& transactionError[index].prevLrsBank && styles.errorInput}`}>
            <div className={styles.inputLeft}>
                <input type="text" name='text' placeholder="Enter Bank/AD Name & Address"
                 value={data.prevLrsBank}
                onChange={(e)=>onChange(index, 'prevLrsBank', e.target.value)} />
            </div>
        </div>
        {!(validate>index&& transactionError[index].prevLrsBank) ?<span className={styles.helper}></span>:
        <span className={styles.errorMsg}>This Feild is required</span>}
    </div>
    </>)}) : ""}
    {transactions.length < 3&&
     <Row className={styles.addNewTransaction}>
    <div className={styles.addTransaction}>
      <Col md={12} className={styles.text} onClick={()=>setTransactions([...transactions, {prevLrsAmount: '', prevLrsBank: '', prevLrsDate: ''}])}>
        <img src={PlusIcon}/>
        <span>Add New Transaction</span>
      </Col>
    </div>
    </Row>}
    </> : ""}
    <Row>
      <div className={styles.radioButtonEasyFund} style={{paddingBottom:"150px"}}>
      <div style={{marginLeft:"15px"}}>
      <CheckBox
        label=''
        id='isTermsAndCondition'
        name='isTermsAndCondition'
        value={termsAndCondition}
        onChange={() => setTermsAndCondition(!termsAndCondition)}
        isChecked={termsAndCondition}
      /></div>
      <div className={styles.easyFundTermsCondition}>I agree to Terms & Conditions under <a href={LEGAL_LINKS.ELECTRONIC_COMMUNICATION_AGREEMENT} target="_blank">Easyfund</a></div>
      </div>
    </Row>
    <div className={styles.footerContainer}>
      <button className={styles.continueBtn} disabled={loadingContinue} onClick={termsAndCondition ? () => checkIfQuoteExpired() : ""} style={{backgroundColor: termsAndCondition ? "" : "grey",border:"none"}}>
          {loadingContinue&&<Spinner animation="border" className={styles.spinnerColor}/>}
          Continue
      </button>
      {transferOptions && Object.keys(transferOptions).length>=1 && <button className={styles.tertiaryBtn} onClick={()=>!loadingContinue&&setPage(PAGE.TRANSFER)}>Try Another Method</button>}
  </div>
  </>}</>
  )
}

export default ReviewEasyFundDetails
