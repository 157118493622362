import React, { useState, useEffect, useContext, createContext, useRef } from 'react'
import { Row, Col, Table, Container, OverlayTrigger,Tooltip} from 'react-bootstrap';
import styles from './funds.module.css'
import {
	Header,
	SideBar,
  WithDrawFund
} from '../../components'
import { addCommasinPrice } from '../../utils';
import CashManagementDatePicker from '../CashManagement/cashManagementDatePicker';
import {addDays} from "date-fns";
import savingDownload from '../../public/images/downloadSavings.svg';
import moment from 'moment';
import Icon from '../../public/images/ThreeDotIcon.svg';
import leftIcon from '../../public/images/LeftPageIcon.svg';
import rightIcon from '../../public/images/RightPageIcon.svg';
import dollarIcon from '../../public/images/dollarIcon.svg'
import plusImage from '../../public/images/whiteplus.png'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../Context/ThemeContext'
import coinHandImg from '../../public/images/coins-hand.svg'
import bankIcon from '../../public/images/bankIcon.svg'
import coinSwapImg from '../../public/images/coins-swap.svg'
import creditCardCheckImg from '../../public/images/credit-card-check.svg'
import {getFundsTransactionData,getAccountSummaryWithCommission,getFundsTransactionDetails,swiftMailFunds} from '../../api'
import { decimalAdjust } from '../../utils'
import { format } from 'date-fns'
import download from '../../public/images/downloadFund.svg';
import beneficiary from '../../public/images/beneficiaryFund.svg';
import manage from '../../public/images/manageBankFunds.svg';
import statementIcon from '../../public/images/statmentIcon.svg';
import CashMgmtModal from '../CashManagement/CashMgmtModal';
import TransactionDetailsModal from './transactioDetailFund';
import DownIcon from '../../public/images/iconDown.svg';
import filter from '../../public/images/savingFilter.svg';
import close from '../../public/images/savingsClose.svg';
import AccountDetails from '../../public/images/accountDetails.svg';
import { Spinner } from "react-bootstrap"
import OpenEmailPopUp from './emailPopup'
import CustomModal from "../../components/CustomModal/CustomModal"
import FundBenefeiciary from './beneficiary'
import { LEGAL_LINKS } from '../../constants';

const transactionValue = [
  {value:"All Transactions",
  color:"#767C8F",
  pillValue:"ALL"},
  {value:"Processing",
  color:"#FA5D05",
  pillValue:"WAITING_FOR_FUND,FUND_RECEIVED,Initiated,HOLD,PROCESSED,COMPLETED"},
  {value:"Successful",
  color:"#0DC014",
  pillValue:"CREDITED"},
  {value:"Failed",
  color:"#FA274A",
  pillValue:"PAYMENT_FAILURE,FAILED"},
  {value:"Rejected",
  color:"#FA274A",
  pillValue:"REJECTED"},
  {value:"Cancelled",
  color:"#FA274A",
  pillValue:"CANCELLED"},
  {value:"Refunded",
  color:"#767C8F",
  pillValue:"REFUNDED"},
  {value:"Expired",
  color:"#767C8F",
  pillValue:"EXPIRED"}
]

const NewFund = (props) => {
  const [slideeffect, setslideeffect] = useState(false)
  const date = new Date();
  const countPerPage = 10;
  const [startDate, setStartDate] = useState(addDays(date, -30))
  const [endDate, setEndDate] = useState(date)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPaginationPage, setTotalPaginationPage] = useState(1)
  const [listLoading, isListLoading] = useState(false)
  const [isTransactionListEmpty, setIsTransactionListEmpty] = useState(false)
  const [transactionListError, setTransactionListError] = useState(false)
  const [collection, setCollection] = useState();
  const [cashAvailable, setCashAvailable] = useState('$--')
  const [withdrawValue, setWithdrawValue] = useState('')
  const [unsettledAmt, setUnsettledAmt] = useState(0)
  const [pendingSettlememt, setPendingSettlement] = useState(null)
  const [menuVisible, setMenuVisible] = useState(false)
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const [withdraw, setWithdraw] = useState(false)
  const [showTransactionDetails, setShowTransactionDetails] = useState(false)
  const [tranId, setTranId ] = useState();
  const [showTransactionTypePopup, setShowTransactionTypePopup] = useState(false);
  const [showTransactionTypePopupMobile, setShowTransactionTypePopupMobile] = useState(false)
  const [selectedPill, setSelectedPill] = useState('ALL')
  const [selectedInputPill, setSelectedInputPill] = useState('All Transactions')
  const [totalTransactionCount, setTotalTransactionCount] = useState('')
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [swiftlink, setSwiftLink] = useState(null);
  const [pendingTotal, setPendingTotal] = useState(null);
  const [isShow, setIsShow] = useState(false)
  const [beneficicaryModalShow, setBeneficairyModalShow] = useState(false)
  const [profileData, setProfileData] = useState({})
  const [disableFund, setDisableFund] = useState(false);
  const [disableWithdraw, setDisableWithdraw] = useState(false);

  const onClose = ()=>{
    setBeneficairyModalShow(false)
  }

  const pillSelection = (e,f) => {
    setSelectedPill(e)
    setShowTransactionTypePopup(false)
    setSelectedInputPill(f)
    setCurrentPage(1)
  }

  const ref = useRef()
  const fraudPopupRef = useRef()
  const beneficiaryRef = useRef()
  useOnClickOutside(ref, () => setShowTransactionTypePopup(false))
  useOnClickOutside(fraudPopupRef,() => setShow(false))
  useOnClickOutside(beneficiaryRef, () => setMenuVisible(false))


  const adjustDecimal = e => {
		let commaPrice = decimalAdjust('floor', e, -2)
		return addCommasinPrice(commaPrice)
	}

  const asyncFunctionCall = async () => {
		// let response = await getFund()
		let response = await getAccountSummaryWithCommission()
		// setskeletonLoading(false)
		if (
			response &&
			(response.unsettledAmount || response.unsettledAmount !== '') &&
			response.accountSummary &&
			(response.accountSummary.goodFaithViolations ||
				response.accountSummary.goodFaithViolations !== '') &&
			(response.accountSummary.cashAvailableForWithdrawal ||
				response.accountSummary.cashAvailableForWithdrawal !== '') &&
			(response.accountSummary.cashBalance ||
				response.accountSummary.cashBalance !== '') &&
			(response.accountSummary.cashAvailableForTrade ||
				response.accountSummary.cashAvailableForTrade !== '') &&
			(response.commission || response.commission !== '')
		) {
			setData([
				{
					icon: coinHandImg,
					title: 'Unsettled',
					description: `Funds that will be settled in T+1 days.`,
					value: `$${adjustDecimal(response.unsettledAmount)}`,
					extra: ''
					// pendingCommission: `$${addCommasinPrice(response.commission)}`
				},
				{
					icon: bankIcon,
					title: 'On Hold',
					description: `Funds kept on hold for pending buy orders.`,
					// value: `$${addCommasinPrice(response.unsettledAmount)}`,
					value: `$${addCommasinPrice(response.commission)}`,
					extra: ''
				},
				{
					icon: coinSwapImg,
					title: 'Suspense',
					description: `Funds currently awaiting clarification.`,
					value: `$--`,
					extra: ''
				},
				{
					icon: creditCardCheckImg,
					title: 'Withdrawable',
					description: `Funds that are available to withdraw.`,
					value: `$${addCommasinPrice(
						response.accountSummary.cashAvailableForWithdrawal
					)}`,
					extra: 'Withdraw'
				}
			])

			setWithdrawValue(
				`$${addCommasinPrice(
					response.accountSummary.cashAvailableForWithdrawal
				)}`
			)
			setUnsettledAmt(adjustDecimal(response.unsettledAmount))

			setCashAvailable(
				`$${addCommasinPrice(response.accountSummary.cashAvailableForTrade)}`
			)

			// let goodFaithObj = null
			// let value = response.accountSummary.goodFaithViolations
			// value = value.trim().substring(0, 1)
			// goodFaithObj = goodFaithTitle(value)
			// goodFaithObj && props.setGoodFaithViolation(goodFaithObj)
		}

    const goodFaithTitle = value => {
      switch (value) {
        case '0':
          return {
            value:0,
            title: 'You are a good trader, Keep Going!',
            description:
              'Buying and selling shares more frequently may increase the chances of violation.',
            href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
            extra: ` Learn More`,
            bg: `#ECFAEC`
          }
        case '1':
          return {
            value:1,
            title: 'Take easy, No need to hurry!',
            description:
              'Buying and selling shares more frequently may increase the chances of violation.',
            href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
            extra: ` Learn More`,
            bg: `#EBF2FC`
          }
        case '2':
          return {
            value:2,
            title: 'Going Too Fast, You Need to Relax',
            description:
              'Buying and selling shares more frequently may increase the chances of violation.',
            href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
            extra: ` Learn More`,
            bg: `#FFF2EB`
          }
        case '3':
          return {
            value:3,
            title: 'You have Violated Multiple Times',
            description:
              'Buying and selling shares more frequently may increase the chances of violation.',
            href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
            extra: ` Learn More`,
            bg: `#FFF2EB`
          }
        default:
          return {
            value:4,
            title: 'You have Violated Multiple Times',
            description:
              'Buying and selling shares more frequently may increase the chances of violation.',
            href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
            extra: ` Learn More`
          }
      }
    }

		let pendingSettlementArr = []
		response &&
			response.accountSummary &&
			response.accountSummary.cashSettlement &&
			response.accountSummary.cashSettlement.map((item, idx) => {
				let obj = {
					cash: `$${addCommasinPrice(item.cash)}`,
					time: format(new Date(item.utcTime), 'MMM dd,yyyy')
				}
				pendingSettlementArr.push(obj)
			})
		pendingSettlementArr.length > 0 &&
			setPendingSettlement(pendingSettlementArr)
	}

	const { buttonColor } = useContext(ThemeContext)

  useEffect(() => {
    let sessionProfileData = ''
			sessionProfileData = sessionStorage.getItem('userInfo')
				? JSON.parse(sessionStorage.getItem('userInfo'))
				: ''
    setDisableFund(sessionProfileData && sessionProfileData.userRestriction ? sessionProfileData.userRestriction.fund : false)
    setDisableWithdraw(sessionProfileData && sessionProfileData.userRestriction ? sessionProfileData.userRestriction.withdrawal : false)
    setProfileData(sessionProfileData)
    getFundTransactionDetail()
    asyncFunctionCall()
  },[])

  const dateFormatter = (mydate) => {
    var curr_date = mydate.getDate();
    var curr_month = mydate.getMonth() + 1;
    var curr_year = mydate.getFullYear();
    if (curr_date < 10) {
      curr_date = "0" + curr_date;
    }
    if (curr_month < 10) {
      curr_month = "0" + curr_month;
    }
    mydate = `${curr_month}/${curr_date}/${curr_year}`;
    return mydate;
  }

  let customer = ''
  customer = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : ''

  const getFundTransactionDetail = () => {
    isListLoading(true)
    var start_date = dateFormatter(startDate)
    var end_date = dateFormatter(endDate)
    getFundsTransactionData(customer.customerId, selectedPill,start_date,end_date,currentPage,false).then(response=>{
      if(response.status === 200){
          setCollection(response.doc.transactions.length > 0 ? response.doc.transactions : []);
          setTotalPaginationPage(Math.ceil(response.doc.total/countPerPage))
          isListLoading(false)
          setTotalTransactionCount(response.doc.total)
          if(response.doc.transactions.length === 0) {
            setIsTransactionListEmpty(true)
          } else {
            setIsTransactionListEmpty(false)
          }
        } else {
          isListLoading(false)
          setTransactionListError(true)
        }
      }).catch((err)=>{
        console.error(err)
        setTransactionListError(true)
        isListLoading(false)
    })
  }

  useEffect(() => {
    const interval = setInterval(() => getFundTransactionDetail(), 10000)
    return () => {
      clearInterval(interval);
    }
  },[selectedPill,currentPage,startDate,endDate])

  const goBack = (p) => {
    if(p !== 1){
    const x = p-1;
    setCurrentPage(x);
    const to = countPerPage * x;
    const from = to - countPerPage;
    // setCollection(transactionDataTable.slice(from, to));
    }else{
      console.log('cannot go back');
    }
  }

  const goNext = (p) => {
    const x = p+1;
    if(totalPaginationPage >= p+1){
      setCurrentPage(x);
      const to = countPerPage * x;
      const from = to - countPerPage;
      // setCollection(transactionDataTable.slice(from, to));
    } else{
      console.log('cannot go forward');
    }
  }

  useEffect(() => {
    getFundTransactionDetail()
  },[currentPage,startDate,endDate,selectedPill])

  const handleEffect = () => {
		setslideeffect(prevState => setslideeffect(!prevState))
	}

  const changeDate = (data, id) => {
    setCurrentPage(1)
    if (id === "Start_Date") {
        setStartDate(data)
    } else {
      setEndDate(data)
    }
  }

  const seprateTimeAndDate = (e,type) => {
    if(type === 'date'){
      const date = moment(e).format('D MMM YY');
      return date ? date : "--"
    }
    if(type === 'time'){
      const time = moment(e).format('h:mm A z');
      return time ? time : "--"
    }
  }

  const [data, setData] = useState([
		{
			icon: coinHandImg,
			title: 'Unsettled',
			description: `Funds that will be settled in T+2 days.`,
			value: '$--',
			extra: ''
		},
		{
			icon: bankIcon,
			title: 'On Hold',
			description: `Funds kept on hold for pending buy orders.`,
			value: '$--',
			extra: ''
		},
		{
			icon: coinSwapImg,
			title: 'Suspense',
			description: `Funds currently awaiting clarification.`,
			value: '$--',
			extra: ''
		},
		{
			icon: creditCardCheckImg,
			title: 'Withdrawable',
			description: `Funds currently awaiting clarification.`,
			value: '$--',
			extra: 'Withdraw'
		}
	])

  const handleMenu = () => {
		setMenuVisible((prev)=>(!prev))
	}
  const mobileDropDown= () =>{
    setMobileMenuVisible((prev)=>(!prev))
  }

  const withdrawFundBalance = () => {
		props.setWithdraw(true)
	}

  const openTransactionDetails = () => {
    setShowTransactionDetails(!showTransactionDetails)
  }

  const openTransactionTypePopup = () => {
    setShowTransactionTypePopup(!showTransactionTypePopup)
  }

  const openTransactionTypePopupMobile = () => {
    setShowTransactionTypePopupMobile(!showTransactionTypePopupMobile)
  }

  const downLaodingCsv = () =>{
    var start_date = dateFormatter(startDate)
    var end_date = dateFormatter(endDate)
      getFundsTransactionData(customer.customerId, selectedPill,start_date,end_date,currentPage,true).then(response=>{
        if(response.status === 200){
          let x =  response.doc
            if(x !== undefined && x.length > 0){
            downloadExcel(x)
            }
          }
        }).catch((err)=>{
          console.error(err)
      })
  }

  const downloadExcel = (data, fileName =  `Stockal EasyFund Statement_${dateFormatter(startDate)}_${dateFormatter(endDate)}.csv`) => {
    var jsonObject = JSON.stringify(data);
    const topHeader = "Stockal EasyFund Account - Statement of Accounts \r\n \r\n"
    // const info = `${bankDetails.userName},,,Account Number:  ${`XXXXXXXXXX${lastFourDigit(bankDetails.stoCashAccount.accountNum)}`} \r\n`
    // const info2 = `${userInfo.addressLine1} ${userInfo.addressLine2},,,Routing Number: ${bankDetails.stoCashAccount.routingNum} \r\nCity: ${userInfo.city},,,Routing Number: ${bankDetails.stoCashAccount.accountType}\r\n`
    // const info3 = `State: ${userInfo.stateProvince},,,Bank Name: ${bankDetails.stoCashAccount.bankName} \r\nCountry: ${userInfo.country},,,Bank Address: ${bankDetails.stoCashAccount.bankAddress.addressLine1}-${bankDetails.stoCashAccount.bankAddress.addressLine2} - ${bankDetails.stoCashAccount.bankAddress.addressCity} ${bankDetails.stoCashAccount.bankAddress.addressZipPostalCd} - ${bankDetails.stoCashAccount.bankAddress.addressState} - ${bankDetails.stoCashAccount.bankAddress.addressCountryCd}\r\n`
    // const info4 = `Postal Code: ${userInfo.zipPostalCode},,,Currency: ${bankDetails.stoCashAccount.accountCurrencyCd}\r\n`
    // const info5 = `Email: ${userInfo.emailAddress1},,,A/C Open Date: ${moment(bankDetails.stoCashAccount.accountOpenDt).format("DD/MM/YYYY h:mm:ss")} IST,,,\r\nMobile No: ${userInfo.phoneHome},,,Account Status: ${bankDetails.stoCashAccount.accountStatus}\r\n \r\n \r\n`
    // const statementFrom = `Statement From: ${dateFormatter(startDate)} To: ${dateFormatter(endDate)}\r\n \r\n \r\n`
    const preHeader = "*************************************************************************************************** \r\n";
    const headers = "Date and Time,Trasaction ID,Transfer Method,Transaction Status,Transaction Sub Status,Transaction Amount,Transaction Charges,Debited from,Credited to\r\n";
    // const disclaimer = "\r\n Disclaimer & Disclosures: \r\n "
    // const disclaimerDescription = "1. This Cash Management Transaction Statement Report (\"Report\") has been compiled based on information provided by Evolve Bank &Trust (\"Evolve Bank\") and made to Borderless Investing Inc. and its subsidiaries (owner of the Stockal platform and collectively referred to as the \"Stockal Group\") which has not been subject to an audit or review engagement at the time of report generation. \r\n 2. Figures stated herein are susceptible and subject to revision due to unexpected changes including without limitation bank charges currency fluctuations general charges and in accounting interpretation. \r\n 3. Accordingly neither Stockal Group its affiliates nor any of its employees make any warranty express or implied nor accept any responsibility for the reliability accuracy or completeness of the compiled financial information nor does it accept liability of any kind whatsoever including liability by reason of negligence to any person for losses incurred as a result of placing reliance on the compiled financial information. \r\n \r\n 4. ERRORS OR DISCREPANCIES \r\n a. If you find an error or discrepancy relating to your Report you must notify us immediately and no later than 10 days after this statement is sent or made available to you. \r\n b. If this statement shows that we have mailed the Report and that you have not received it kindly notify customer support immediately. \r\n c. If we do not receive your response in this stipulated time; we will assume that you have accepted the Report and account balance as being correct (as set out in this notice) with respect to your Cash Management Account. \r\n Please retain this statement for your tax records.";

    try {
      var csv = topHeader +
      // + info + info2 + info3 + info4 + info5 + statementFrom +
      preHeader + headers + preHeader + convertToCSV(jsonObject) + preHeader ;
      // disclaimer + disclaimerDescription;
      var exportedFilenmae = fileName;

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const convertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      let object_ = {
        'Date and Time': array[i].createdAt ? `${moment(array[i].createdAt).format("DD/MM/YYYY h:mm:ss A")} IST` : '',
        'Trasaction ID':array[i].transactionId ? array[i].transactionId : "",
        'Transfer Method':"Easyfund",
        'Transaction Status': array[i].paymentStatus ? array[i].paymentStatus === 'FUND_RECEIVED' ||  array[i].paymentStatus === 'Initiated' ||  array[i].paymentStatus === 'PROCESSED' ||  array[i].paymentStatus === 'WAITING_FOR_FUND' || array[i].paymentStatus === "HOLD" || array[i].paymentStatus === "COMPLETED" ? 'Processing': array[i].paymentStatus === 'CREDITED' ? 'Successful': array[i].paymentStatus === 'PAYMENT_FAILURE' || array[i].paymentStatus === 'FAILED' ? 'Failed': array[i].paymentStatus === 'REFUNDED' ? 'Refunded' : array[i].paymentStatus === 'REJECTED' ? 'Rejected' : array[i].paymentStatus === 'EXPIRED' ? 'Expired' : "" : '--',
        'Transaction Sub Status' : array[i].paymentStatus ? array[i].paymentStatus === 'FUND_RECEIVED' ? "Statement Required" :  array[i].paymentStatus === 'PROCESSED' ? "Transfer Approved" : array[i].paymentStatus === 'WAITING_FOR_FUND' ? "Payment Awaited" : array[i].paymentStatus === "HOLD" ? "Document Required" : "" : "",
        'Transaction Amount': array[i].transactionAmount ? `+ $${adjustDecimal(array[i].transactionAmount)}` : '',
        'Transaction Charges':  array[i].stoFundTransferQuote &&  array[i].stoFundTransferQuote.totalAdditionalAmount ?  `Rs.${adjustDecimal(array[i].stoFundTransferQuote.totalAdditionalAmount)}` : "",
        'Debited from': array[i].stoUserBank && array[i].stoUserBank.beneficiaryAccountNumber ?  array[i].stoUserBank.beneficiaryAccountNumber : "--",
        'Credited to': "Stockal",
      };
      Object.keys(object_).forEach(key => {
        if (line != '') line += ','
        line += `"${object_[key]}"`;
      })
      str += line + '\r\n';
    }
    return str;
  }

  const FraudPopup = (props) => {
    return(
      <div className={styles.fraudPopupContainer}
        id={`#fraud${props.id}`} style={props.show && props.target === `#fraud${props.id}` ? {display:"block"} : {display:"none"}}>
        {/* <a href={swiftlink}> */}
          <span className={styles.text}
          onClick={ swiftlink == null || swiftlink == "" ? () => setShow(false) : () => DownloadPdfSendMail(props.transactionId)}
          style={{borderBottom:"1px solid #D3D4DA", color: swiftlink === null || swiftlink === "" ? "grey": "#3C404A", cursor: swiftlink === null || swiftlink === "" ? "auto": "pointer"}}>
          <img className={styles.flagImage} src={AccountDetails}></img>Download SWIFT</span>
        {/* </a> */}
      </div>
    )
  }

  const DownloadPdfSendMail = async (e) => {
    const response = await swiftMailFunds(e)
    try{
      if(response.status === 200){
        setShow(false)
        setIsShow(true);
        setSwiftLink('');
      }
    } catch (err) {
        console.error(err)
    }
  }

  const handleClick = (event,transactionId) => {
    setShow(!show);
    setTarget(`#fraud${event}`);
  };

  useEffect(()=> {
    if(show){
      findTransactionDetails()
    }
  },[show])

  const findTransactionDetails = () => {
    getFundsTransactionDetails(tranId).then(response => {
        if(response.status === 200){
          setSwiftLink(response.doc ? response.doc.swiftUrl : '')
        }
    }).catch((err)=>{
        console.error(err)
    })
  }

  useEffect(() => {
    getFundTransactionDetailForUploadDocs();
  },[collection])

  const getFundTransactionDetailForUploadDocs = () => {
    isListLoading(true)
    var start_date = dateFormatter(addDays(date, -30))
    var end_date = dateFormatter(date)
    const type = "FUND_RECEIVED,HOLD"
    var page = 1
      getFundsTransactionData(customer.customerId,type,start_date,end_date,page,false).then(response=>{
        if(response.status === 200){
          setPendingTotal(response.doc.total);
          }
        }).catch((err)=>{
          console.log('err',err);
      })
    }

  const seeAllUploadTransaction = () => {
    setSelectedPill("FUND_RECEIVED,HOLD")
    setSelectedInputPill("Processing")
    setStartDate(addDays(date, -30))
    setEndDate(date)
    setCurrentPage(1)
  }

  return(
    <>
      {/* <Header />
      <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div> */}
        <Row className='mb-5'>
          <Col lg={8} className={styles.savingsLeftCardPad}>
            <Row className={styles.savingsTitle}>
              <Col lg={6}>Available to Invest</Col>
              {pendingTotal !== null && pendingTotal !== 0 ?
              <Col lg={6} style={{float:"right",cursor:"pointer",zIndex:"1"}} className={styles.uploadDocs} onClick={seeAllUploadTransaction}>
                <Row>
                  <Col lg={1} md={2}><img src={statementIcon} style={{height:"20px",marginTop:"5px"}}/></Col>
                  <Col lg={10} md={10}>{pendingTotal} transaction required to upload documents. <span className={styles.uploadSeeAll}>See All</span></Col>
                </Row>
              </Col> : ""}
            </Row>
            <Row>
              <Col>
              <span className={styles.savingsAccountBalance}>{cashAvailable}</span>
              </Col>
              <Col className={styles.responsiveThreeButton}>
                <div style={{position:"relative",textAlign:"right"}}>
                  <button
                    type='button'
                    onClick={mobileDropDown}
                    className={`btn btn-sm ${styles.menuContainer} themeButton`}>
                    <div className={styles.menuIcon}></div>
                  </button>
                  <div className={`${styles.optionContainerMobile} ${mobileMenuVisible ? styles.visible : ''}`}>
                    <div className={styles.options} onClick={() => setBeneficairyModalShow(true,setMobileMenuVisible(false))}>
                      <img src={beneficiary} alt='icon' style={{marginRight:"10px"}} />
                      Beneficiary Details
                    </div>
                    <div className={styles.options}
                    onClick={() => props.handleShowMobile({investment: true, manageBank:true},setMobileMenuVisible(false))}>
                      <img src={manage} alt='icon' style={{marginRight:"10px"}} />
                      Manage Banks
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          {/* responsive right part on upside and banner */}

          {pendingTotal !== null && pendingTotal !== 0 ?
            <Col lg={6} style={{cursor:"pointer",zIndex:"1"}} className={styles.uploadDocsMobile} onClick={seeAllUploadTransaction}>
              <Row>
                <Col lg={1} md={2} sm={2} xs={1}><img src={statementIcon} className={styles.uploadDocsImage} style={{height:"20px",marginTop:"5px"}}/></Col>
                <Col lg={10} md={10} sm={10} xs={10}>{pendingTotal} transaction required to upload documents. <span className={styles.uploadSeeAll}>See All</span></Col>
              </Row>
            </Col> : ""}
          <Row className={styles.mobileRightSideDetails}>
            <Col>
            <InvestMentRightSideData buttonColor={buttonColor}
            profileData={profileData}
            handleMenu={handleMenu}
            menuVisible={menuVisible}
            withdrawFundBalance={withdrawFundBalance}
            handleShowMobile={props.handleShowMobile}
            data={data}/>
          </Col>
          </Row>

          {/* trasnsaction history date and download button */}
          <Row className={styles.savingsPillsMargin}>
              <Col lg={12} className={styles.savingsTitleTransactions}>Remittance History</Col>
              <Col xs={12} md={12} className={styles.savingsHideMobileFilter} onClick={openTransactionTypePopupMobile} >
                <div className={styles.savingsMobileFilter}>
                <span className={styles.savingsMobileFilterText}><img src={filter} style={{marginRight:"10px"}}></img>{selectedInputPill}</span>
                </div>
                {showTransactionTypePopupMobile ? <TransactionTypePopUp data={transactionValue} selectPill={pillSelection} openTransaction={openTransactionTypePopupMobile}/> : ""}
              </Col>
              {/* for date */}
                <Col lg={3} sm={5} md={5} xs={5}>
                  <CashManagementDatePicker
                    type={'From'}
                    changeDate={changeDate}
                    value={startDate}
                    id="Start_Date"
                    extraPad="0px"
                    maxDate={endDate}
                  />
                </Col>
                <Col lg={3} sm={5} md={5} xs={5} className={styles.savingDatePickerWebPadding}>
                  <CashManagementDatePicker
                    type={'To'}
                    changeDate={changeDate}
                    value={endDate}
                    minDate={startDate}
                    id="End_Date"
                    extraPad="15px"
                  />
              </Col>
              <Col lg={2} xs={2} className={styles.savingsDownloadButtonAlighnment}>
                <img src={savingDownload}
                onClick={downLaodingCsv}
                className={styles.savingsDownloadButton}></img>
              </Col>
              <Col lg={4} xs={12} style={{width:"100%"}} ref={ref} onClick={openTransactionTypePopup} className={styles.savingsWebFilter}>
                <span className={styles.savingsPillsDropdownSelection} style={{color: selectedInputPill === "Processing" ? "#FA5D05" : selectedInputPill === "Successful" ? "#0DC014" :  selectedInputPill === "Failed" ? "#FA274A" : selectedInputPill === "Rejected" ? "#FA274A" : selectedInputPill === "Cancelled" ? "#FA274A" : ""}}>
                <span className={styles.savingPillCircleColor} style={{backgroundColor: selectedInputPill === "Processing" ? "#FA5D05" : selectedInputPill === "Successful" ? "#0DC014" : selectedInputPill === "Rejected" || selectedInputPill === "Cancelled" || selectedInputPill === "Failed" ? "#FA274A" : ""}}></span>{selectedInputPill}
                <img className={styles.savingPillsDropDownIcon} src={DownIcon}></img></span>
                {showTransactionTypePopup ? <TransactionTypePopUp data={transactionValue} selectPill={pillSelection} openTransaction={openTransactionTypePopup}/> : ""}
              </Col>
            </Row>
            <Row className={styles.savingsTableHeader}>
              <Col lg={3} md={4} sm={4} className={styles.savingsTableHeading}>
                <span>Date & Time</span>
              </Col>
              <Col lg={3} md={6} sm={6} xs={6} className={`${styles.savingsTableHeading} ${styles.savingsTableHeadingAmount}`}>
                <span>Amount</span>
              </Col>
              <Col lg={4} md={6} sm={6} xs={6} className={`${styles.savingsTableHeading} ${styles.savingtableHideMobile}`}>
                <span>Status</span>
              </Col>
              <Col lg={2}  className={`${styles.savingsTableHeading} ${styles.savingtableHideMobile}`}>
              </Col>
            </Row>
            <Row className={styles.mobileSavingsTableHeader}>
              <Col sm={6} xs={6} className={styles.savingsTableHeading}>
                <span>Amount & Date</span>
              </Col>
              <Col sm={6} xs={6} className={`${styles.savingsTableHeading} ${styles.savingsTableHeadingAmount} ${styles.mobileRightAlignment}`}>
                <span>Status</span>
              </Col>
            </Row>
          {collection && collection.length > 0 ? collection.map((item, index) => {
            return(
              <Row className={styles.savingsTablePoints}>
                <Col lg={3} className={`${styles.savingTableType} ${styles.savingtableHideMobile}`} onClick={() => {
                setTranId(item.transactionId)
                openTransactionDetails()
                }}>
                  <span className={styles.savingTableTransactionTypeValue}>
                    {seprateTimeAndDate(item.createdAt,'date')}
                  </span>
                  <span className={styles.savingTableId}>
                    {seprateTimeAndDate(item.createdAt,'time')} IST
                  </span>
                </Col>
                <Col lg={3} xs={6} className={styles.savingTableType} onClick={() => {
                setTranId(item.transactionId)
                openTransactionDetails()
                }}>
                  <span className={styles.savingTableTransactionTypeValue}>+ ${addCommasinPrice(parseFloat(item.transactionAmount))}.00</span>
                  <span className={`${styles.savingTableId} ${styles.savingtableHideMobile}`}>
                  </span>
                  <span className={`${styles.savingTableId} ${styles.savingtableHideMobile}`}>
                    Deposit
                  </span>
                  {/* mobile date*/}
                  <span className={`${styles.savingTableId} ${styles.savingShowMobile}`}>
                    {seprateTimeAndDate(item.createdAt,'date')},{seprateTimeAndDate(item.createdAt,'time')} IST
                  </span>
                </Col>
                <Col lg={4} xs={6} className={`${styles.savingTableType} ${styles.savingsTableHeadingAmount} ${styles.mobileRightAlignment}`}
                onClick={() => {
                setTranId(item.transactionId)
                openTransactionDetails()
                }}>
                  <span className={`${styles.fundStatusHead}`}>
                    {item.paymentStatus === "FUND_RECEIVED" ? <div><img src={statementIcon} /><span style={{color:"#FA5B05", marginLeft:"10px"}}>Processing</span></div>:
                    item.paymentStatus === "COMPLETED" ? <div><img src={statementIcon}/><span style={{color:"#FA5B05",marginLeft:"10px"}}>Processing</span></div>  :
                    item.paymentStatus === "REJECTED" ?  <div><span className={styles.savingPillCircleColor} style={{backgroundColor:"#FA274A"}}></span><span style={{color:"#FA274A"}}>Rejected</span></div>  :
                    item.paymentStatus === "Initiated" || item.paymentStatus === "HOLD" ? <div><img src={statementIcon} /><span style={{color:"#FA5B05", marginLeft:"10px"}}>Processing</span></div> :
                    item.paymentStatus === "REFUNDED"  ?  <div><span className={styles.savingPillCircleColor} style={{backgroundColor:"#767C8F"}}></span><span style={{color:"#767C8F"}}>Refunded</span></div> :
                    item.paymentStatus === "EXPIRED"  ?  <div><span className={styles.savingPillCircleColor} style={{backgroundColor:"#767C8F"}}></span><span style={{color:"#767C8F"}}>Expired</span></div> :
                    item.paymentStatus === "PROCESSED"  ?  <div><img src={statementIcon}/><span style={{color:"#FA5B05",marginLeft:"10px"}}>Processing</span></div> :
                    item.paymentStatus === "PAYMENT_FAILURE"  ?  <div><span className={styles.savingPillCircleColor}></span><span style={{color:"#FA274A"}}>Failed</span></div> :
                    item.paymentStatus === "FAILED"  ?  <div><span className={styles.savingPillCircleColor} style={{backgroundColor:"#FA274A"}}></span><span style={{color:"#FA274A"}}>Failed</span></div> :
                    item.paymentStatus === "CANCELLED"  ?  <div><span className={styles.savingPillCircleColor} style={{backgroundColor:"#FA274A"}}></span><span style={{color:"#FA274A"}}>Cancelled</span></div> :
                    item.paymentStatus === "WAITING_FOR_FUND" ? <div><img src={statementIcon}/><span style={{color:"#FA5B05", marginLeft:"10px"}}>Processing</span></div> :
                    item.paymentStatus === "CREDITED"  ?  <div><span className={styles.savingPillCircleColor} style={{backgroundColor:"#0DC014"}}></span><span style={{color:"#0DC014"}}>Successful</span></div> :
                    item.paymentStatus === null ? "--" : item.paymentStatus}
                  </span>
                  <span className={`${styles.savingTableId}`}>
                    {item.paymentStatus === "FUND_RECEIVED" && item.transactionSource !== "AXIS" ? <span style={{color:"#0061D3"}}>Statement Required</span>:
                    item.paymentStatus === "COMPLETED"  && item.transactionSource !== "AXIS"  ? <span>Download SWIFT</span>  :
                    // item.paymentStatus === "Rejected" ?  <span>Rejected</span> :
                    // item.paymentStatus === "Initiated" ? <span>Verfifying Document</span> :
                    // item.paymentStatus === "Refund" ?  <span>Refund</span> :
                    item.paymentStatus === "PROCESSED" && item.transactionSource !== "AXIS"  ? <span>Transfer Approved</span>:
                    item.paymentStatus === "WAITING_FOR_FUND" && item.transactionSource !== "AXIS"  ? <span style={{color:"#0061D3"}}>Payment Awaited</span> :
                    item.paymentStatus === "HOLD" && item.transactionSource !== "AXIS"  ? <span style={{color:"#0061D3"}}>Document Required</span> :
                    item.paymentStatus === null ? "" : ""}
                  </span>
                </Col>
                <Col lg={1} className={`${styles.savingTableType} ${styles.savingtableHideMobile}`} style={{cursor:"pointer"}}
                onClick={() => {
                handleClick(index,item.transactionId)
                setTranId(item.transactionId)}}>
                <img className={styles.savingThreeVerticalDots} value={`#fraud${item.uniqueId}`} src={Icon}></img>
                <FraudPopup id={index}
                fraudRef={fraudPopupRef}
                transactionId={item.transactionId}
                target={target}
                show={show}
                />
                </Col>
              </Row>
            )
          }) : listLoading ?
          <div style={{textAlign:"center",height:"150px", paddingTop:"60px"}}>
            <Spinner animation="border" className={styles.spinnerColor} style={{color:"#0061d3"}}/>
          </div>
          : <span className={styles.noDataFound}>No Data Found</span>}
          <OpenEmailPopUp isShow={isShow} setIsShow={setIsShow}/>
          <CustomModal show={beneficicaryModalShow} handleClose={onClose} className={`${styles.addFundModal}`}>
            <FundBenefeiciary/>
          </CustomModal>
          {/* pagination */}
          <Row className={styles.savingsPagination}>
            <Col lg={3} xs={4}
            className={styles.savingPaginationRowPad}
            onClick={() => goBack(currentPage)}
            >
            {totalTransactionCount <= 10 ? "" :
            <>
            <img src={leftIcon} style={{marginRight:"8px"}}></img>
            <span
            className={styles.savingPaginationButton} style={{cursor: currentPage !== 1 ? "pointer" : "default"}}>
            Previous
            </span>
            </>}
            </Col>
            <Col lg={6} xs={4} className={styles.savingPaginationRowCount} style={{textAlign:"center"}}>
              {totalPaginationPage === 0 ||totalPaginationPage === undefined ? "" :
              `Page ${currentPage} of ${totalPaginationPage}`}
            </Col>
            <Col lg={3} xs={4}
            className={styles.savingPaginationRowPad}
            style={{textAlign:"right"}}
            onClick={() => goNext(currentPage)}
            >
            {totalTransactionCount <= 10 ? "" :
            <>
            <span
            className={styles.savingPaginationButton} style={{cursor: currentPage !== totalPaginationPage  ? "pointer" : "default"}}>
            Next</span>
            <img src={rightIcon} style={{marginLeft:"8px"}}></img>
            </>}
            </Col>
          </Row>
          {showTransactionDetails ?
            <CashMgmtModal showModal={showTransactionDetails} openModal={openTransactionDetails}>
              <TransactionDetailsModal onHide={openTransactionDetails} stoTranId={tranId} handleShowMobile={props.handleShowMobile} DownloadPdfSendMail={DownloadPdfSendMail}/>
            </CashMgmtModal>
          : ''}

          </Col>
          {/* right part of page */}
          <Col lg={4} className={`${styles.savingsRightCardPad} ${styles.WebVirtualCardShowOnly}`}>
          <InvestMentRightSideData buttonColor={buttonColor}
          handleMenu={handleMenu}
          menuVisible={menuVisible}
          data={data}
          withdrawFundBalance={withdrawFundBalance}
          handleShowMobile={props.handleShowMobile}
          goodFaithViolations={props.goodFaithViolations}
          beneficiaryRef={beneficiaryRef}
          setBeneficairyModalShow={setBeneficairyModalShow}
          profileData={profileData}
          disableFund={disableFund}
          disableWithdraw={disableWithdraw}/>
          </Col>
        </Row>
      {/* </Container> */}
    </>
  )
}

const TransactionTypePopUp = (props) => {
  console.log('props',props);
  return(
    <div className={styles.transactionTypeContainer}>
      <div className={styles.savingsMobileCross} onClick={props.openTransaction}><img src={close} alt="close-icon"></img></div>
      {props.data.map((e,index) => {
          return( <span onClick={() => props.selectPill(e.pillValue,e.value)} className={styles.transactionTypeValue} style={{color:e.color}}><span className={styles.savingPillCircleColor}  style={{backgroundColor:e.color}}></span>{e.value}</span>)
        })
      }
    </div>
  )
}

const InvestMentRightSideData = (props) => {
  return(
    <>
    <div className={styles.rightPart}>
      <button
        style={{ backgroundColor: props.buttonColor }}
        type='button'
        disabled={
          props.profileData && props.profileData['accountStatus'] === 2 && !props.disableFund
            ? false
            : true
        }
        onClick={() => {
          props.handleShowMobile({investment: true, manageBank:false,selectFund:true})
        }}
        className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
        <img src={plusImage} alt="icon" />
        <span className={styles.addfundstext}>Add Funds</span>
      </button>
      <button
      type='button'
      onClick={props.withdrawFundBalance}
      disabled={
      props.profileData && props.profileData['accountStatus'] === 2 && !props.disableWithdraw
        ? false
        : true
      }
      className={`btn btn-sm ${styles.remittanceHistory} themeButton`}>
      <span className={styles.remittanceHistoryText}>
        Withdraw
      </span>
      </button>
      <div style={{position:"relative"}} className={styles.webThreeButtons}>
      <button
        type='button'
        onClick={props.handleMenu}
        className={`btn btn-sm ${styles.menuContainer} themeButton`}>
        <div className={styles.menuIcon}></div>
      </button>
      <div className={`${styles.optionContainer} ${props.menuVisible? styles.visible : ''}`}
      ref={props.beneficiaryRef}
      >
        {/* <Link to=''> */}
          <div className={styles.options} onClick={props.profileData && props.profileData['accountStatus'] === 2 ?
            () => props.setBeneficairyModalShow(true):
            () => console.log('no account Status')}>
            <img src={beneficiary} alt='icon' style={{marginRight:"10px"}} />
            Beneficiary Details
          </div>
        {/* </Link> */}
        <div className={styles.options} style={{cursor:"pointer"}}
        onClick={props.profileData && props.profileData['accountStatus'] === 2 ?
        () => props.handleShowMobile({investment: true, manageBank:true},props.handleMenu()) :
        () => console.log('no account Status')}>
          <img src={manage} alt='icon' style={{marginRight:"10px"}} />
          Manage Banks
        </div>
      </div>
    </div>
  </div>
  <div style={{marginTop:"4px"}}>
        {props.data.map((item, idx) => {
          return idx !== 2 ? (
            <>
              <div key={idx} className={styles.fundDetailSection} style={{borderBottom:"1px solid #efeff1"}}>
                <div className={styles.fundDetail}>
                  <div className={styles.fundInfo}>
                    <div className={styles.fundLabel}>{item.title}</div>
                    <div className={styles.amount}>{item.value}</div>
                    <div className={styles.fundDescription}>
                      {item.description}
                    </div>
                  </div>
                </div>
                <div className={styles.withdrawable}>
                  <div className={styles.fundImg}>
                    <img src={item.icon} alt="icon"></img>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )
        })}
      </div>
      {props.goodFaithViolations && (
        <>
      <div className={styles.banner}>
          <div className={styles.infoWrapper}>
            <div className={styles.bannerHeader}>
              {/* You are a good trader, Keep Going! */}
              {props.goodFaithViolations.title}
            </div>
            {/* <div className={styles.bannerDescription}>
              Buying and selling shares more frequently may increase the chances of violation.
            </div> */}
          </div>
          <a href={LEGAL_LINKS.GOOD_FAITH_VIOLATION} target='_blank'>
            <div className={styles.learnMore}>
              Learn More
            </div>
          </a>
        </div></>)}
    </>
  )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler]
  )
}

export default NewFund
