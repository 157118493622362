import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import {getAccountInfo} from '../../api'
import {setCurrentPlan} from '../../common'
import styles from './profile.module.css'
import { Footer } from '../../components'
import { Profile, Header, SideBar, BottomNav } from '../../components'
import {FundSkeleton} from '../../skeletons'


const ProfilePage = (props) => {
  const [slideeffect, setslideeffect] = useState(false)
  const [loading,setLoading] = useState(true)

  const handleEffect = () => {
    setslideeffect(prevState => setslideeffect(!prevState))
  }

  const getProfile = async() => {
    const userInfores = await getAccountInfo();
    const planInfo = await setCurrentPlan();
    if (userInfores && userInfores.statusDetails){
      sessionStorage.setItem('userInfo', JSON.stringify(userInfores));
      setLoading(false)
    }
  }


  useEffect(() => {
  	let sessionProfileData=''
		sessionProfileData = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : "";
		if(sessionProfileData  === ''){
			getProfile()
		}else{
      setLoading(false)
    }
  },[])


  return (
    <>
      <Header />
      <Container className={slideeffect ? styles.shiftPos : styles.originalPos}>
        {loading ? <FundSkeleton/> :
        <>
        <Profile isUserSet={() => props.isUserSet()} />
        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div>
        </> }
        {/* <div style={{ marginTop: '100px' }}>
                  <Footer />
                </div> */}
      </Container>
      <BottomNav />
    </>
  )
}

export default ProfilePage
