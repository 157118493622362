import React, { useContext, useEffect, useState } from "react"
import style from "./online.module.css"
import { putBrokerDetail } from "../../../../api"
import { AddFundContext } from "../../AddFundContext"
import Transaction from "../Common/Transaction"
import Success from "../Common/Success"
import { Spinner } from "react-bootstrap"
import Beneficiary from "../Common/Beneficiary"
import Acknowledged from "../Common/Acknowledged"

const OnlineTransfer = ()=>{
    const {selectedAccount, setPage, subPage, setSubPage, hashID, setLrsID, transferOptions, setOnErrorRetry, setShowGenericError, PAGE, SUBPAGE} = useContext(AddFundContext)
    const [loadingContinue, setLoadingContinue] = useState(false)
    const ONLINE=PAGE.ONLINE
    useEffect(()=>{
        setSubPage(SUBPAGE[ONLINE].INSTRUCTION)
    },[])

    const submitData = async(showGenericError)=>{
        let success = true
        let type="online_transfer"
        if(selectedAccount.bank.code==="OTHER_NONINDIAN")
            type="others"
        try{
           const res = await putBrokerDetail(hashID, type)
           if(res  && res.code==200){
                setLrsID(res.data.requestId)
                setSubPage(SUBPAGE[ONLINE].TRANSACTION)
            }else{
                success = false
            }
        }catch(error){
            success = false
        }
        if(success && showGenericError){
            setShowGenericError(false)
            setOnErrorRetry(null)
        }else if(!success){
            setOnErrorRetry(submitData)
            setShowGenericError(true)
        }
    }
    const onContinue = async()=>{
        if(!loadingContinue){
            setLoadingContinue(true)
            await submitData()
            setLoadingContinue(false)
        }

    }
    return (<>
        {subPage ===SUBPAGE[ONLINE].INSTRUCTION && <>
            <div className={style.subHeader}>Online Independent Wire</div>
            <Beneficiary bank={selectedAccount.bankName}/>
            <div className={style.footerContainer}>
                <button className={style.continueBtn} onClick={onContinue}>
                    {loadingContinue && <Spinner animation="border" className={style.spinnerColor}/>}
                    Continue
                    </button>
                {transferOptions && Object.keys(transferOptions).length>1 && <button className={style.tertiaryBtn} onClick={()=>{!loadingContinue && setPage(PAGE.TRANSFER);}}>Try Another Method</button>}
            </div>
        </>}
        {subPage===SUBPAGE[ONLINE].TRANSACTION && <Transaction />}
        {subPage===SUBPAGE.SUCCESS && <Success remittance={false}/>}
        {subPage===SUBPAGE.ACKNOWLEDGEMENT && <Acknowledged />}
    </>)
}

export default OnlineTransfer
