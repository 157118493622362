import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import queryString from 'query-string'
import { format } from 'date-fns'
import {
	Header,
	FundAmount,
	HowAddFund,
	SetupOtherBankAccount,
	OtherCountryAccount,
	TransferComplete,
	ThankYou,
	OtherBankAccount,
	IndianBankAccount,
	FillBankDetail,
	PickupDetail,
	ReviewDetail,
	ReviewWithDate,
	FundTransfer,
	Otp,
	WithDrawFund,
	WithDrawAdd,
	BankAccountAdded,
	AddFund,
	SideBar
} from '../../components'
import CustomModal from '../../components/CustomModal/CustomModal'

import { ThemeContext } from '../../Context/ThemeContext'
import plusImage from '../../public/images/whiteplus.png'
import coinHandImg from '../../public/images/coins-hand.svg'
import bankIcon from '../../public/images/bankIcon.svg'
import coinSwapImg from '../../public/images/coins-swap.svg'
import creditCardCheckImg from '../../public/images/credit-card-check.svg'
import fundImage from '../../public/images/fundtransfer.png'
import dollarIcon from '../../public/images/dollarIcon.svg'
import flagIcon from '../../public/images/Flag.svg'
import PricingPlan from '../../components/PricingPlan'
import { ManageFundSkeleton } from '../../skeletons'
import wireImage from '../../public/images/wireinstruction.png'
import {
	getFund,
	getAccountSummaryWithCommission,
	getAccountInfo,
	getVirtualAccountDetail,
	putApplyFunds
} from '../../api'
import Success from '../../components/Modal/Success'
// import { Modal } from 'bootstrap'
import { putBrokerDetail } from '../../api'
import { setCurrentPlan } from '../../common'
import { Modal } from 'react-bootstrap'
import crossImage from '../../public/images/cross.png'
import Fairexpay from '../../components/Fairexpay'
import FundInitiated from '../../components/Fund/FundForm/WithdrawFundForms/FundInitiated/FundInitiated'
import Banner from '../../components/BeneficiaryBanner/index'
import { addCommasinPrice } from '../../utils'
import { decimalAdjust } from '../../utils'

import styles from './fund.module.css'
import WithdrawFund from '../../components/Fund/WithdrawFund'
import { showFundModal, closeFundModal } from '../../store/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import NewFund from '../NewFund/funds'
import { LEGAL_LINKS } from '../../constants';


const Fund = props => {
	const stringCollection = {
		addFund: 'addFund',
		howAddFund: 'howAddFund',
		setupOtherAccount: 'setupOtherAccount',
		otherBankAccount: 'otherBankAccount',
		otherCountryAccount: 'otherCountryAccount',
		transfer: 'transferComplete',
		thank: 'thankYou',
		setupIndianAccount: 'setupIndianAccount',
		instruction: 'instruction',
		pickup: 'pickup',
		reviewdate: 'reviewdate',
		great: 'great',
		review: 'review',
		otp: 'otp',
		withdraw: 'withdraw',
		withdrawadd: 'withdrawadd',
		addedbank: 'addedbank',
		fairexpay: 'fairexpay'
	}
	const [showModal, setShowModal] = useState(false)
	const [showPlanModal, setShowPlanModal] = useState(false)
	const [showMobileModal, setShowMobileModal] = useState(false)
	const [check, setCheck] = useState('')
	const [modalData, setModalData] = useState('')
	const [toggel, setToggel] = useState(true)
	const [withdrawClicked, setWithdrawClicked] = useState(false)
	const [hashId, setHashId] = useState('')
	const [fund, setFund] = useState('')
	const [otherCountryDetails, setOtherCountryDetails] = useState()
	const [banksList, setBanksList] = useState()
	const [brokerDetail, setBrokerDetail] = useState(null)
	const [foundAccount, setFoundAccount] = useState(false)
	const [skeletonLoading, setskeletonLoading] = useState(true)
	const [enterBrokerDetail, setEnterBrokerDetail] = useState([])
	const [lrsLoading, setLrsLoading] = useState(false)
	const [successModal, setSuccessModal] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [previousTransaction, setPreviousTransaction] = useState([
		{ prevLrsAmount: '', prevLrsDate: '', prevLrsBank: '' }
	])
	const [transactionCount, setTransactionCount] = useState(1)
	const [previous, setprevious] = useState(false)
	const [disablePrevious, setDisablePrevious] = useState(false)
	const [selectedBankNumber, setSelectedBankNumber] = useState(0)
	const [selectedBank, setSelectedBank] = useState([])
	const [clickedBankDetails, setClickedBankDetails] = useState({
		bankName: '',
		bankType: '',
		bankId: ''
	})
	//for upload
	const [panFileFront, setpanFileFront] = useState([])
	const [panFileBack, setpanFileBack] = useState([])
	const [accountStatement1, setaccountStatement1] = useState([])
	const [accountStatement2, setaccountStatement2] = useState([])
	const [accountStatement3, setaccountStatement3] = useState([])
	const [accountStatement4, setaccountStatement4] = useState([])
	const [accountStatementCount, setaccountStatementCount] = useState(1)
	const [profileData, setProfileData] = useState({})

	const { buttonColor, isFundButton, contactNumber } = useContext(ThemeContext)

	const [pickupInformation, setPickupInformation] = useState({
		applicantPan: '',
		selfPickup: true,
		hasDoneAnyPreviousLRSinYear: false,
		transactionNumber: '',
		pickUpDate: '',
		pickUpTime: '',
		address1: '',
		address2: '',
		country: '',
		postalCode: '',
		city: '',
		state: ''
	})
	const [review, setReview] = useState({
		bankName: '',
		ifsc: '',
		accountNumber: '',
		bankAddress: ''
	})
	const [bankDetails, setBankDetails] = useState({
		isMyAccount: false,
		beneficiaryAccountNumber: '',
		beneficiaryAccountType: '',
		beneficiarySwiftABA: '',
		bankName: '',
		bankIFSC: '',
		bankAddress: '',
		nationalBankId: ''
	})

	const [data, setData] = useState([
		{
			icon: coinHandImg,
			title: 'Unsettled',
			description: `Funds that will be settled in T+1 days.`,
			value: '$--',
			extra: ''
		},
		{
			icon: bankIcon,
			title: 'On Hold',
			description: `Funds kept on hold for pending buy orders.`,
			value: '$--',
			extra: ''
		},
		{
			icon: coinSwapImg,
			title: 'Suspense',
			description: `Funds currently awaiting clarification.`,
			value: '$--',
			extra: ''
		},
		{
			icon: creditCardCheckImg,
			title: 'Withdrawable',
			description: `Funds currently awaiting clarification.`,
			value: '$--',
			extra: 'Withdraw'
		}
	])

	const [cashAvailable, setCashAvailable] = useState('$--')
	const [unsettledAmt, setUnsettledAmt] = useState(0)
	const [pendingSettlememt, setPendingSettlement] = useState(null)
	const [goodFaithViolations, setGoodFaithViolation] = useState(null)
	const [withdraw, setWithdraw] = useState(false)
	const [withdrawValue, setWithdrawValue] = useState('')
	const [menuVisible, setMenuVisible] = useState(false)
	const history = useHistory()
	const dispatchAction = useDispatch()
	useEffect(() => {
		const fetchProfileData = async () => {
			let sessionProfileData = ''
			sessionProfileData = sessionStorage.getItem('userInfo')
				? JSON.parse(sessionStorage.getItem('userInfo'))
				: ''
			if (sessionProfileData === '') {
				sessionProfileData = await getProfile()
			}
			setProfileData(sessionProfileData)
			asyncFunctionCall()
			setClickedBankDetails({
				...clickedBankDetails,
				bankName: clickedBankDetails.bankName,
				bankType: clickedBankDetails.bankType,
				bankId: clickedBankDetails.bankId
			})
			let queryUrl = queryString.parse(window.location.search, {
				parseNumbers: false
			})
			if (
				queryUrl.modal &&
				sessionProfileData &&
				sessionProfileData['accountStatus'] === 2
			) {
				dispatchAction(showFundModal({ investment: true,selectFund:true}))
			}
		}
		fetchProfileData()
	}, [])

	const adjustDecimal = e => {
		let commaPrice = decimalAdjust('floor', e, -2)
		return addCommasinPrice(commaPrice)
	}

	const asyncFunctionCall = async () => {
		// let response = await getFund()
		let response = await getAccountSummaryWithCommission()
		setskeletonLoading(false)
		if (
			response &&
			(response.unsettledAmount || response.unsettledAmount !== '') &&
			response.accountSummary &&
			(response.accountSummary.goodFaithViolations ||
				response.accountSummary.goodFaithViolations !== '') &&
			(response.accountSummary.cashAvailableForWithdrawal ||
				response.accountSummary.cashAvailableForWithdrawal !== '') &&
			(response.accountSummary.cashBalance ||
				response.accountSummary.cashBalance !== '') &&
			(response.accountSummary.cashAvailableForTrade ||
				response.accountSummary.cashAvailableForTrade !== '') &&
			(response.commission || response.commission !== '')
		) {
			setData([
				{
					icon: coinHandImg,
					title: 'Unsettled',
					description: `Funds that will be settled in T+1 days.`,
					value: `$${adjustDecimal(response.unsettledAmount)}`,
					extra: ''
					// pendingCommission: `$${addCommasinPrice(response.commission)}`
				},
				{
					icon: bankIcon,
					title: 'On Hold',
					description: `Funds kept on hold for pending buy orders.`,
					// value: `$${addCommasinPrice(response.unsettledAmount)}`,
					value: `$${addCommasinPrice(response.commission)}`,
					extra: ''
				},
				{
					icon: coinSwapImg,
					title: 'Suspense',
					description: `Funds currently awaiting clarification.`,
					value: `$--`,
					extra: ''
				},
				{
					icon: creditCardCheckImg,
					title: 'Withdrawable',
					description: `Funds currently awaiting clarification.`,
					value: `$${addCommasinPrice(
						response.accountSummary.cashAvailableForWithdrawal
					)}`,
					extra: 'Withdraw'
				}
			])

			setWithdrawValue(
				`$${addCommasinPrice(
					response.accountSummary.cashAvailableForWithdrawal
				)}`
			)
			setUnsettledAmt(adjustDecimal(response.unsettledAmount))

			setCashAvailable(
				`$${addCommasinPrice(response.accountSummary.cashAvailableForTrade)}`
			)

			let goodFaithObj = null
			let value = response.accountSummary.goodFaithViolations
			value = value.trim().substring(0, 1)
			goodFaithObj = goodFaithTitle(value)
			goodFaithObj && setGoodFaithViolation(goodFaithObj)
		}

		let pendingSettlementArr = []
		response &&
			response.accountSummary &&
			response.accountSummary.cashSettlement &&
			response.accountSummary.cashSettlement.map((item, idx) => {
				let obj = {
					cash: `$${addCommasinPrice(item.cash)}`,
					time: format(new Date(item.utcTime), 'MMM dd,yyyy')
				}
				pendingSettlementArr.push(obj)
			})
		pendingSettlementArr.length > 0 &&
			setPendingSettlement(pendingSettlementArr)
	}

	const goodFaithTitle = value => {
		switch (value) {
			case '0':
				return {
					value:0,
					title: 'You are a good trader, Keep Going!',
					description:
						'Buying and selling shares more frequently may increase the chances of violation.',
					href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
					extra: ` Learn More`,
					bg: `#ECFAEC`
				}
			case '1':
				return {
					value:1,
					title: 'Take easy, No need to hurry!',
					description:
						'Buying and selling shares more frequently may increase the chances of violation.',
					href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
					extra: ` Learn More`,
					bg: `#EBF2FC`
				}
			case '2':
				return {
					value:2,
					title: 'Going Too Fast, You Need to Relax',
					description: 'Buying and selling shares more frequently may increase the chances of violation.',
					href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
					extra: ` Learn More`,
					bg: `#FFF2EB`
				}
			case '3':
				return {
					value:3,
					title: 'You have Violated Multiple Times',
					description: 'Buying and selling shares more frequently may increase the chances of violation.',
					href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
					extra: ` Learn More`,
					bg: `#FFF2EB`
				}
			default:
				return {
					value:4,
					title: 'You have Violated Multiple Times',
					description: 'Buying and selling shares more frequently may increase the chances of violation.',
					href: LEGAL_LINKS.GOOD_FAITH_VIOLATION,
					extra: ` Learn More`
				}
		}
	}

	const getProfile = async () => {
		const userInfores = await getAccountInfo()
		if (userInfores && userInfores.statusDetails) {
			sessionStorage.setItem('userInfo', JSON.stringify(userInfores))
			return userInfores
		}
	}

	const handleShowMobile = async (x) => {
		let planInfoSession = sessionStorage.getItem('planInfo')
			? JSON.parse(sessionStorage.getItem('planInfo'))
			: ''
		if (!planInfoSession && !sessionStorage.getItem('showPlanModal')) {
			const planInfo = await setCurrentPlan()
			if (!planInfo) {
				sessionStorage.setItem('showPlanModal', true)
				setShowPlanModal(true)
				return
			}
		}
		dispatchAction(showFundModal(x))
    // dispatchAction(showFundModal({ investment: true }))
	}

	const handleCloseMobile = () => {
		setPreviousTransaction(
			[{ prevLrsAmount: '', prevLrsDate: '', prevLrsBank: '' }],
			setTransactionCount(
				1,
				setprevious(false, setDisablePrevious(false, setShowMobileModal(false)))
			)
		)
		setaccountStatementCount(
			1,
			setpanFileBack([]),
			setpanFileFront([]),
			setaccountStatement1([]),
			setaccountStatement2([]),
			setaccountStatement3([]),
			setaccountStatement4([])
		)
	}

	const enterBrokerInformation = async () => {
		let type =
			clickedBankDetails && clickedBankDetails.bankType === 'online'
				? 'online_transfer'
				: clickedBankDetails.bankType === 'pickup'
				? 'from_pickup'
				: 'others'
		try {
			setLrsLoading(true)
			const res = await putBrokerDetail(hashId, type)
			if (res && res.code == 200) {
				setEnterBrokerDetail(res.data)
				setLrsLoading(false)
				return true
			}
		} catch (error) {
			console.log(error)
			setLrsLoading(false)
			return false
		}
	}

	const handlePlanClose = from => {
		setShowPlanModal(false)
		if (from && from === 'success') {
			setSuccessModal(true)
			setSuccessMessage('Plan successfully added')
		}
	}

	const resetPreviousTranaction = () => {
		setSelectedBankNumber(0)
		setPreviousTransaction(
			[{ prevLrsAmount: '', prevLrsDate: '', prevLrsBank: '' }],
			setTransactionCount(1, setprevious(false, setDisablePrevious(false))),
			setPickupInformation({
				applicantPan: '',
				selfPickup: true,
				hasDoneAnyPreviousLRSinYear: false,
				transactionNumber: '',
				pickUpDate: '',
				pickUpTime: '',
				address1: '',
				address2: '',
				country: 'India',
				postalCode: '',
				city: '',
				state: ''
			}),
			setaccountStatementCount(
				1,
				setpanFileBack([]),
				setpanFileFront([]),
				setaccountStatement1([]),
				setaccountStatement2([]),
				setaccountStatement3([]),
				setaccountStatement4([])
			)
		)
	}

	const fetchBrokerDetails = async () => {
		try {
			const res = await getVirtualAccountDetail()
			let response
			if (selectedBank.beneficiaryAccountType === 'other') {
				response = await putApplyFunds(hashId, selectedBank.hashId, false, '')
			} else {
				response = await putApplyFunds(hashId, selectedBank.hashId, false, '')
			}
			if (res && res.code === 200 && response && response.code === 200) {
				setBrokerDetail(res.data)
			} else {
				// setErrorMessage('Something Went Wrong')
				setBrokerDetail(null)
			}
		} catch (error) {
			//   setErrorMessage('Something Went Wrong')
			setBrokerDetail(null)
		}
	}

	const withdrawFundBalance = () => {
		setWithdraw(true)
	}

	const handleMenu = () => {
		setMenuVisible((prev)=>(!prev))
	}

	return (
		<>
			<Header />
			<Container className='mt-5'>
				{/* <div className={styles.heading}>Manage Funds</div> */}
				{skeletonLoading ? (
					<>
						<ManageFundSkeleton
							isFundButton={isFundButton}
							buttonColor={buttonColor}
							profileData={profileData}
							handleShowMobile={handleShowMobile}
						/>
					</>
				) : (
					<>
          <NewFund handleShowMobile={handleShowMobile}
          // setGoodFaithViolation={setGoodFaithViolation}
          goodFaithViolations={goodFaithViolations}
          setWithdraw={setWithdraw}/>
						{/* <div className={styles.fundDetailContainer}>
							<div className={styles.headerContainer}>
								<div className={styles.leftPart}>
									<div className={styles.fundAvailableLabel}>
										Available to Invest
									</div>
									<div className={styles.fundAvailable}>
										{cashAvailable}
										{goodFaithViolations?.value >=3 ? <img src={flagIcon} alt='icon' style={{marginLeft:"8px"}}/> : ''}
									</div>
								</div>
								<div className={styles.rightPart}>
									{isFundButton ? (
										<>
											<button
												style={{ backgroundColor: buttonColor }}
												type='button'
												disabled={
													profileData && profileData['accountStatus'] === 2
														? false
														: true
												}
												onClick={() => handleShowMobile()}
												className={`btn btn-primary btn-sm ${styles.addfunds} themeButton`}>
												<img src={plusImage} alt="icon" />
												<span className={styles.addfundstext}>Add Funds</span>
											</button>
										</>
									) : (
										<></>
									)}
									<button
										type='button'
										onClick={withdrawFundBalance}
										disabled={
											profileData && profileData['accountStatus'] === 2
												? false
												: true
										}
										className={`btn btn-sm ${styles.remittanceHistory} themeButton`}>
										<span className={styles.remittanceHistoryText}>
											Withdraw
										</span>
									</button>
									<div style={{position:"relative"}}>
										<button
											type='button'
											onClick={handleMenu}
											className={`btn btn-sm ${styles.menuContainer} themeButton`}>
											<div className={styles.menuIcon}></div>
										</button>
										<div className={`${styles.optionContainer} ${menuVisible? styles.visible : ''}`}>
											<Link to='/remittance'>
												<div className={styles.options}>
													<img src={dollarIcon} alt='icon' style={{marginRight:"10px"}} />
													Remittance History
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div>
								{data.slice(0, 4).map((item, idx) => {
									return idx !== 2 ? (
										<>
											<div key={idx} className={styles.fundDetailSection}>
												<div className={styles.fundDetail}>
													<div className={styles.fundImg}>
														<img src={item.icon} alt="icon"></img>
													</div>
													<div className={styles.fundInfo}>
														<div className={styles.fundLabel}>{item.title}</div>
														<div className={styles.fundDescription}>
															{item.description}
														</div>
													</div>
												</div>
												<div className={styles.withdrawable}>
													<div className={styles.amount}>{item.value}</div>
												</div>
											</div>
										</>
									) : (
										<></>
									)
								})}
							</div>
						</div>
						{unsettledAmt != 0 && pendingSettlememt && (
							<>
								<div className={styles.pendingHeader}>Pending Settlements</div>
								<div className={styles.pendingDetailContainer}>
									{pendingSettlememt.map((item, idx) => {
										return item.cash !== '$0' ? (
											<>
												<div className={styles.pendingDetailSection}>
													<div className={styles.amountSection}>
														<div className={styles.amount}>{item.cash}</div>
														<div className={styles.pendingLabel}>
															&nbsp;will be settled on
														</div>
													</div>
													<div className={styles.amount}>{item.time}</div>
												</div>
											</>
										) : (
											<></>
										)
									})}
								</div>
							</>
						)}

						{goodFaithViolations && (
							<>
								<div
									className={styles.banner}
									style={{ background: `${goodFaithViolations.bg}` }}>
									<div className={styles.infoWrapper}>
										<div className={styles.bannerHeader}>
											{goodFaithViolations.title}
										</div>
										<div className={styles.bannerDescription}>
											{goodFaithViolations.description}
										</div>
									</div>
									<a href={goodFaithViolations.href} target='_blank'>
										<div className={styles.learnMore}>
											{goodFaithViolations.extra}
										</div>
									</a>
								</div>
							</>
						)} */}
					</>
				)}

				<div className={styles.bottomMargin}></div>
				<div className={styles.mobileHide}>
					<SideBar handleEffect={() => {}} />
				</div>

				<Modal
					show={showMobileModal}
					onHide={handleCloseMobile}
					className={styles.mobileModal}
					dialogClassName={styles.modalDialog}
					scrollable>
					{/* <Modal.Header className={styles.modalHeader}></Modal.Header> */}
					<div className={styles.crossImageStyling}>
						<img src={crossImage} alt="icon" onClick={() => handleCloseMobile()} />
					</div>
					<Modal.Body className={styles.modalPadding}>
						{modalData === stringCollection.addFund ? (
							<FundAmount
								setModalData={setModalData}
								data={stringCollection.howAddFund}
								fund={fund}
								setFund={setFund}
								title={withdrawClicked ? 'Withdraw funds' : 'Add funds'}
								withdrawClicked={withdrawClicked}
								otp={stringCollection.otp}
								setHashId={setHashId}
								hashId={hashId}
								buttonColor={buttonColor}
								stringCollection={stringCollection}
							/>
						) : modalData === stringCollection.howAddFund ? (
							<HowAddFund
								setModalData={setModalData}
								data={stringCollection.setupOtherAccount}
								data2={stringCollection.setupIndianAccount}
								data3={stringCollection.otherBankAccount}
								back={stringCollection.addFund}
								setToggel={setToggel}
								setOtherCountryDetails={setOtherCountryDetails}
								otherCountryDetails={otherCountryDetails}
								setBanksList={setBanksList}
								banksList={banksList}
								clickedBankDetails={clickedBankDetails}
								setClickedBankDetails={setClickedBankDetails}
								setFoundAccount={setFoundAccount}
								setBankDetails={setBankDetails}
								setSelectedBank={setSelectedBank}
							/>
						) : modalData === stringCollection.setupOtherAccount ? (
							<SetupOtherBankAccount
								setModalData={setModalData}
								data={
									stringCollection.otherBankAccount
									// toggel ? (
									//   check === 'pickup'
									//   ? (stringCollection.pickup)
									//   : (check === 'other')
									//   ?  (stringCollection.review)
									//   : (stringCollection.instruction)
									// ) :
									// (stringCollection.otherBankAccount)
								}
								back={
									toggel
										? stringCollection.setupIndianAccount
										: stringCollection.howAddFund
								}
								toggel={toggel}
								checkBank={check}
								setReview={setReview}
								review={review}
								setBankDetails={setBankDetails}
								bankDetails={bankDetails}
								setHashId={setHashId}
								setFoundAccount={setFoundAccount}
								setOtherCountryDetails={setOtherCountryDetails}
								setSelectedBank={setSelectedBank}
								clickedBankDetails={clickedBankDetails}
								setupIndianAccount={stringCollection.setupIndianAccount}
								buttonColor={buttonColor}
							/>
						) : modalData === stringCollection.otherBankAccount ? (
							<OtherBankAccount
								setModalData={setModalData}
								data={
									toggel
										? stringCollection.setupIndianAccount
										: stringCollection.otherCountryAccount
								}
								withdrawClicked={withdrawClicked}
								title={
									withdrawClicked
										? 'Review Withdrawl Details'
										: 'Your Bank Account'
								}
								back={
									foundAccount
										? stringCollection.addFund
										: stringCollection.howAddFund
								}
								otherCountryDetails={otherCountryDetails}
								stringCollection={stringCollection}
								fund={fund}
								setBrokerDetail={setBrokerDetail}
								hashId={hashId}
								contactNumber={contactNumber}
								selectedBankNumber={selectedBankNumber}
								setSelectedBankNumber={setSelectedBankNumber}
								banksList={banksList}
								setClickedBankDetails={setClickedBankDetails}
								buttonColor={buttonColor}
								setSelectedBank={setSelectedBank}
								selectedBank={selectedBank}
								toggel={toggel}
								setFoundAccount={setFoundAccount}
								setCheck={setCheck}
								resetPreviousTranaction={resetPreviousTranaction}
								fetchBrokerDetails={fetchBrokerDetails}
							/>
						) : modalData === stringCollection.otherCountryAccount ? (
							<OtherCountryAccount
								setModalData={setModalData}
								data={stringCollection.transfer}
								back={stringCollection.otherBankAccount}
								brokerDetail={brokerDetail}
								hashId={hashId}
								buttonColor={buttonColor}
								selectedBank={selectedBank}
								buttonTitle={'Transfer Complete'}
								fetchBrokerDetails={fetchBrokerDetails}
							/>
						) : modalData === stringCollection.transfer ? (
							<TransferComplete
								setModalData={setModalData}
								data={stringCollection.thank}
								back={
									toggel
										? check === 'other'
											? stringCollection.great
											: check === 'pickup'
											? stringCollection.great
											: stringCollection.instruction
										: stringCollection.otherCountryAccount
								}
								hashId={hashId}
								buttonColor={buttonColor}
							/>
						) : modalData === stringCollection.thank ? (
							<ThankYou />
						) : modalData === stringCollection.setupIndianAccount ? (
							<IndianBankAccount
								setToggel={setToggel}
								setModalData={setModalData}
								data={stringCollection.setupOtherAccount}
								setCheck={setCheck}
								back={
									foundAccount
										? stringCollection.otherBankAccount
										: stringCollection.howAddFund
								}
								setReview={setReview}
								review={review}
								setPickupInformation={setPickupInformation}
								banksList={banksList}
								setClickedBankDetails={setClickedBankDetails}
								foundAccount={foundAccount}
								otherCountryDetails={otherCountryDetails}
								selectedBank={selectedBank}
								setBanksList={setBanksList}
								stringCollection={stringCollection}
								hashId={hashId}
							/>
						) : modalData === stringCollection.instruction ? (
							<FillBankDetail
								brokerDetail={brokerDetail}
								setModalData={setModalData}
								data={stringCollection.transfer}
								back={stringCollection.setupIndianAccount}
								bankName={clickedBankDetails.bankName}
								bankId={clickedBankDetails.bankId}
								buttonColor={buttonColor}
								enterBrokerInformation={enterBrokerInformation}
								fetchBrokerDetails={fetchBrokerDetails}
							/>
						) : modalData === stringCollection.pickup ? (
							<PickupDetail
								setModalData={setModalData}
								data={stringCollection.reviewdate}
								data2={stringCollection.review}
								back={
									check === 'other'
										? stringCollection.otherBankAccount
										: stringCollection.setupIndianAccount
								} //for fairexpay
								pickupInformation={pickupInformation}
								setPickupInformation={setPickupInformation}
								hashId={hashId}
								previousTransaction={previousTransaction}
								setPreviousTransaction={setPreviousTransaction}
								previous={previous}
								setprevious={setprevious}
								transactionCount={transactionCount}
								setTransactionCount={setTransactionCount}
								otherCountryDetails={otherCountryDetails}
								disablePrevious={disablePrevious}
								setDisablePrevious={setDisablePrevious}
								buttonColor={buttonColor}
								checkBank={check}
								panFileFront={panFileFront}
								setpanFileFront={setpanFileFront}
								panFileBack={panFileBack}
								setpanFileBack={setpanFileBack}
								accountStatement1={accountStatement1}
								setaccountStatement1={setaccountStatement1}
								accountStatement2={accountStatement2}
								setaccountStatement2={setaccountStatement2}
								accountStatement3={accountStatement3}
								setaccountStatement3={setaccountStatement3}
								accountStatement4={accountStatement4}
								setaccountStatement4={setaccountStatement4}
								accountStatementCount={accountStatementCount}
								setaccountStatementCount={setaccountStatementCount}
							/>
						) : modalData === stringCollection.reviewdate ? (
							<ReviewWithDate
								displayData={[
									{
										header: 'Bank Name',
										data:
											selectedBank && selectedBank.bankName
												? selectedBank.bankName
												: '--'
									},
									{
										header: 'Account Number',
										data:
											selectedBank && selectedBank.accountNumber
												? selectedBank.accountNumber
												: '--'
									},
									{ header: 'USD Amount', data: fund }
								]}
								setModalData={setModalData}
								data={stringCollection.great}
								back={stringCollection.pickup}
								pickupInformation={pickupInformation}
								enterBrokerInformation={enterBrokerInformation}
								enterBrokerDetail={enterBrokerDetail}
								fund={fund}
								review={review}
								buttonColor={buttonColor}
							/>
						) : modalData === stringCollection.great ? (
							check === 'other' ? (
								<FundTransfer
									display={{
										image: fundImage,
										title: ` Wire instructions`,
										title2: ``,
										discription:
											'Your bank does not allow this digitally; please do the following:'
									}}
									check={check}
									lrsLoading={lrsLoading}
									enterBrokerDetail={enterBrokerDetail}
									setModalData={setModalData}
									data={stringCollection.transfer}
									buttonColor={buttonColor}
								/>
							) : withdrawClicked ? (
								<FundTransfer
									display={{
										image: fundImage,
										title: `Funds withdrawal `,
										title2: `initiated!`,
										discription:
											'Your bank does not allow this digitally; please do the following:'
									}}
									check={check}
									setModalData={setModalData}
									data={stringCollection.transfer}
									withdrawClicked={withdrawClicked}
									buttonColor={buttonColor}
								/>
							) : (
								<FundTransfer
									display={{
										image: fundImage,
										title: `Offline Fund transfer`,
										title2: `initiated`,
										discription: `Your LRS ID: ${
											enterBrokerDetail && enterBrokerDetail.requestId
												? enterBrokerDetail.requestId
												: '--'
										} `
									}}
									check={check}
									lrsLoading={lrsLoading}
									enterBrokerDetail={enterBrokerDetail}
									setModalData={setModalData}
									data={stringCollection.transfer}
									buttonColor={buttonColor}
								/>
							)
						) : modalData === stringCollection.review ? (
							check === 'other' ? (
								<ReviewDetail
									displayData={[
										{
											header: 'Bank Name',
											data:
												selectedBank && selectedBank.bankName
													? selectedBank.bankName
													: '--'
										},
										{
											header: 'IFSC code',
											data:
												selectedBank && selectedBank.bankIFSC
													? selectedBank.bankIFSC
													: '--'
										},
										{ header: 'USD Amount', data: fund },
										{
											header: 'bank Address',
											data:
												selectedBank && selectedBank.bankAddress
													? selectedBank.bankAddress
													: '--'
										},
										{
											header: 'bank Account number',
											data:
												selectedBank && selectedBank
													? selectedBank.accountNumber
													: bankDetails
													? bankDetails.beneficiaryAccountNumber
													: '--'
										},
										{
											header: 'Swift/IBAN number',
											data:
												selectedBank && selectedBank
													? selectedBank.beneficiarySwiftABA
													: bankDetails
													? bankDetails.beneficiarySwiftABA
													: '--'
										}
									]}
									buttonTitle='Proceed to Download form' //for fairxexpay
									setModalData={setModalData}
									enterBrokerInformation={enterBrokerInformation}
									enterBrokerDetail={enterBrokerDetail}
									data={stringCollection.great} //for fairexpay
									// back={stringCollection.pickup} //for fairexpay
									back={
										foundAccount
											? stringCollection.otherBankAccount
											: stringCollection.setupIndianAccount
									}
									buttonColor={buttonColor}
								/>
							) : (
								<ReviewDetail
									buttonColor={buttonColor}
									displayData={[
										{
											header: 'Bank Name',
											data:
												selectedBank && selectedBank.bankName
													? selectedBank.bankName
													: '--'
										},
										{
											header: 'Account Number',
											data:
												selectedBank && selectedBank.accountNumber
													? selectedBank.accountNumber
													: '--'
										},
										{ header: 'USD Amount', data: fund }
									]}
									setModalData={setModalData}
									enterBrokerInformation={enterBrokerInformation}
									enterBrokerDetail={enterBrokerDetail}
									data={stringCollection.great}
									back={stringCollection.pickup}
									buttonTitle='Proceed to Download Form'
								/>
							)
						) : modalData === stringCollection.otp ? (
							<Otp
								buttonColor={buttonColor}
								setModalData={setModalData}
								data={stringCollection.setupOtherAccount}
								setToggel={setToggel}
							/>
						) : modalData === stringCollection.withdraw ? (
							<WithDrawFund title='Withdraw funds' buttonColor={buttonColor} />
						) : modalData === stringCollection.withdrawadd ? (
							<WithDrawAdd
								buttonColor={buttonColor}
								setModalData={setModalData}
								data={stringCollection.addedbank}
							/>
						) : modalData === stringCollection.addedbank ? (
							<BankAccountAdded />
						) : modalData === stringCollection.fairexpay ? ( //for fairexpay
							<Fairexpay
								fund={fund}
								modalData={modalData}
								setModalData={setModalData}
								stringCollection={stringCollection}
								pickupInformation={pickupInformation}
								setPickupInformation={setPickupInformation}
								hashId={hashId}
								previousTransaction={previousTransaction}
								setPreviousTransaction={setPreviousTransaction}
								previous={previous}
								setprevious={setprevious}
								transactionCount={transactionCount}
								setTransactionCount={setTransactionCount}
								disablePrevious={disablePrevious}
								setDisablePrevious={setDisablePrevious}
								buttonColor={buttonColor}
								checkBank={check}
								panFileFront={panFileFront}
								setpanFileFront={setpanFileFront}
								panFileBack={panFileBack}
								setpanFileBack={setpanFileBack}
								accountStatement1={accountStatement1}
								setaccountStatement1={setaccountStatement1}
								accountStatement2={accountStatement2}
								setaccountStatement2={setaccountStatement2}
								accountStatement3={accountStatement3}
								setaccountStatement3={setaccountStatement3}
								accountStatement4={accountStatement4}
								setaccountStatement4={setaccountStatement4}
								accountStatementCount={accountStatementCount}
								setaccountStatementCount={setaccountStatementCount}
								enterBrokerInformation={enterBrokerInformation}
								enterBrokerDetail={enterBrokerDetail}
								selectedBank={selectedBank}
								bankDetails={bankDetails}
							/>
						) : null}
					</Modal.Body>
				</Modal>
			</Container>

			{/* planPricing */}
			{showPlanModal ? (
				<PricingPlan
					setPlanButton={() => setShowPlanModal(false)}
					pageType='new'
					show={showPlanModal}
					handleClose={handlePlanClose}
					isUserSet={() => props.isUserSet()}
				/>
			) : (
				''
			)}
			{/* {successModal ? (
				<Success show={successModal} message={successMessage} />
			) : (
				''
			)} */}
			{withdraw ? (
				<WithdrawFund
					withdrawValue={withdrawValue}
					showModal={withdraw}
					setShowModal={setWithdraw}
				/>
			) : (
				''
			)}
		</>
	)
}

export default Fund
