import React,{useState,useEffect} from 'react'
import {Card,Row,Col} from 'react-bootstrap'
import { format } from 'date-fns'
import OutsideClickHandler from 'react-outside-click-handler'
import DatePicker from '../../DatePicker/DatePicker'
import CalendarSvg from '../../../public/images/newOnboarding/Calendar.svg'
import SignUpInput from '../../SignUp/SignUpInput'
import SignUpButton from '../../SignUp/SignUpButton'
import NewHeader from '../../NewSignupComponents/NavBar/NewHeader'
import {verifyPAN,getAccountInfo,logout,extractDocumentContent,updatedAccount} from '../../../api'
import { setUserInfoSession } from '../../../common';
import DocumentSkeleton from '../../../skeletons/DocumentSkeleton'
import { useHistory } from "react-router"
import styles from './IndDocument.module.css'

const VerifyPan = () => {
  const [pancard,setPancard] = useState('')
  const [firstName,setFirstName] = useState('')
  const [middleName,setMiddleName] = useState('')
  const [lastName,setLastName] = useState('')
  const [dateOfBirth,setDateOfBirth] = useState('')
  const [showCalendar,setShowCalendar] = useState(false)
  const [loading,setLoading] = useState(false)
  const [panError,setPanError] = useState('')
  const [firstNameError,setFirstNameError] = useState('')
  const [lastNameError,setLastNameError] = useState('')
  const [dateOfBirthError,setDateOfBirthError] = useState('')
  const [disableData,setDisableData] = useState(false)
  const [showLoader,setShowLoader] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const fetchAccountInfo = async() => {
      setShowLoader(true)
      try{
        const res = await getAccountInfo()
        if (res && Object.keys(res).length > 0) {
          sessionStorage.setItem('userInfo', JSON.stringify(res))
          if(res.hasOwnProperty('panVerification')){
            uploadedData()
          }
          else{
            // eslint-disable-next-line no-undef
            webengage.track("Pan verfication started");
            if(res?.firstName || res?.firstName !== ''){
            res?.firstName && setFirstName(res?.firstName)
            res?.lastName && setLastName(res?.lastName)
            res?.middleName && setMiddleName(res?.middleName)
            res?.dob && setDateOfBirth(res?.dob)
            if(res?.panVerification && (res.panVerification === 'COMPLETED' || res.panVerification === 'INPROGRESS' || res.panVerification === 'REJECTED' )){
            setDisableData(true)
            }
            setShowLoader(false)
            }
            else{
              if(sessionStorage.getItem('oaid')){
                extractDoc(sessionStorage.getItem('oaid'))
              }else{
                setShowLoader(false)
              }
            }
          }
        }
      }catch(error){
        console.log(error)
      }
    }
    fetchAccountInfo()
  },[])

 const extractDoc = async applicantID => {
		try {
			const extractData = await extractDocumentContent(applicantID)
			if (extractData && extractData.data && extractData.data.length) {
				const filter = extractData.data.filter(item => {
					if (
						item.extracted_data &&
						Object.keys(item.extracted_data).length > 0 && item.extracted_data.hasOwnProperty('first_name')
					) {
						return true
					}
				})
				if (filter.length) {
					const data = filter[0].extracted_data
					const name = data.first_name ? data.first_name.split(' ') : []
          setFirstName(name.length ? name[0] : '')
          setMiddleName(name.length > 1 ? name[1] : '')
         data?.last_name && setLastName(data?.last_name)
         data?.date_of_birth && setDateOfBirth(data?.date_of_birth)
				}
			}
      setShowLoader(false)
		} catch (error) {
			console.log(error)
		}
	}

	const formatDateDisplay = date => {
		if (typeof date === 'string') {
			return date
		}
		return format(date, 'yyyy-MM-dd')
	}

  let date = new Date()
  date.setFullYear(date.getFullYear() - 18)


  const checkPan  = () => {
    if(!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(pancard)){
      setPanError('Please enter a valid PAN (Eg. AFZPK7190K)')
      checkEmpty()
      }
    else{
      setPanError('')
      return true
    }
  }

  const checkEmpty = () => {
    const isFirstNameEmpty = firstName.trim() === '';
    const isLastNameEmpty = lastName.trim() === '';
    dateOfBirth === '' && setDateOfBirthError('Date of Birth is required',setLoading(false))
    isFirstNameEmpty && setFirstNameError('First Name is required', setLoading(false));
    isLastNameEmpty && setLastNameError('Last Name is required', setLoading(false));
    if (!isFirstNameEmpty && !isLastNameEmpty && dateOfBirth !== '') {
      return true;
    }
  };

  const errorValidation = (value) => {
     if(value.includes('name') || value.includes('Name')){
       setFirstNameError('Doesn’t match PAN',setLastNameError('Doesn’t match PAN'))
     }
     else if(value.includes('dob') || value.includes('date of birth') || value.includes('DoB')){
       setDateOfBirthError('Doesn’t match PAN')
     }
     else if(value.includes('PAN')){
      setPanError('PAN invalid')
    }else{
       setPanError('Unable to verify')
     }
  }

  const onVerifyPan = async() => {
      try{
      const fullname= middleName === '' || middleName === undefined || middleName === null ? `${firstName.trim()} ${lastName.trim()}` : `${firstName.trim()} ${middleName.trim()} ${lastName.trim()}`
      const dobArr = formatDateDisplay(dateOfBirth).split('-');
      const dob = dobArr.length === 3 ? dobArr[2]+'/'+dobArr[1]+'/'+dobArr[0] : format(new Date(dateOfBirth), "dd/MM/yyyy");
      const resp = await verifyPAN({pan:pancard.toUpperCase(), name:fullname, dob},'ONBOARDING')
      if(resp.data && resp.data.code === 200){
         // eslint-disable-next-line no-undef
         webengage.track("Pan verfication completed",{
          'pan verification successful':true
         });
        uploadedData()
      }else{
         // eslint-disable-next-line no-undef
         webengage.track("Pan verfication completed",{
          'pan verification successful':false
         });
        if(resp.data.code === 400){
          errorValidation(resp.data.message)
         }else{
          setPanError('Unable to verify')
         }
      }
      setLoading(false)
     }catch(error){
        // eslint-disable-next-line no-undef
        webengage.track("Pan verfication completed",{
          'pan verification successful':false
        });
       if(error.code === 400){
        errorValidation(error.message)
       }else{
        setPanError('Unable to verify')
       }
       setLoading(false)
     }
  }

  const uploadedData = async (data) => {
    updatedAccount({ nextStep: 'PERSONAL_DETAILS' })
    .then(res => {
      if (res.code === 200) {
        setUserInfoSession({ nextStep: 'PERSONAL_DETAILS' })
       history.push('/accountsetup', { data, applicantId: sessionStorage.getItem('oaid') })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  const handleSubmit = () => {
     setLoading(true,setFirstNameError('',setLastNameError('',setDateOfBirthError(''))))
     if(checkPan()){
       if(checkEmpty()){
         onVerifyPan()
       }
     }else{
      setLoading(false)
     }
  }

  const onClickLogout = () => {
    var token = JSON.parse(sessionStorage.getItem('token'))
    //webengage logout
    // eslint-disable-next-line no-undef
    webengage.user.logout()
    // delete token.refreshToken
    var body = {
      accessToken: token && token.accessToken
    }
    // var accessToken = JSON.stringify(token)
    logout(JSON.stringify(body)).then(response => {
      sessionStorage.clear()
      localStorage.clear()
        history.push('/')
    })
  }

  const toggle = () => {
    if(disableData && dateOfBirth !== ''){
      setShowCalendar(false)
    }else{
      setShowCalendar(!showCalendar)
    }
  }


  return(
    <>
    <NewHeader
      message=''
      linkName='Log Out'
      onClickLink={onClickLogout}
      />
    {showLoader ? <DocumentSkeleton/> :
    <div className={styles.cardDiv}>
    <Card className={styles.cardSignUp}>
      <Card.Body className={styles.cardBodySignup}>
        <Row className={styles.title}>Verify your PAN</Row>
        <Row className={styles.subTitle}>
        We need additional details to verify your PAN. Please ensure your details exactly match with your document.
        </Row>
        <Row className={styles.splashKycBox} style={{margin:'20px 0px 0px 0px'}}>
            <SignUpInput
              label='Permanent Account Number'
              placeholder = 'Enter PAN Number'
              classes={styles.panCardCapital}
              type='text'
              name='pancard'
              tabIndex='1'
              value={pancard}
              onChange={event => setPancard(event.target.value,setPanError('',setFirstNameError(''),setLastNameError('')))
              }
              isInvalid={panError}
              errorMessage={panError}
          />
        </Row>
        <Row  style={{margin:'20px 0px 0px 0px'}}>
          <Col className='pl-0'>
            <SignUpInput
                label='First Name'
                placeholder = 'Enter First Name'
                type='text'
                name='firstName'
                tabIndex='1'
                value={firstName}
                onChange={event => setFirstName(event.target.value,setPanError('',setFirstNameError(''),setLastNameError('')))
                }
                isInvalid={firstNameError}
                errorMessage={firstNameError}
                readOnly={disableData && firstName !== ''}
            />
          </Col>
          <Col className='pr-0'>
            <SignUpInput
                label='Middle Name (optional)'
                placeholder = 'Enter Middle Name'
                type='text'
                name='middleName'
                tabIndex='1'
                value={middleName}
                onChange={event => setMiddleName(event.target.value,setPanError('',setFirstNameError(''),setLastNameError('')))
                }
                readOnly={disableData && middleName !== ''}
                // onBlur={event => handleBlur(event)}
                // onKeyPress={(e) => this.keyPressHandler(e,handleSubmit)}
                // isInvalid={(touched.email && errors.email) || this.state.emailError}
                // isValid={(touched.email && !errors.email && this.state.emailError === '')}
                // errorMessage={errors.email || this.state.emailError}
                // icon={EmailSvg}
            />
          </Col>
        </Row>
        <Row  style={{margin:'20px 0px 0px 0px'}}>
          <Col className='pl-0'>
            <SignUpInput
                label='Last Name'
                placeholder = 'Enter Last Name'
                type='text'
                name='lastName'
                tabIndex='1'
                value={lastName}
                onChange={event => setLastName(event.target.value,setPanError('',setFirstNameError(''),setLastNameError('')))
                }
                isInvalid={lastNameError}
                errorMessage={lastNameError}
                readOnly={disableData && lastName !== ''}
            />
          </Col>
          <Col className='pr-0'>
               <OutsideClickHandler
                onOutsideClick={() => setShowCalendar(false)}>
                    <SignUpInput
                        containerClasses={styles.passwordInvalid}
                        tabIndex='6'
                        label='Date of Birth'
                        placeholder = 'Date of Birth'
                        type='text'
												name='dateOfBirth'
												value={formatDateDisplay(dateOfBirth)}
												readOnly={true}
                        isInvalid = {dateOfBirthError}
												onClick={toggle}
                        errorMessage={dateOfBirthError}
                    />
                    <img className={styles.calendarImg} src={CalendarSvg} onClick={toggle}></img>
										{showCalendar ? (
											<div className={styles.calendar}>
													<DatePicker
														onChange={date => {
															setDateOfBirth(date, setShowCalendar(!showCalendar))
														}}
														date={new Date().setFullYear(new Date().getFullYear() - 18)}
														color='#0061D3'
														maxDate={date}
													/>
											</div>
										 ) : (
											''
										)}
              </OutsideClickHandler>
          </Col>
        </Row>
        <div style={{marginTop:'32px'}}>
            <SignUpButton
              title='Verify PAN'
              tabIndex='3'
              onClick={handleSubmit}
              loading={loading}
            />
        </div>
      </Card.Body>
    </Card>
  </div>}
  </>
  )
}

export default VerifyPan
