import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import styles from './NavigationStates.module.css'
import Lottie from 'react-lottie-player';
import GlobeSpin from '../../public/lottie/globeSpin.json'
import cards from '../../public/images/cards.svg'
import review from '../../public/images/review.svg'
import rejected from '../../public/images/rejected.svg'
import startBannerUnderProcess from '../../public/images/StartBannerUnderProcess.svg'
import { LEGAL_LINKS } from '../../constants';

const NotificationEmptyState = () => {
    return (
        <div className={styles.stateContainer}>
            <Lottie
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                loop={true}
                animationData={GlobeSpin}
                play
                style={{ width: 112, height: 112, margin:"auto" }}
            />
            <p className={styles.title}>You’re all caught up!</p>
            <p className={styles.description}>New notifications will show up here.</p>
        </div>
    )
}

const CashAccountReviewState = () => {
    return (
        <div className={styles.stateContainer}>
            <img src={review} alt='review' style={{height:"69px"}}></img>
            <p className={styles.title}>Cash Account application is under review.</p>
            <p className={styles.description}>We just need a bit more time to confirm it.</p>
            <a href={LEGAL_LINKS.LEARN_MORE} target="_blank" rel="noopener noreferrer">
                <button className={styles.stateButton}>Learn More</button>
            </a>
        </div>
    )
}

const CashAccountRejectedState = () => {

    return (
        <div className={styles.stateContainer}>
            <img src={rejected} alt='rejected' style={{height:"90px"}}></img>
            <p className={styles.title}>Cash Account application has been rejected.</p>
            <p className={styles.description}>Please contact customer support for more details.</p>
            <div className={styles.buttonContainerColumn}>
                <a href="mailto:customersuccess@stockal.com" target="_blank" rel="noopener noreferrer">
                    <button className={styles.stateButtonSolid} style={{minWidth:"193px"}}>Contact Support</button>
                </a>
                <a href={LEGAL_LINKS.LEARN_MORE} target="_blank" rel="noopener noreferrer">
                    <button className={styles.stateButton} style={{minWidth:"193px"}}>Learn More</button>
                </a>
            </div>
        </div>
    )
}

const CashAccountSignupState = (props) => {

    const { accountApproved } = props

    let history = useHistory()

    return (
        <div className={styles.stateContainer}>
            <img src={cards} alt='review' style={{height:"103px"}}></img>
            <p className={styles.title}>Sign up for a Cash Management account.</p>
            <p className={styles.description}>Save, invest and spend with a Cash A/C.</p>
            {accountApproved ? (
                <div onClick={() => history.push('/savings/signup')}>
                    <button className={styles.stateButtonSolid}>Open US Account</button>
                </div>
            ) : (
                <button className={styles.buttonDisabled}>Open US Account</button>
            )}
        </div>
    )
}

const DriveWealthNotApprovedState = () => {

    let history = useHistory()

    return (
        <div className={styles.stateContainer}>
            <img src={startBannerUnderProcess} alt='review' style={{maxWidth:"300px"}}></img>
            <p className={styles.title}>Investment Account application is under process.</p>
            <p className={styles.description}>We just need a bit more time to confirm it.</p>
            <div>
                <button className={styles.stateButton}>
                  <a href="https://www.stockal.com/cash" target="_blank" rel="noopener noreferrer">
                    Learn More
                  </a>
                </button>
            </div>
        </div>
    )
}

const CashAccountPausedState = (props) => {

    const { isDisabled, addedToWaitlist, addToWaitlist } = props

    return (
        <div className={styles.stateContainer}>
            <img src={cards} alt='review' style={{height:"103px"}}></img>
            <p className={styles.title}>Interested in a Cash Management Account?</p>
            <p className={styles.description}>Save, invest and spend with a Cash A/C.</p>
            {addedToWaitlist ?
                <>
                    <button className={styles.buttonDisabled}>You’ll be notified</button>
                </> : <>
                    {isDisabled ?
                        <Button variant="primary" disabled style={{minWidth:'111px',borderRadius:'6px'}}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button> :
                        <button className={styles.stateButtonSolid} onClick={()=>addToWaitlist()}>I’m Interested</button>
                    }
                </>
            }
        </div>
    )
}


export { NotificationEmptyState, CashAccountReviewState, CashAccountRejectedState, CashAccountSignupState, DriveWealthNotApprovedState, CashAccountPausedState }


