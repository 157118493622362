import React, { useEffect, useState,useContext } from 'react'
import PortfolioMain from '../../components/Portfolio/Portfolio'
import PortfolioTable from '../../components/PortfolioTable/PortFolioTable'
import { Container, Row, Col,Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap'
import {
  getPortfolioData,
  getAssetsPortfolio,
  getCurrentValue,
  getLivePrice,
  checkForNewHolding,
  getTradeCycleDates,
  getAccountInfo,
  getMonthlyStatement,
  getStatementFile
} from '../../api'
import { useDispatch, useSelector } from 'react-redux'
import { Header, Footer, PortfolioTableMobile, SideBar, BottomNav  } from '../../components'
import { USER_PORTFOLIO } from '../../store/actions/action'
import styles from './portfolio.module.css'
import PortfolioSummarySkeleton from './PortfolioSummarykeleton'
import PortfolioStackSkeleton from './PortfolioStackSkeleton'
import { partition, map, keyBy, isEmpty, isNumber } from 'lodash'
import { getMarketDetails, getUserInvestment, getUserAccountSummary } from '../../store/actions'
import minusImage from '../../public/images/minus.png'
import addImage from '../../public/images/add.png'
import lendingImage from '../../public/images/lending.svg'
import downloadImage from '../../public/images/Download.svg'
import warningIcon from "../../public/images/alert.svg"
import { PORTFOLIO_LIVE_PRICE_INTERVAL, PORTFOLIO_CURRENT_VALUE_INTERVAL, MARKET_OPEN_STATUS, PORTFOLIO_DELTA_API_INTERVAL, PORTFOLIO_PAGE } from '../../constants'
import { calPriceChange, calcCurrVal, calReturn, isMarketOpen ,decimalAdjust, addCommasinPrice} from '../../utils'
import {getLivePricesFromTicker} from '../../common'
import EmptyPage from '../../components/EmptyPage/EmptyPage'
import ExportPortfolio from './Export'
import { Spinner } from "react-bootstrap"
import { LEGAL_LINKS } from '../../constants';

const Portfolio = () => {
	const dispatchAction = useDispatch()
  const [loading, setloading] = useState(true)
  const [apiLoading, setApiLoading] = useState(true)
  const [loadingStacks, setloadingStacks] = useState(true)
  const [livePrices, setLivePrices] = useState({});
  const [currentValues, setcurrentValues] = useState({});
  const [timestamp, setTimestamp] = useState('');
  const [portfolioEmpty, setPortfolioEmpty] = useState(false);
  const [slideeffect, setslideeffect] = useState(false)
  const [tradeCycleDates, setTradeCycleDates] = useState({
  })
  const [profileData, setProfileData] = useState(null)
  const [fpslLoading, setFpslLoading] = useState(false)
  const [fpslError, setFpslError] = useState(null)
  const [tryAgainCounter, setTryAgainCounter] = useState(0)
	const state = useSelector(state => state)
	const {
    userPortfolio: { userPortfolio = {} } = {},
    userAccountSummary: { userSummary = {} } = {},
    marketDetails: { marketDetails: { currentMarketStatus = {} } = {} } = {},
  } = state
	const {
		stackPortfoliosList = [],
		stockPortfoliosList = [],
    etfPortfoliosList = [],
    stackSymbols = [],
    etfSymbols = [],
    stockSymbols = [],
    assetPortfolio = []
  } = userPortfolio

  const {
		portfolioSummary: {
      stockPortfolio = {},
      stackPortfolio = {},
      etfPortfolio= {},
    } = {}
	} = userSummary

  const setUserInfo = async() => {
    let userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null
    if(!userInfo){
        const res = await getAccountInfo()
        if (res && res.statusDetails){
            sessionStorage.setItem('userInfo', JSON.stringify(res))
            userInfo=res
        }
    }
    setProfileData(userInfo)
  }

  const handleEffect = () => {
		setslideeffect(prevState => setslideeffect(!prevState))
	}

  Object.keys(state.marketDetails).length === 0 &&
    dispatchAction(getMarketDetails())


    /**
     * Portfolio header calculation
    */


	const title = ['Stacks', 'Stocks', 'ETFs', 'Funds']
	const stackHeader = [
		'Stack',
		'Name',
		'Price',
		'Total Invested',
		'Total Return',
		'Current Value'
	]
	const stockHeader = [
		'Stock',
		'Name',
		'Price',
		'Shares',
		'Avg.Cost',
		'Total Invested',
		'Total Return',
		'Current Value'
	]
	const etfHeader = [
		'ETF',
		'Name',
		'Price',
		'Shares',
		'Avg.Cost',
		'Total Invested',
		'Total Return',
		'Current Value'
	]
  const fundHeader = [
    'Fund',
		'Name',
		'Report'
	]
	const stackData = []
	const stockData = []
  const etfData = []
  const fundData = []
  let totalCurrentValuepf = 0 ;
  // const getCurrentValueStacks = async (ticker, category) => {
  //   const { currentValue = '' } = await getCurrentValue(ticker, category);
  //   return currentValue;
  // }

  const getCurrentValueStacks = async (tickers) => {
    let cvs = {};
    try {
      await map(tickers, async (ticker) => {
          const data = await getCurrentValue(ticker, 'stack');
          cvs = {
            ...cvs,
            [ticker]: { currentValue: data.currentValue }
          }
          setcurrentValues(cvs);
      })
    } catch(err){
      console.log("error in current value ", err);
    }
  }

  const getPortfolioDataApi = async () => {
    let data;
    try {
      data = await getPortfolioData()
    } catch (err) {
      console.log("error fetching portfolioData");
    }
    return data;
  }


  const getLivePriceNonStacks = async(tickers) => {
    try {

      // tickers = tickers.join(',')
      // let livePrices = await getLivePrice(tickers);
      const liveprices = await getLivePricesFromTicker(tickers)
      setLivePrices(keyBy(liveprices, 'symbol'));
    } catch (err) {
      console.log("error fetching live prices");
    }
  }

  const getTradeCycleDatesApi = async() => {
    try {
      let tradeCycleDatesData = await getTradeCycleDates();
      setTradeCycleDates(tradeCycleDatesData);
    } catch (err) {
      console.log("error fetching trade cycle dates");
    }
  }

  const setNonStacksData = (item) => {
    if(isMarketOpen(currentMarketStatus)) {
      item.price = livePrices[item.symbol] && livePrices[item.symbol].lastTrade
    } else {
      item.price = livePrices[item.symbol] && livePrices[item.symbol].close;
    }
    // if(currentMarketStatus !== 'Close'){
    //   item.price = livePrices[item.symbol] && livePrices[item.symbol].lastTrade;
    // }
    // else{
    //   item.price = livePrices[item.symbol] && livePrices[item.symbol].close;
    // }
    const dwPriorclose = isMarketOpen(currentMarketStatus) ?livePrices[item.symbol] && livePrices[item.symbol].close: livePrices[item.symbol] && livePrices[item.symbol].priorClose;
    let currPrice = item.price
    let {change, changePer} = calPriceChange({
      currPrice,
      close: dwPriorclose ? dwPriorclose : item.close,
      priorClose: dwPriorclose ? dwPriorclose : item.priorClose,
      isMarketOpen: isMarketOpen(currentMarketStatus)})
    let currentValue = calcCurrVal(item.totalUnit, item.price);
    let {ret: totalReturn, retPer: totalRetPer} = calReturn(currentValue, item.totalInvestment)
    const symbolHeader = item.type !== 'etf' ? 'Stock' : 'ETF'
    let { listed, sellOnly } = item;
    let isDelisted = false;
    isDelisted = sellOnly
    return {
      Image: item.logo,
      [symbolHeader]: item.symbol,
      Name: item.company,
      Price: currPrice,
      Chng: changePer,
      Shares: item.totalUnit,
      'Avg.Cost': item.totalInvestment / item.totalUnit,   //check if avg cost calculation is correct
      'Total Invested': item.totalInvestment,
      'Total Return': totalReturn,
      'Return Percent': totalRetPer,
      'Current Value': currentValue,
      category: item.category,
      change,
      symbol: item.symbol,
      totalReturn,
      totalRetPer,
      type:item.type,
      isDelisted,
    }
  }

	stackPortfoliosList.map(async (item, i) => {
    let currPrice = item.price
    // let {change, changePer} = calPriceChange({currPrice, close: item.close, priorClose: item.priorClose, isMarketOpen: isMarketOpen(currentMarketStatus)})
    let currentValue = currentValues[item.symbol] && currentValues[item.symbol].currentValue;
    let {ret: totalReturn, retPer: totalRetPer} = calReturn(currentValue, item.totalInvestment)
    let isDelisted = false;
    const temp = {
			ModalData: item.stocks,
			Image: item.logo,
			Stack: item.symbol,
			Name: item.name,
			Price: currPrice,
			Chng: item.percentChange,
			'Total Invested': item.totalInvestment,
			'Total Return': totalReturn,
			'Return Percent': totalRetPer,
      'Current Value': currentValue,
      category: item.category,
      change: item.change,
      isSellPending: item.sWeightedPrice && item.sWeightedPrice > 0 ? true: false,
      isRebalancing: item.isRebalancing,
      symbol: item.symbol,
      isStackActive: item.isStackActive,
      totalReturn,
      totalRetPer,
      type: item.type,
      tos: item.tos,
      isDelisted,
		}
    stackData.push(temp)
  })

	stockPortfoliosList.map(async (item, i) => {
    let temp = setNonStacksData(item);
		stockData.push(temp)
  })

	etfPortfoliosList.map((item, i) => {
    let temp = setNonStacksData(item);
		etfData.push(temp)
  })

  //This is not right way to do this operation since for other categories are been done this way, to maintain consistency following the same
  assetPortfolio.map((item, i) => {
		fundData.push({Image:item.logo,
      Fund:item.code,
      symbol:item.code,
      Name:item.name,
      Report:item.report,
      type:'fund'
    })
  })

	useEffect(() => {
    const asynchFetch = async () => {
      let portfoliodata, timestamp, pendingOrders;
      let [equtityData, assetData=[]] = await Promise.all([getPortfolioDataApi(), getAssetsPortfolio()])
      if((equtityData && !isEmpty(equtityData)) || (assetData && !isEmpty(assetData))) {
        ({ holdings: portfoliodata, timestamp, pendingData: pendingOrders  } = equtityData);
        if((portfoliodata && portfoliodata.length === 0) && assetData.length===0) {
          setPortfolioEmpty(true);
        }
      }
      let [stackPortfoliosList, nonStacksPortfolioList] = partition(portfoliodata, ({ category }) => category === 'stack');
      let [stockPortfoliosList, etfPortfoliosList] = partition(nonStacksPortfolioList, ({ type }) => type !== 'etf');
      let stackSymbols = map(stackPortfoliosList, 'symbol');
      let stockSymbols = map(stockPortfoliosList, 'symbol');
      let etfSymbols = map(etfPortfoliosList, 'symbol');
      if(stackPortfoliosList && stackPortfoliosList.length > 0) {
        // await stackPortfoliosList.map(async({symbol, category}, index) => {
          await getCurrentValueStacks(stackSymbols);
        // })
      }
      if((stockSymbols && stockSymbols.length > 0) || (etfSymbols && etfSymbols.length > 0)){
        await getLivePriceNonStacks([...stockSymbols, ...etfSymbols])
      }
      setApiLoading(false)
      setTimestamp(timestamp);
      dispatchAction({ payload: {
        stackPortfoliosList,
        stockPortfoliosList,
        etfPortfoliosList,
        timestamp,
        stackSymbols,
        stockSymbols,
        etfSymbols,
        assetPortfolio:assetData
      }, type: USER_PORTFOLIO })
    }

    const checkForNewHoldingApi = async () => {
      let isNewHolding = false;
      try {
        let newHolding =  await checkForNewHolding(timestamp)
        if(newHolding && newHolding.count && newHolding.count > 0) {
          asynchFetch();
        }
      } catch(err) {
        console.log("error fetching delta for holdings");
      }
      return isNewHolding;
    }

    /**
     * Call delta api every specified second to check if any new entry added in pf, if exists call pf API again to refresh.
     */
    const newHoldingId = setInterval(() => {
      if(timestamp && timestamp !== "") {
        checkForNewHoldingApi();
      }
    }, PORTFOLIO_DELTA_API_INTERVAL);

    asynchFetch();
    return () => {
      clearInterval(newHoldingId);
    }
	}, [dispatchAction, timestamp])

  /**
   * Current Value for the stacks | livePrice for nonStacks
   * running every 10 seconds for now => need to change to every 5 seconds.
   */
  useEffect(() => {
    const setLivePrice = async () => {
      if ((stockSymbols && stockSymbols.length > 0) || (etfSymbols && etfSymbols.length > 0)) {
        await getLivePriceNonStacks([...stockSymbols, ...etfSymbols])
      }
    }

    const setCV = async () => {
      if (stackSymbols && stackSymbols.length > 0) {
          await getCurrentValueStacks(stackSymbols);
      }
    }

    const priceId = setInterval(() => {
      if(isMarketOpen(currentMarketStatus)){
        setLivePrice();
      }
    }, PORTFOLIO_LIVE_PRICE_INTERVAL);

    const valueId = setInterval(() => {
      if (isMarketOpen(currentMarketStatus)) {
        setCV();
      }
    }, PORTFOLIO_CURRENT_VALUE_INTERVAL);

    return () => {
      clearInterval(valueId);
      clearInterval(priceId);
    }
  }, [currentMarketStatus, dispatchAction, etfSymbols, stackPortfoliosList, stockSymbols, userPortfolio, stackSymbols])

  let stockSum = stockPortfolio && !isEmpty(stockPortfolio) ? stockPortfolio.currentValue : 0
  let stackSum = stackPortfolio && !isEmpty(stackPortfolio) ? stackPortfolio.currentValue : 0
  let etfSum = etfPortfolio && !isEmpty(etfPortfolio) ? etfPortfolio.currentValue : 0

  if(!isNaN(stockSum) && isNumber(stockSum)){
    totalCurrentValuepf += stockSum
  }
  if(!isNaN(stackSum) && isNumber(stackSum)){
    totalCurrentValuepf += stackSum
  }
  if(!isNaN(etfSum) && isNumber(etfSum)){
    totalCurrentValuepf += etfSum
  }

useEffect(() => {
  if(!apiLoading) {
    if(stackPortfoliosList.length === stackData.length) {
      setloadingStacks(false)
    }
    if((stockPortfoliosList.length === stockData.length) && (etfPortfoliosList.length === etfData.length)) {
      setloading(false)
    }
  }
}, [stackData, stackPortfoliosList, apiLoading, etfData, etfPortfoliosList, stockPortfoliosList.length, stockData.length])

useEffect(() => {
  const tradeCycleDatesAPi = async() => {
    await getTradeCycleDatesApi()
  }
  tradeCycleDatesAPi();
  setUserInfo()
}, [])
const sortArray=(data)=>{
  // descending order
  if (data.length > 0) {
    const that = this;
    data.sort(function (a, b) {
      var keyA = Date.parse(convertStringToDate(a.displayName)),
        keyB = Date.parse(convertStringToDate(b.displayName));
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  }
  return data;
}
const convertStringToDate=(stringData)=> {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  stringData = stringData.replace(',', '');
  const month = monthNames.findIndex(mon => mon === stringData.split(' ')[0]) + 1;
  const date = stringData.split(' ')[1];
  const year = stringData.split(' ')[2];
  return year + '-' + month + '-' + date;
}
const getDateStandard = (val)=>{
  if (val < 10)
    return "0" + val
  else
    return val
}
const  getMonthlyStatementHandler = async()=>{
  if(tryAgainCounter>3){
    window.fcWidget.open()
  }
  else if(!fpslLoading && !(fpslError && fpslError<2)){
    setFpslError(null)
    setFpslLoading(true)
    const date = new Date()
    const endDate = new Date(`${date.getFullYear()}-${getDateStandard(date.getMonth()+1)}-${getDateStandard(date.getDate())}`).toISOString()
    date.setMonth(date.getMonth()-2)
    const startDate = new Date(`${date.getFullYear()}-${getDateStandard(date.getMonth()+1)}-01`).toISOString()
    const response = await getMonthlyStatement(profileData.userID, startDate, endDate);
    if (response.code === 200 && response.data) {
       const data = sortArray(response.data)
       if(data.length>0){
          const pdfDataRes = await getStatementFile(profileData.userID, data[0].fileKey);
          if(pdfDataRes.status === 200){
            const href = window.URL.createObjectURL(new Blob([pdfDataRes.data], { type: "application/pdf" }))
            let element = document.createElement('a');
            element.setAttribute('href', href);
            element.setAttribute('download', '');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.dispatchEvent(new MouseEvent('click'));
            document.body.removeChild(element);
            setTryAgainCounter(0)
          }
          else{
            setTryAgainCounter(tryAgainCounter+1)
            setFpslError(2)
          }
        }else{
          setTryAgainCounter(tryAgainCounter+1)
          setFpslError(1)
       }
    } else {
      setTryAgainCounter(tryAgainCounter+1)
      setFpslError(2)
    }
    setFpslLoading(false)
  }
}
return (
		<>
			<Header />
      <BottomNav />
			  <Container
        className={slideeffect ? styles.shiftPos : styles.originalPos}
        >
        {
        portfolioEmpty ?
        <EmptyPage
        type={PORTFOLIO_PAGE}
        /> :
        <>
				  <Row className={styles.summaryRow}>
					<Col>
						<Row className={styles.portfolioTitle}>
							<Col>portfolio summary</Col>
              <Col className={styles.export} xs={4}>
                <ExportPortfolio stockData={stockData} stackData={stackData} etfData={etfData}/>
              </Col>
						</Row>
						<PortfolioMain
            portfolioEmpty={portfolioEmpty}
            totalCurrentValuePf={totalCurrentValuepf}
            tradeCycleDates={tradeCycleDates}
            />
					</Col>
				</Row>
				  <Container className={styles.mobilePadding}>
            <div className='d-none d-md-flex'>
            <Accordion defaultActiveKey='0' style={{width:'100%'}} >
              <CustomToggle
                eventKey='0'
                value={stockSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',stockSum,-2))}`}
                title={title[1]}></CustomToggle>
              <Accordion.Collapse eventKey='0'>
                  { loading ? (
						          <PortfolioStackSkeleton />
					            ) :
                      <PortfolioTable
                        title={title[1]}
                        rowHeaders={stockHeader}
                        data={stockData}
                      />
                    }
              </Accordion.Collapse>
              <CustomToggle
                eventKey='1'
                value={etfSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',etfSum,-2))}`}
                title={title[2]}></CustomToggle>
              <Accordion.Collapse eventKey='1'>
                { loading ? (
						          <PortfolioStackSkeleton />
					            ) :
                    <PortfolioTable
                      title={title[2]}
                      rowHeaders={etfHeader}
                      data={etfData}
                    />
                  }
              </Accordion.Collapse>
              <CustomToggle
                eventKey='2'
                value={stackSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',stackSum,-2))}`}
                title={title[0]}></CustomToggle>
              <Accordion.Collapse eventKey='2'>
              {loadingStacks ? (
                  <PortfolioStackSkeleton />
                ) :
                  (
                    <PortfolioTable
                      title={title[0]}
                      rowHeaders={stackHeader}
                      data={stackData}
                      // data={first}
                    />
                  )
                }
              </Accordion.Collapse>
              <CustomToggle
                eventKey='3'
                value={''}
                title={title[3]}></CustomToggle>
              <Accordion.Collapse eventKey='3'>
              {loadingStacks ? (
                  <PortfolioStackSkeleton />
                ) :
                  (
                    <PortfolioTable
                      title={title[3]}
                      rowHeaders={fundHeader}
                      data={fundData}
                    />
                  )
                }
              </Accordion.Collapse>
            </Accordion>
            </div>
							{/* <Row className='d-none d-md-flex'>
								<Col>
									<Row className={`mb-3 ${styles.tableTitle}`}>
                    <span>{title[1]}</span>
                    <span className={styles.totalSum}>{stockSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',stockSum,-2))}`}</span></Row>
										{ loading ? (
						          <PortfolioStackSkeleton />
					            ) :
                      <PortfolioTable
                        title={title[1]}
                        rowHeaders={stockHeader}
                        data={stockData}
                      />
                    }
								</Col>
							</Row> */}
							{/* <Row className='d-none d-md-flex'>
								<Col>
									<Row className={`mb-3 ${styles.tableTitle}`}><span>{title[2]}</span>
                  <span className={styles.totalSum}>{etfSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',etfSum,-2))}`}</span></Row>
                  { loading ? (
						          <PortfolioStackSkeleton />
					            ) :
                    <PortfolioTable
                      title={title[2]}
                      rowHeaders={etfHeader}
                      data={etfData}
                    />
                  }
								</Col>
							</Row> */}
          	{/* <Row className='d-none d-md-flex'>
						<Col>
							<Row className={`mb-3 ${styles.tableTitle}`}>
                <span>{title[0]}</span>
                <span className={styles.totalSum}>{stackSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',stackSum,-2))}`}</span></Row>
							{loadingStacks ? (
								<PortfolioStackSkeleton />
							) :
                (
                  <PortfolioTable
                    title={title[0]}
                    rowHeaders={stackHeader}
                    data={stackData}
                    // data={first}
                  />
                )
              }
						</Col>
					</Row> */}
          <div className='d-block d-md-none'>
          <Accordion defaultActiveKey='0' style={{width:'100%'}} >
              <CustomToggle
                eventKey='0'
                value={stockSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',stockSum,-2))}`}
                title={title[1]}></CustomToggle>
              <Accordion.Collapse eventKey='0'>
                  { loading ? (
						          <PortfolioStackSkeleton />
					            ) :
                      <PortfolioTableMobile
                      title={title[1]}
                      rowHeaders={(stockHeader)}
                      data={stockData}
                      currentValue={stockSum}
                    />
                    }
              </Accordion.Collapse>
              <CustomToggle
                eventKey='1'
                value={etfSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',etfSum,-2))}`}
                title={title[2]}></CustomToggle>
              <Accordion.Collapse eventKey='1'>
                <PortfolioTableMobile
                  title={title[2]}
                  rowHeaders={ etfHeader}
                  data={etfData}
                  currentValue={etfSum}
                />
              </Accordion.Collapse>
              <CustomToggle
                eventKey='2'
                value={stackSum === 0 ? '' : ` / $${addCommasinPrice(decimalAdjust('floor',stackSum,-2))}`}
                title={title[0]}></CustomToggle>
              <Accordion.Collapse eventKey='2'>
                <PortfolioTableMobile
                  title={title[0]}
                  rowHeaders={(stackHeader)}
                  data={stackData}
                  currentValue={stackSum}
                />
              </Accordion.Collapse>
              <CustomToggle
                eventKey='3'
                value={''}
                title={title[3]}></CustomToggle>
              <Accordion.Collapse eventKey='3'>
                <PortfolioTableMobile
                  title={title[3]}
                  rowHeaders={fundHeader}
                  data={fundData}
                />
              </Accordion.Collapse>
            </Accordion>
          </div>

					{/* <Row className='d-block d-md-none'>
						<Col>
							<PortfolioTableMobile
								title={title[1]}
								rowHeaders={(stockHeader)}
                data={stockData}
                currentValue={stockSum}
							/>
						</Col>
					</Row>
          <Row className='d-block d-md-none'>
						<Col>
							<PortfolioTableMobile
								title={title[2]}
								rowHeaders={ etfHeader}
                data={etfData}
                currentValue={etfSum}
							/>
						</Col>
					</Row>
          <Row className='d-block d-md-none'>
						<Col>
							<PortfolioTableMobile
								title={title[0]}
								rowHeaders={(stackHeader)}
                data={stackData}
                currentValue={stackSum}
							/>
						</Col>
					</Row> */}
        {/* Security lending */}
        {profileData&&!profileData['fpslProhibited'] && profileData['fpslAgreement']&&<div className={styles.secLending}>
              <div className={styles.info}>
                <img src={lendingImage} alt='lending-icon'/>
                <div>
                  <div className={styles.lendingHeader}>Securities Lending</div>
                  <div className={styles.lendingDescription}>To know how much interest you have earned on your holdings last month, download the report. <span onClick={()=>window.open(LEGAL_LINKS.SECURITIES_LENDING_DISCLOSURE)}>Learn More</span></div>
                </div>
              </div>
              <button className={`${styles.lendingDownload} ${fpslError&&styles.lendingDownloadError} ${(fpslLoading || fpslError===1)&&styles.disableBtn}`} onClick={getMonthlyStatementHandler} >
                {!fpslError?<>
                  {!fpslLoading?<img src={downloadImage} alt='download-icon'/>
                  :<Spinner animation="border" className={styles.spinnerColor}/>}
                  Download Report
                </>
                  :<span>
                    <img src={warningIcon} alt='alert-icon'/>
                    Unable to download
                    {fpslError>1&&<span className={styles.tryAgain}>{tryAgainCounter>3?'Contact Support':'Try Again'}</span>}
                  </span>
                }
              </button>
          </div>
        }
				</Container>
        </>
      }
				{/* <Row className={styles.portfolioFooter}>
					<Col>
						<Footer />
					</Col>
				</Row> */}
        <div className={styles.sideBarMobileHide}>
          <SideBar handleEffect={handleEffect} />
        </div>
			</Container>
    </>
	)
}

const CustomToggle = ({ eventKey, value, title, callback }) => {
	const currentEventKey = useContext(AccordionContext)

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey)
	)

	const isCurrentEventKey = currentEventKey === eventKey

	return (
		<Row onClick={decoratedOnClick} className={`mb-3 ${styles.tableTitle}`}>
			<Col className='pl-0' xs={10}>
      <span>{title}</span>
      <span className={styles.totalSum}>{value}</span>
			</Col>
			<Col md={2} xs={2} style={{textAlign:"end",paddingRight:'0px'}}>
				<img
					src={
						isCurrentEventKey
							? minusImage
							: addImage
					} style={{cursor:'pointer'}}></img>
			</Col>
		</Row>
	)
}

export default Portfolio

// formulae to calculate
// total return = (((totalUnits*close)-totalInvestment)/totalInvestment)*100
// current value = totalUnits*close
// avg cost = totalInvestment/totalUnits
