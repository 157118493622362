import React, { useContext, useEffect } from "react"
import styles from './common.module.css'
import addFundPending from '../../../../public/images/addFundPending.svg'
import transferAckowledge from '../../../../public/images/transferAcknowledge.svg'
import { AddFundContext } from "../../AddFundContext";
import { useDispatch } from 'react-redux';
import {closeFundModal} from '../../../../store/actions'

const Acknowledged = ({msg, desc, buttonText, buttonAction }) => {
    const { setNoClose, setShowCancel , isCheckboxSelectedForTransaction, onBack} = useContext(AddFundContext)
    const dispatchAction = useDispatch()
    
    useEffect(() => {
        setNoClose(true)
        setShowCancel(false)
    }, [])
    
    const handleButtonClick = () => {
        if (buttonAction) {
            buttonAction()
        } else {
            dispatchAction(closeFundModal())
        }
    }

    const headingText = isCheckboxSelectedForTransaction ? 'Add funds pending' : 'Transfer request acknowledged' 
    const descriptionText = isCheckboxSelectedForTransaction ? 
    'If you haven’t completed the transaction, kindly initiate it from your bank. You can go back to view the detailed steps for the same. Kindly ignore if the transaction has already been initiated. It usually takes up-to 48 hours for the funds to reflect in your account.':
    'It usually takes up-to 48 hours for the funds to reflect in your account.' ;
    const imageAsPerCheckBox = isCheckboxSelectedForTransaction ? transferAckowledge : addFundPending;
    
    return(
        <>
        <div className={styles.acknowledgedContainer}>
            <img src={imageAsPerCheckBox} alt="success" />
            <h3 className={styles.acknowledgedHead}>{headingText}</h3>
            <p className={styles.acknowledgedDesc}>{descriptionText}</p>
        </div>
        <div className={styles.footerContainer}>
        <button className={styles.continueBtn} onClick={()=>{dispatchAction(closeFundModal())}}>
            Understood
        </button>
        {isCheckboxSelectedForTransaction ?  
        <span className={styles.blueTextGoBack} style={{marginTop:"10px"}} 
            onClick={() => onBack()}>
            Go Back
        </span> : ""}
        </div> 
        </>
    )
}

export default Acknowledged;
