import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { Redirect } from 'react-router'
import styles from './ProfileCard.module.css'
import { useHistory } from 'react-router-dom'
import { logout } from '../../api'
import { Link } from 'react-router-dom'
import { addCommasinPrice } from '../../utils'
import Public from '../../public/images/Profile.svg'
import Remittance from '../../public/images/Remittance.svg'
import Reports from '../../public/images/Reports.svg'
import Help from '../../public/images/Help.svg'
import Logout from '../../public/images/Logout.svg'
import { LEGAL_LINKS } from '../../constants'



const ProfileCard = (props) => {
	let history = useHistory()
  const ssoShown = sessionStorage.getItem('Sso_flag') ? sessionStorage.getItem('Sso_flag') : false
  let logoutpage = sessionStorage.getItem('logoutPage') ? sessionStorage.getItem('logoutPage') : ''

  const logoutPageCal = () => {
    if(logoutpage){
      window.location.href=logoutpage
    }else{
      window.location.href='/logout'
    }
  }

	const onClickLogout = () => {
		var token = JSON.parse(sessionStorage.getItem('token'))

     //webengage logout
      // eslint-disable-next-line no-undef
     webengage.user.logout();

		// delete token.refreshToken
		var body = {
			accessToken: token && token.accessToken
		}
		// var accessToken = JSON.stringify(token)
		logout(JSON.stringify(body)).then(response => {
			sessionStorage.clear()
			localStorage.clear()
      if(ssoShown){
        logoutPageCal()
      }
      else{
        history.push('/')
      }
		})
	}

  const onCloseApp = () => {
    if(localStorage.getItem('fromStockalApp') === 'Y' || localStorage.getItem('fromStockalApp') === 'y'){
      logoutPageCal()
      window.closeSite.postMessage('close')
    }
    else{
      onClickLogout()
    }
  }

  let portfolioAmount = props.portfolioSummary && props.portfolioSummary.totalCurrentValue ? props.portfolioSummary.totalCurrentValue.toFixed(2) : "--";
  let cashAvailableForTrade = props.accountSummary && props.accountSummary.cashAvailableForTrade ? props.accountSummary.cashAvailableForTrade.toFixed(2) : "--";


	return (
		<Card className={styles.profileCard} data-testid='profilecard-div'>
			<Card.Body className='p-0 pt-4 pb-4'>
				<Row className={styles.amountSection}>
					<Col className='p-0'>
						<div className={styles.amount}>${portfolioAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
						<div className={styles.amountText}>Portfolio</div>
					</Col>
					<Col className='p-0'>
						<div className={styles.amount}>${cashAvailableForTrade.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
						<div className={styles.amountText}>Buying Power</div>
					</Col>
				</Row>
				<Link to='/profile' className={styles.link}>
					<Row className={styles.profileList}>
						<span>
							<img src={Public}></img>
						</span>
						<span className={styles.listItem}>Profile</span>
					</Row>
				</Link>
        <Link to='/remittance' className={styles.link}>
          <Row className={styles.profileList}>
            <span>
              <img
                src={Remittance}></img>
            </span>
            <span className={styles.listItem}>Remittance History</span>
          </Row>
        </Link>
        <Link to='/reports' className={styles.link}>
          <Row className={styles.profileList}>
            <span>
              <img src={Reports}></img>
            </span>
            <span className={styles.listItem}>Reports</span>
          </Row>
        </Link>
        {/* <Link to='https://stockal.freshdesk.com/support/home' target="_blank" className={styles.link}> */}
        <a target='_blank' href={LEGAL_LINKS.HELP_HOME} className={styles.link} rel="noopener noreferrer" >
          <Row className={styles.profileList}>
            <span>
              <img src={Help}></img>
            </span>
            <span className={styles.listItem}>Help</span>
          </Row>
        </a>
        {/* </Link> */}
        {ssoShown ?
        <Row className={styles.profileList} onClick={onCloseApp}>
          <span>
            <img src={Logout}></img>
          </span>
          <span className={styles.listItem}>
            Close
          </span>
        </Row>  :
        <Row className={styles.profileList} onClick={onClickLogout}>
          <span>
            <img src={Logout}></img>
          </span>
          <span className={styles.listItem}>
            Logout
          </span>
        </Row> }
			</Card.Body>
		</Card>
	)
}

export default ProfileCard
