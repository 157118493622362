import React, { useState, useEffect, useContext } from "react"
import CustomModal from "../CustomModal/CustomModal"
import style from "./addFund.module.css"
import Account from "./Account"
import Transfer from "./Transfer/Transfer"
import Bank from "./Bank"
import { AddFundContext } from "./AddFundContext"
import BackIcon from "../../public/images/back.svg"
import InfoIcon from "../../public/images/infofaded.svg"
import Cancel from "./Cancel"
import GenericError from "./GenericError"
import GenericErrorWithOption from './GenericErrorWithOption'
import AddBank from "./Add/AddBank"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeFundModal } from "../../store/actions"
import { FundConfigContext } from "./AddFundConfig"
import SelectTransferType from "./SelectTransferType"
import { getConfigDataOfSelectedTransferOption } from '../../api';
import AxisBankTransfer from "./Transfer/Axis"
import { LEGAL_LINKS } from '../../constants';
const AddFund = () =>{
    const {brokerAvailable,selectedBank,setSelectedBank,setSelectedBankProcess,selectedBankProcess,isAxisBank,setStepsToTransactions,setMakeVerification,showAxis,setShowAxis,setShowError,setLoading,AddFundConfigReset,stepsToTransactions} = useContext(FundConfigContext);
    const {accountType, page, reset, fund, setFund, onBack, noHeader, setNoHeader, showGenericError, gotoList, setShowGenericError, setIsSaving, showCancel, setShowCancel, setCancelDetails, noClose, subPage, setPage, setAccountType, isSaving, PAGE, SUBPAGE,showGenericErrorOption,setManageFund,seteasyfundTransDetail,fundTitle,setSelectedTransfer} = useContext(AddFundContext)
    const [validAmount, setValidAmount] = useState(true)
    const [shouldValidate, setShouldValidate] = useState(false)
    const inputRef = useRef()
    const dispatchAction = useDispatch()
    const {show, option} = useSelector(({fund})=>fund)
    const [userInfoLodaded, setUserInfoLoaded] = useState(false);

    useEffect(()=>{
       fetchUserInfo()
    },[])
    const fetchUserInfo= ()=>{
        const details = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';
        if(details){
            setUserInfoLoaded(true)
            setIsSaving(details.savingAccountStatus===2)
        }
    }
    useEffect(()=>{
        if(page===PAGE.ACCOUNT || page===PAGE.BANK)
            setValidAmount(true)
    },[page])
    useEffect(()=>{
        if(!show){
            reset()
            setShouldValidate(false)
            setValidAmount(true)
            AddFundConfigReset();
        }else{
            if(!userInfoLodaded)
                fetchUserInfo()
              if(option.investment && option.manageBank){
                setAccountType('Investment')
                setPage(PAGE.BANK)
                setManageFund(option)
              }else if((option.investment && option.easyFund) || (option.investment && option.hdfc)){
                if(option.transactionDetails && option.transactionDetails.vendorName){
                  setSelectedTransfer(option.transactionDetails.vendorName)
                }
                setAccountType('Investment')
                seteasyfundTransDetail(option.transactionDetails ? option.transactionDetails : '')
                setPage(PAGE.EASYFUND)
              }
              else if(option.investment && (!option.saving || !isSaving) && !option.selectFund){
                setAccountType('Investment')
                setPage(PAGE.BANK)
            }else if(option.investment && (!option.saving || !isSaving) && option.selectFund){
                setAccountType('Investment')
                setPage(PAGE.FUNDSELECT)
                setShowError(false)
            }
            else if(!option.investment && option.saving){
                setAccountType('Saving')
                setPage(PAGE.BANK)
            }
        }
    },[show])
    useEffect(()=>{
        shouldValidate && validate()
    },[fund])
    const closeModal = ()=>{
        dispatchAction(closeFundModal())
    }
    const validate = ()=>{
        if(!shouldValidate)
            setShouldValidate(true)
        if((accountType==='Investment' && fund >= 100)||(accountType==='Saving' && fund >=20)){
            setValidAmount(true)
            return true
        }
        else{
            setValidAmount(false)
            inputRef.current.focus()
            document.getElementById('modal-body').scrollTop=0
            return false
        }
    }

    const onClose = ()=>{
        if(subPage===SUBPAGE.SUCCESS || subPage === SUBPAGE[PAGE.EASYFUND].AUTOUPLOADSTATEMENT ||  subPage === SUBPAGE[PAGE.EASYFUND].UPLOADSTATEMEN || stepsToTransactions === "GEN_PAYMENT"){
            closeModal()
        }else if(!showGenericError && !(page===PAGE.DIRECT && subPage===SUBPAGE[PAGE.DIRECT].ERROR) && !(page===PAGE.ADD && subPage===SUBPAGE[PAGE.ADD].VERIFY)){
            if(!showCancel){
                setCancelDetails({msg:'Are you sure you want to cancel the transaction?', onConfirm:closeModal})
                setShowCancel(true)
            }
        }else{
            setNoHeader(false)
            if(showGenericError)
                setShowGenericError(false)
            gotoList()
        }
    }
    return(<>
        <CustomModal show={show} handleClose={onClose} className={`${style.addFundModal} ${noClose && style.noClose}`}>
            <div className={style.container}>
                {!noHeader&& <div className={style.header}>
                    {showAxis ? "" :
                    page !==PAGE.ACCOUNT && <img src={BackIcon} className={style.backIcon} onClick={onBack}/>}
                    <div className="d-flex flex-column">
                        <span className={style.primary} id='addfund-title'>{page!==PAGE.ADD?'Add Funds': fundTitle}</span>
                        {accountType&& page!==PAGE.ADD && page !==PAGE.ACCOUNT && <span className={style.secondary}>{`${accountType==='Investment'?'Investment':'Cash'} Account`}</span>}
                        {page ===PAGE.ACCOUNT && <span className={style.secondary}>Add amount and select account to be funded</span>}
                    </div>
                </div>}
                <div className={`${style.body} ${noHeader && 'mt-0'}`}>
                    <div className={`${!showCancel && 'd-none'}`}>
                        <Cancel setShowCancel={setShowCancel}/>
                    </div>
                    {showGenericError && <GenericError />}
                    {showGenericErrorOption && <GenericErrorWithOption/>}
                    <div className={`${(showCancel || showGenericError || showGenericErrorOption)&& 'd-none'}`}>
                        {/* First two screens */}
                        {page===PAGE.BANK || page === PAGE.FUNDSELECT  && accountType==="Investment"&&<div className={style.investNote}>
                                <img src={InfoIcon}/>
                                <div>
                                    <span>$5 One-Time Fee Deducted for W8-Ben Form.</span>
                                    <span className={style.textLink} onClick={()=>window.open(LEGAL_LINKS.W8_BEN, '_blank')}>Learn more</span>
                                </div>
                            </div>}
                        {(page===PAGE.ACCOUNT || page=== PAGE.BANK || page === PAGE.FUNDSELECT) && <>
                            <div className={style.inputContainer}>
                                <div className={style.input}>
                                    <div className={style.inputWrap}>
                                        <span className={style.widthMachine} aria-hidden="true">{fund}</span>
                                        <input className={style.customInput} value={fund} type="number" placeholder="100" min={1} onKeyPress={(e)=>(e.key==='.'||e.key==='-') && e.preventDefault()}
                                            onChange={(e)=>setFund(e.target.value)} onWheel={(e) => e.target.blur()}
                                            ref={inputRef}/>
                                    </div>
                                </div>
                                {validAmount? <div className={style.hint}>{page===PAGE.ACCOUNT? 'Please input the amount to be transferred':`Min investment amount${accountType==='Investment'?': $100':' recommended: $1000'}`}</div>
                                    :<div className={style.error}>{accountType==='Investment'?'Min investment amount: $100':'Min investment amount: $20'}</div>
                                }
                            </div>
                            <div className={style.accountContainer}>
                                {page===PAGE.ACCOUNT?<Account />
                                :page === PAGE.FUNDSELECT ?
                                    <SelectTransferType setSelectedBank={setSelectedBank}
                                    PAGE={PAGE}
                                    setPage={setPage}
                                    selectedBank={selectedBank}
                                    brokerAvailable={brokerAvailable}
                                    setShowAxis={setShowAxis}/>
                                    :<Bank
                                    setMakeVerification={setMakeVerification}
                                    isAxisBank={isAxisBank}
                                    setStepsToTransactions={setStepsToTransactions}
                                    selectedBank={selectedBank}
                                    validate={validate}/>
                                }
                            </div>
                        </>
                        }
                        {/* Add Bank flow */}
                        {page===PAGE.ADD && <AddBank />}
                        {/* Transfer flow */}
                        {showAxis ?
                        <AxisBankTransfer fund={fund}/>
                        :
                        (page===PAGE.TRANSFER || page===PAGE.DIRECT || page===PAGE.ONLINE || page===PAGE.OFFLINE || page===PAGE.EASYFUND) && <Transfer /> }
                    </div>

                </div>
            </div>
        </CustomModal>
    </>
    )
}

export default AddFund
