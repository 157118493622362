import React, { Component, useContext } from 'react'
import {Card, Col} from 'react-bootstrap'
import SignUp from './SignUp'
import VerifyEmail from './VerifyEmail'
import UserName from './UserName'
import {
  checkPartnerAvailability,
  checkLoginByPartnerToken,
  clientLogin,
  getPartnerCountries
} from '../../api'
import PhoneNumber from './PhoneNumber'
import NewHeader from '../NewSignupComponents/NavBar/NewHeader'
import AlertError from '../AlertError/AlertError'
import queryString from 'query-string'
import { unscramble } from '../../encryption'
import { ThemeContext } from '../../Context/ThemeContext'
import $ from 'jquery'
import styles from './SignUp.module.css'
import OnboardingFooter from '../Footer/OnboardingFooter'
import Logo from '../../public/images/newlogo.svg'
import LoginBanner from '../Login/LoginBanner'

class SignUpForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 'SignUp',
      signUp: {},
      refcode: '',
      leadSqCompID: '',
      serverError: '',
      statePartnerCode: '',
      activeCountries:[]
    }
  }

  static contextType = ThemeContext


  JMPrevention(){
    let queryUrl = queryString.parse(this.props.location.search, {
      parseNumbers: false
    })

    let queryUrl1 = queryString.parse(this.props.location.search, {
      decode: false
    })
    if (queryUrl1.d) {
      let eData = unscramble(queryUrl1.d)
      queryUrl = queryString.parse(eData, { parseNumbers: false })
    }
     //jm partner not allowed without partnerToken,name,phone,email
    // if((this.context.partnerCode === 'JMFINANCIAL') && !((queryUrl.partnerToken || queryUrl.Client_ID) && queryUrl.email && queryUrl.name && queryUrl.phone)){
    if((this.context.partnerCode === 'JMFINANCIAL') && !((queryUrl.partnerToken || queryUrl.Client_ID) && queryUrl.name && queryUrl.phone)){
    //rediect to home page
      window.location.href = this.context.landingUrl
    }
  }

  componentDidUpdate() {
    // console.info("signup--",this.props.authed)

    if (this.context.partnerCode !== this.state.statePartnerCode) {
      this.setState({ statePartnerCode: this.context.partnerCode })
      this.setUserDetailsByParams()
    }
    // this.JMPrevention()

  }
  componentDidMount(){
  //   this.getLeadSquareData();
    this.getQueryParamter()
    // this.JMPrevention()
    this.getActiveCountryCitizenship()
  }

  getQueryParamter = () => {
    let queryUrl = queryString.parse(this.props.location.search, {
      parseNumbers: false
    })
    if(queryUrl.username || queryUrl.email){
      var jsonQuery = JSON.stringify(queryUrl)
      const expires = new Date(2147483647 * 1000).toUTCString();
      document.cookie = "userStockalDetail" + "=" + jsonQuery + "; expires=" + expires + ";path=/";
    }
    this.getCookies()
  }

  getCookies = () => {
    let getCookies = document.cookie.split(';')
    for(let i =0;i<getCookies.length;i++){
      const splited = getCookies[i].split('=')
       if(splited && splited[0].includes("userStockalDetail")){
         const data = splited[1] ? JSON.parse(splited[1]) : {}
         const obj = {
           email:data.email ? data.email :'',
           userName:data.username ? data.username : ''
         }
         this.setState({signUp:{...this.state.signUp,...obj}})
       }
    }
  }


  getActiveCountryCitizenship = async () => {
    const flagArray = []
    getPartnerCountries('residence').then(response => {
      if (response && response.code === 200) {
        const list = response.data;
        list.map(item => {
          if(item){
          const temp = {
            value: item.country,
            label: item.countryName,
            code: item.country,
            code2: item.countryCode2,
          }
            flagArray.push(temp)
        }
          else{
            console.info("item--",item)
          }

        })
        this.setState({ activeCountries: [...flagArray]})
      }
    })
  }


  async SetRefCode() {
    const { partnerCode } = this.context
    let queryUrl = queryString.parse(this.props.location.search, {
      parseNumbers: false
    })
    let refcode = partnerCode

    let queryUrl1 = queryString.parse(this.props.location.search, {
      decode: false
    })
    if (queryUrl1.d) {
      let eData = unscramble(queryUrl1.d)
      queryUrl = queryString.parse(eData, { parseNumbers: false })
    }

    if (queryUrl.refcode) {
      refcode = queryUrl.refcode.toUpperCase()
    }
    //check partner availability
    let partnerResp = await checkPartnerAvailability(refcode)
    let leadSqCompID = ''
    if (partnerResp.code === 200 && partnerResp.data) {
      leadSqCompID = partnerResp.data.leadSqCompID
        ? partnerResp.data.leadSqCompID
        : ''
      refcode = partnerResp.data.code ? partnerResp.data.code : ''
    }
    this.setState({
      refcode: refcode.toUpperCase(),
      leadSqCompID
    })
    this.getLeadSquareData(leadSqCompID);
    return refcode
  }
  FindIndex(paramsArray, key) {
    for (var i = 0; i < paramsArray.length; i++)
      if (paramsArray[i] === key)
        return i;
  }

  getLeadSquareData(leadSqCompID) {
    if (leadSqCompID) {
      let url = window.location.href;
      let paramsObject = {};
      let params = url.split('?')[1];
      if (params) {
        let paramArr = params.split("&");
        for (let i = 0; i < paramArr.length; i++) {
          paramsObject[paramArr[i].split('=')[0]] = paramArr[i].split('=')[1];
        }
        }
      const data = {
        ...paramsObject,
        searchBy: 'email',
        relatedCompanyId: leadSqCompID,
      }
      this.setState({ leadData: data })
    }
  }

  async setUserDetailsByParams() {
    let iiflData = false
    let refcode = await this.SetRefCode()
    //debugger
    //  if (refcode === 'IIFL') {
    // 		this.setState({ pageLoading: true })
    // 	}
    let queryUrl = queryString.parse(this.props.location.search, {
      parseNumbers: false
    })

    const { isPartner } = this.context
    if ((isPartner === 'true' || isPartner === true) && !refcode) {
      this.props.history.push('/signin')
    }
    let queryUrl1 = queryString.parse(this.props.location.search, {
      decode: false
    })
    if (queryUrl1.d) {
      let eData = unscramble(queryUrl1.d)
      queryUrl = queryString.parse(eData, { parseNumbers: false })
    }
    let obj


    if (queryUrl.fromStockalApp) {
      localStorage.setItem('fromStockalApp',queryUrl.fromStockalApp)
    }
    if (queryUrl.email) {
      obj = { ...obj, email: queryUrl.email.toLowerCase() }
    }
    if (queryUrl.phone) {
      obj = {
        ...obj,
        phoneNumber: queryUrl.phone.includes(' ')
          ? `+${queryUrl.phone.trim().replace(/-/g, ' ')}`
          : queryUrl.phone,
        phoneCountryCode: 'IN',
        isPhoneParamPresent: true
      }
      // this.ValidateNumber()
    }
    if (queryUrl.username) {
      obj = { ...obj, userName: queryUrl.username }
    }

    if (queryUrl.userReferralCode) {
      obj = { ...obj, refCode: queryUrl.userReferralCode }
    }

    if (queryUrl.partnerToken || queryUrl.Client_ID) {
      const clientCode = queryUrl.partnerToken || queryUrl.Client_ID
      this.setState({ signUp: { ...this.state, partnerToken: clientCode } })
      obj = { ...obj, partnerToken: clientCode }
      const loginResp = await checkLoginByPartnerToken({
        partnerToken: clientCode
      })

      if (loginResp.code === 200  && loginResp.data && loginResp.data.dwUsername) {
        // const { partnerName } = this.context
        $('#mdlRedirect').modal('show')
        sessionStorage.setItem('partner-token', clientCode)
        // const country = partnerName
        const that = this
        setTimeout(function () {
          $('#mdlRedirect').modal('hide')
          that.setState({ loading: false })
          that.props.history.push('/signin?username=' + loginResp.data.dwUsername)
        }, 3000)
      } else {
        if (refcode === 'IIFL') {
          const { clientData, responseTime } = await this.getClientData(
            clientCode
          )
          if (clientData) {
            iiflData=true
            this.setClientData(clientData)
          }
        }
      }
    }
    // //jm partner not allowed without partnerToken,name,phone,email
    // if((refcode === 'JMFINANCIAL') && !((queryUrl.partnerToken || queryUrl.Client_ID) && queryUrl.email && queryUrl.name && queryUrl.phone)){
    //   //rediect to home page
    //   window.location.href = this.context.landingUrl
    // }
    if (obj !== undefined && !iiflData) {
      this.setState({ signUp: obj })
    }
  }


  setClientData = response => {
    if (response && response.Status === 0) {
      let fullname = this.getName(response.ClientName)
      let obj = {
        ...this.state.signUp,
        firstName: fullname.txtFirstName,
        middleName: fullname.txtMiddleName,
        lastName: fullname.txtLastName,
        email: response.Email.toLowerCase(),
        phoneNumber: response.Mobile.includes('+')
          ? +response.Mobile
          : '+91' + response.Mobile,
        phoneCountryCode: 'IN'
      }
      this.setState({ signUp: obj })
      // this.ValidateNumber()
    }
  }

  getClientData = async partnerToken => {
    // check for IIFL and call APIs
    const sendDate = new Date().getTime()
    let result = await clientLogin({ clientCode: partnerToken })
    const receiveDate = new Date().getTime()
    const respTime = receiveDate - sendDate
    if (result && result.code === 200 && result.data && result.data.Status === 0) {
      const response = result.data;
      const data = {
        PermanentAddress: response.PermanentAddress,
        CorrespondenceAddress: response.CorrespondenceAddress,
        DOB: response.DOB,
        PINCode: response.PINCode,
        State: response.State,
        Pan: response.Pan,
        ClientName: response.ClientName,
        Email: response.Email,
        Mobile: response.Mobile
      }
      sessionStorage.setItem('stockal-client', JSON.stringify(data))
      return { clientData: response, responseTime: respTime }
    }
    return { clientData: null, responseTime: null }
  }

  getName(full_name) {
    var Obj = {
      txtFirstName: '',
      txtLastName: '',
      txtMiddleName: ''
    }
    var splitName = full_name.split(' ')
    if (splitName.length > 0) {
      switch (splitName.length) {
        case 1:
          Obj.txtFirstName = splitName[0]
          break
        case 2:
          Obj.txtFirstName = splitName[0]
          Obj.txtLastName = splitName[1]
          break
        case 3:
          Obj.txtFirstName = splitName[0]
          Obj.txtMiddleName = splitName[1]
          Obj.txtLastName = splitName[2]
          break
        default:
          Obj.txtFirstName = splitName[0]
          Obj.txtMiddleName = splitName.slice(1, -1).join(' ')
          Obj.txtLastName = splitName[splitName.length - 1]
      }
    }
    return Obj
  }

  updateFormData = data => {
    // const value = Object.assign(this.state.signUp, data)
    // console.log('value',data)
    this.setState({ signUp: Object.assign(this.state.signUp, data) })
  }

  changeCurrent = formName => {
    sessionStorage.setItem('currentSignUp', formName)
    this.setState({ current: formName })
  }

  onChangeServerError = value => {
    this.setState({ serverError: value })
  }

  getCurrent = () => {
    const { current } = this.state
    // eslint-disable-next-line default-case
    switch (current) {
      case 'SignUp':
        return (
          <SignUp
            changeCurrent={this.changeCurrent}
            formData={this.state.signUp}
            updateFormData={this.updateFormData}
            serverError={this.state.serverError}
            onChangeServerError={this.onChangeServerError}
            activeCountries={this.state.activeCountries}
            partnerCode={this.state.refcode}
            leadData={this.state.leadData}
          />
        )
      case 'VerifyEmail':
        return (
          <VerifyEmail
            changeCurrent={this.changeCurrent}
            formData={this.state.signUp}
            partnerCode={this.state.refcode}
            updateFormData={this.updateFormData}
          />
        )
      case 'UserName':
        return (
          <UserName
            changeCurrent={this.changeCurrent}
            formData={this.state.signUp}
            leadData={this.state.leadData}
            updateFormData={this.updateFormData}
          />
        )
      case 'PhoneNumber':
        return (
          <PhoneNumber
            changeCurrent={this.changeCurrent}
            formData={this.state.signUp}
            leadData={this.state.leadData}
            activeCountries={this.state.activeCountries}
            partnerCode={this.state.refcode}
          />
        )
      // case 'AccountSetUp':
      // 	return <AccountSetUp />
    }
  }

  redirectToLogin = () => {
    sessionStorage.clear()
    localStorage.clear()
    this.props.history.push(`/signin`)
  }


  render() {

    const { isPartner } = this.context;
	  const showPoweredLogo =
		isPartner && !window.location.href.includes('landing-page')

    const hotlineSupport = (sessionStorage.getItem('planInfo') &&
      JSON.parse(sessionStorage.getItem('planInfo')).details &&
      JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport) ?
      JSON.parse(sessionStorage.getItem('planInfo')).details.hotlineSupport : [];
    let label, phone = "";
    if (hotlineSupport.length > 0 && hotlineSupport[0].phone) {
      phone = hotlineSupport[0].phone;
      label = hotlineSupport[0].text ? hotlineSupport[0].text : '';
    } else {
      phone = this.context.contactNumber;
      label = 'Hotline'
    }
    const hotlineText = label + ' : ' + phone
    const showLandingBanner = this.context.partnerConfig && this.context.partnerConfig?.[0]?.landingBanner ? true : false
    return (
      <>
        {/* {this.state.serverError === '' ? null : (
          <AlertError color='#E81346' message={this.state.serverError} />
        )} */}
        <NewHeader
          message='Already have an account?'
          linkName='Log In'
          onClickLink={this.redirectToLogin }
        />
        {this.context.partnerConfig && showLandingBanner ?
          <LoginBanner partnerConfig={this.context.partnerConfig} /> : ""}
        <div className={showLandingBanner ? styles.cardDivWithBanner : styles.cardDiv}>
        <Card className={styles.cardSignUp}>
          <Card.Body className={styles.cardBodySignup}>
            {this.getCurrent()}
          </Card.Body>
        </Card>
        </div>

        {/* message popup */}
        <div className='modal fade' id='mdlRedirect'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-body text-center p-4'>
                <div>
                  {this.state.loading ? (
                    <span className='btnLoader themeLoaderColor'></span>
                  ) : (
                    ''
                  )}
                </div>
                <br />
                <p>
                  <span style={{ fontSize: '12px' }}>
                    {/* Seems like you have registered earlier. Redirecting you to
                    login... */}
                    You are already registered with the Client code. Please login to start investing
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
				style={{
					marginTop: '50px',
					display: 'flex',
					justifyContent: 'center'
				}}>
				{showPoweredLogo ? (
					<Col
						md={4}
						xl={4}
						lg={4}
						style={{ textAlign: 'center' }}
					//	className='d-none d-md-block'
            >
						<span className={styles.poweredText}>Powered by</span>
						<img src={Logo} alt='powered by' width='64px' height='16px' />
					</Col>
				) : (
					<Col md={2} xl={3} className={`d-none d-md-flex`}></Col>
				)}
			</div>
			<OnboardingFooter />
      </>
    )
  }
}

export default SignUpForm
