import React from "react";
import styles from './Login.module.css'

const LoginBanner = ({partnerConfig})=>{
    const header = partnerConfig && partnerConfig?.[0].landingBanner?.heading
    const subHeader = partnerConfig && partnerConfig?.[0].landingBanner?.subHeading
    const endDate = partnerConfig && partnerConfig?.[0].landingBanner?.endDate
    const onSignup = partnerConfig && partnerConfig?.[0].landingBanner?.onSignup
    const onSignin = partnerConfig && partnerConfig?.[0].landingBanner?.onSignin

    let d1=new Date(endDate);
    console.log(d1)
  let bannerShow=false;
    if(window.location.href.includes('signup') && onSignup) {
      if (d1 instanceof Date && !isNaN(d1)) {
        let todayDate = new Date() ? new Date : "";
        bannerShow = todayDate && todayDate !== null ? d1 > todayDate : false;
      } else {
        bannerShow = true
      }
    }
    if(window.location.href.includes('signin') && onSignin) {
      if (d1 instanceof Date && !isNaN(d1)) {
        let todayDate = new Date() ? new Date : "";
        bannerShow = todayDate && todayDate !== null ? d1 > todayDate : false;
      } else {
        bannerShow = true
      }
    }
  // const subHeader = 'Join us for an online session on "Investing Globally May Be Easier Than You Think" on February 27th. More details and registration link <a target="_blank" href="https://lu.ma/ygz9tvpx">here</a>'
    return(
        <>
          {bannerShow ?
        <div className={styles.panBannerContainer +' '+styles.beneficiaryBannerContainer}>
            <div className={styles.row1}>
                <div className="d-flex">
                    <div className="d-lg-flex">
                        <div>
                            {/*<h2 className={styles.bannerHead}>{header}</h2>*/}
                            {/*<p className={styles.bannerSubHead}>{subHeader}</p>*/}
                              <h2 className={styles.bannerHead} dangerouslySetInnerHTML={{__html: header}}/>
                              <p className={styles.bannerSubHead} dangerouslySetInnerHTML={{__html: subHeader}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            :null}
        </>
    )
}

export default LoginBanner
